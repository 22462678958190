import React, { useEffect, useState, useRef } from 'react';
import {
  View, StyleSheet, ScrollView, Text,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Layout from '../../components/Common/Layout/Layout.web';
import { useUserContextState } from '../../contexts/UserContext';
import { useContentContextState } from '../../contexts/ContentContext';
import Feed from '../../components/Home/Feed/Feed.web';
import BmmModal from '../../components/Common/Modal/BmmModal';
import Card from '../../components/Common/Card/Card';
import Button from '../../components/Common/Button/Button';
import COLORS from '../../utils/colors';
import analytics from '../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '1000%',
    backgroundColor: COLORS.background,
    marginBottom: 100,
  },
  scrollview: {
    height: '100%',
    backgroundColor: COLORS.background,
  },
  content: {
    backgroundColor: COLORS.background,
    height: '100%',
    paddingBottom: 50,
  },
  modalContent: {
    width: '35%',
    height: '60%',
    backgroundColor: COLORS.background,
    paddingTop: 30,
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
});

function Home() {
  const { t } = useTranslation();
  const userContext = useUserContextState();
  const contentContext = useContentContextState();
  const scrollviewRef = useRef();

  const [modalVisible, setModalVisible] = useState(false);

  const checkSsoAgreement = async () => {
    const saved = await AsyncStorage.getItem('ssoAgree');
    if (saved) {
      userContext.sethaveAgree(saved);
    } else {
      setModalVisible(true);
    }
  };

  useEffect(() => {
    contentContext.setCategories(userContext.user.group.categories);
    contentContext.getMyEditos();
    contentContext.getBeEditos();
    if (userContext.isSso && !userContext.haveAgree) {
      checkSsoAgreement();
    }
  }, []);

  const onPressCategory = (index, item) => {
    if (index === 0) {
      analytics.track('user clicked on main feed button');
      contentContext.setIsOrgaFeed(true);
      contentContext.setCategories(userContext.user.group.categories);
    } else {
      analytics.track('user clicked on specialized feed button', { category: item, index });
      contentContext.setIsOrgaFeed(false);
      contentContext.setCategories([item]);
    }
  };

  return (
    <Layout
      style={styles.container}
      onPressMenuItem={onPressCategory}
      menuItems={userContext.getUserInterests()}
      isMenuCustomizable
    >
      <BmmModal modalVisible={modalVisible}>
        <Card style={styles.modalContent}>
          <ScrollView
            contentContainerStyle={{
              alignItems: 'center', paddingHorizontal: 20,
            }}
          >
            <Text style={{
              color: 'white', textAlign: 'justify', marginBottom: 20, fontSize: 14,
            }}
            >
              {t('auth.sso.externalLinksAgreement')}
            </Text>
            <Button style={{ width: '40%' }} textStyle={{ fontSize: 13 }} text={t('auth.sso.agree')} onPress={() => { setModalVisible(false); userContext.setHaveAgree(true); AsyncStorage.setItem('ssoAgree', true); }} />
          </ScrollView>
        </Card>
      </BmmModal>
      <ScrollView
        contentContainerStyle={styles.scrollview}
        style={{ marginBottom: 30 }}
        ref={scrollviewRef}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.content}>
          <Feed scrollRef={scrollviewRef} />
        </View>
      </ScrollView>
    </Layout>
  );
}

export default Home;
