import React, { useEffect } from 'react';
import {
  StyleSheet, View, Text, Pressable, Linking,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import {
  OpenBook, ShieldMinus, SendDiagonal,
} from 'iconoir-react-native';
import { useNavigation } from '@react-navigation/native';

import { externalLinks } from '../../utils/constants';
import Button from '../../components/Common/Button/Button';
import Layout from '../../components/Common/Layout/Layout';
import COLORS from '../../utils/colors';
import Card from '../../components/Common/Card/Card';
import { useUserContextState } from '../../contexts/UserContext';
import { useAuthContextState } from '../../contexts/AuthContext';
import analytics from '../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'flex-start',
    backgroundColor: COLORS.background,
    paddingHorizontal: '17%',
    paddingVertical: '3%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  linksContainer: {
    alignItems: 'flex-start',
    width: '75%',
    height: '50%',
  },
  text: {
    color: 'white',
  },
  button: {
    marginTop: '2%',
    width: '15%',
    paddingVertical: 10,
    borderRadius: 20,
    alignSelf: 'center',
  },
  separator: {
    height: 2,
    width: '100%',
    backgroundColor: COLORS.backgroundSecondary,
    marginVertical: '3%',
  },
  linksList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    height: '80%',

  },
  linkCard: {
    width: '49%',
    height: '45%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: COLORS.backgroundSecondary,
  },
  pressable: {
    width: '100%',
    height: '100%',
    padding: '5%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
});

function Account({ route }) {
  const { t } = useTranslation();
  const userContext = useUserContextState();
  const authContext = useAuthContextState();
  const navigation = useNavigation();

  useEffect(() => {
    if (route.params && route.params.isLoggedOut) {
      userContext.logout();
      authContext.logout();
      navigation.navigate('Welcome');
    }
  }, []);

  return (
    <Layout currentPage="account">
      <View style={styles.container}>
        <View style={[styles.row]}>
          <View style={styles.column}>
            <Text style={[styles.text, { fontSize: 20, fontWeight: 700, marginBottom: '3%' }]}>{t('account.myAccount')}</Text>
            <Text style={[styles.text, { fontSize: 15 }]}>{userContext.user.mail}</Text>
          </View>
          <Button onPress={() => { analytics.track('user clicked on logout button'); userContext.logout(); authContext.logout(); }} style={styles.button} text={t('account.disconnect')} />
        </View>
        <View style={styles.separator} />
        <View style={[styles.column, styles.linksContainer]}>
          <Text style={[styles.text, { fontSize: 22, fontWeight: 700, marginBottom: '4%' }]}>{t('account.links')}</Text>
          <View style={styles.linksList}>
            <Card style={styles.linkCard}>
              <Pressable
                accessibilityRole="button"
                style={styles.pressable}
                onPress={() => {
                  analytics.track('user clicked on CGU button');
                  Linking.openURL(externalLinks.cgu);
                }}
              >
                <View style={[styles.column, { width: '75%', alignItems: 'flex-start' }]}>
                  <Text style={[styles.text, { fontSize: 15, marginBottom: '5%' }]}>{t('account.cgu')}</Text>
                  <Text
                    style={[styles.text, { fontSize: 13, color: COLORS.textSecondary }]}
                  >
                    Détail des conditions d'utilisation de l'application Be my media
                  </Text>
                </View>
                <OpenBook role="none" color={COLORS.primary} height={18} width={18} />
              </Pressable>
            </Card>
            <Card style={styles.linkCard}>
              <Pressable
                accessibilityRole="button"
                style={styles.pressable}
                onPress={() => {
                  analytics.track('user clicked on confidentiality button');
                  Linking.openURL(externalLinks.privacy);
                }}
              >
                <View style={[styles.column, { width: '75%', alignItems: 'flex-start' }]}>
                  <Text style={[styles.text, { fontSize: 15, marginBottom: '5%' }]}>{t('account.confidentiality')}</Text>
                  <Text
                    style={[styles.text, { fontSize: 13, color: COLORS.textSecondary }]}
                  >
                    Détail de la politique de confidentialité de Be my media
                  </Text>
                </View>
                <ShieldMinus role="none" color={COLORS.primary} height={18} width={18} />
              </Pressable>
            </Card>
            {(userContext.user && !userContext.isSso) && (
              <Card style={styles.linkCard}>
                <Pressable
                  accessibilityRole="button"
                  style={styles.pressable}
                  onPress={() => {
                    analytics.track('user clicked on contact button');
                    Linking.openURL(externalLinks.support);
                  }}
                >
                  <View style={[styles.column, { width: '75%', alignItems: 'flex-start' }]}>
                    <Text style={[styles.text, { fontSize: 15, marginBottom: '5%' }]}>{t('account.contact')}</Text>
                    <Text
                      style={[styles.text, { fontSize: 13, color: COLORS.textSecondary }]}
                    >
                      Contacter le support de Be my media
                    </Text>
                  </View>
                  <SendDiagonal role="none" color={COLORS.primary} height={18} width={18} />
                </Pressable>
              </Card>
            )}
          </View>
        </View>
      </View>
    </Layout>
  );
}

export default Account;
