// Couleurs a renommer
export default {
  primary: '#fe6f5f',
  primaryTransparent: '#fe6f5fcc',
  secondary: '#fe4d39',
  ternary: '#e45545',
  background: '#1a1a1a',
  backgroundSecondary: '#303030',
  backgroundTernary: '#282828',
  backgroundTernaryOpac: '#28282855',
  textSecondary: '#878584',
  inappEdito: '#20a39e',
  inappArticle: '#00827c',
  inappMetadata: '#8dbebb',
  actuEdito: '#ffba49',
  actuArticle: '#ff9a0a',
  actuMetadata: '#644b27',
  error: '#ff3224',
};
