import * as amplitude from '@amplitude/analytics-react-native';

class AmplitudeAnalytics {
  init = async () => {
    try {
      this.isEnable = true;
      amplitude.init('10690c17ca7ad3f88b4d792f02eb1c63');
    } catch (e) {
      console.log('>>>>', e);
    }
  };

  disable = async () => {
    this.isEnable = false;
  };

  track = (eventName, eventProperties = null) => {
    if (!__DEV__ && this.isEnable) {
      try {
        amplitude.track(eventName, eventProperties);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('track analytics : ', eventName, eventProperties);
    }
  };

  trackPage = (pageName, eventProperties = null) => {
    if (!__DEV__ && this.isEnable) {
      try {
        amplitude.track(`user viewed page ${pageName}`, eventProperties);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log(`track user viewed page ${pageName}`, eventProperties);
    }
  };
}

export default new AmplitudeAnalytics();
