import React, {
  createContext, useContext, useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';

const NavigationContext = createContext();

const useNavigationContextState = () => {
  const context = useContext(NavigationContext);

  if (context === undefined) {
    throw new Error('useNavigationContextState was used outside of its Provider');
  }

  return context;
};

function NavigationContextProvider({ children }) {
  const [currentArticle, setCurrentArticle] = useState(null);
  const [currentEdito, setCurrentEdito] = useState(null);
  const [prevPage, setPrevPage] = useState(null);

  async function onPressArticle(article, page) {
    setCurrentArticle(article);
    setPrevPage(page);
  }

  async function onPressEdito(edito, page) {
    setCurrentEdito(edito);
    setPrevPage(page);
  }

  const contextValue = useMemo(() => ({
    currentArticle,
    prevPage,
    currentEdito,
    onPressArticle,
    onPressEdito,
  }), [currentArticle, prevPage, currentEdito]);

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
}

NavigationContextProvider.propTypes = {
  children: PropTypes.element,
};
NavigationContextProvider.defaultProps = {
  children: null,
};

export { NavigationContextProvider, useNavigationContextState };
