import React from 'react';
import { StyleSheet, View } from 'react-native';
import { WebView } from 'react-native-webview';
import COLORS from '../../../utils/colors';

const styles = StyleSheet.create({
  flex: {
    width: '80%',
    height: '100%',
    minHeight: '100%',
    alignSelf: 'center',
    paddingHorizontal: '5%',
    backgroundColor: 'transparent',
    fontFamily: 'ProductSans',
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  red: {
    backgroundColor: COLORS.backgroundSecondary,
    opacity: 0.99,
    paddingBottom: 110,
    height: '100%',
    minHeight: '100%',

    overflow: 'hidden',
  },
});

function Reader({
  url,
  cleandedArticle,
}) {
  if (!cleandedArticle) {
    return (
      <WebView
        style={styles.flex}
        containerStyle={styles.red}
        source={{ uri: url }}
        scrollEnabled={false}
      />
    );
  }

  if (cleandedArticle) {
    const html = cleandedArticle.replaceAll(': black', ': white').replaceAll(': #6d6d72', ': white').replaceAll(': #030303', ': white').replaceAll('Open Sans', 'Trebuchet MS');
    return (
      <WebView
        style={styles.flex}
        scrollEnabled={false}
        containerStyle={styles.red}
        source={{ html }}
      />
    );
  }
}

export default Reader;
