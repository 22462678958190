import React, { useState, useEffect } from 'react';
import { View, useWindowDimensions, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import { useUserContextState } from '../../../contexts/UserContext';
import COLORS from '../../../utils/colors';
import AppHeader from '../AppHeader/AppHeader';
import HorizontalMenu from '../HorizontalMenu/HorizontalMenu';
import LevelUpgradeModal from '../../Progress/LevelUpgradeModal/LevelUpgradeModal';

const styles = StyleSheet.create({

});

function Layout({
  children,
  menuItems,
  onPressMenuItem,
  isMenuCustomizable,
  currentPage,
  selectedIndex,
}) {
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userContext = useUserContextState();

  function onDismissLevelModal() {
    setIsModalVisible(false);
    userContext.setIsUpgradingLevel(null);
  }

  useEffect(() => {
    if (userContext.isUpgradingLevel !== null) {
      setIsModalVisible(true);
    }
  }, [userContext.isUpgradingLevel]);

  return (
    <View
      style={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        backgroundColor: COLORS.background,
      }}
    >
      {isModalVisible && (
        <LevelUpgradeModal onClose={onDismissLevelModal} isModalVisible />
      )}
      <View style={styles.header}>
        <AppHeader currentPage={currentPage} />
        {menuItems.length > 0 && (
        <HorizontalMenu
          isCustomizable={isMenuCustomizable}
          onPressItem={onPressMenuItem}
          items={menuItems}
          selectedIndex={selectedIndex}
        />
        )}
      </View>
      {children}
    </View>
  );
}

Layout.propTypes = {
  children: PropTypes.element,
  menuItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  onPressMenuItem: PropTypes.func,
  isMenuCustomizable: PropTypes.bool,
  currentPage: PropTypes.string,
  selectedIndex: PropTypes.number,
};

Layout.defaultProps = {
  children: null,
  menuItems: [],
  isMenuCustomizable: false,
  currentPage: 'home',
  onPressMenuItem: () => {},
  selectedIndex: 0,
};

export default Layout;
