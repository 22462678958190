import React, { useState } from 'react';
import {
  View, StyleSheet, Text, Pressable,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AwesomeAlert from 'react-native-awesome-alerts';
import COLORS from '../../../utils/colors';

import EpisodeCard from '../EpisodeCard/EpisodeCard.web';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: '15%',
    paddingTop: '2%',
  },
  title: {
    fontSize: 23,
    color: 'white',
    marginBottom: '3%',
    fontWeight: 700,
  },
  alertContainer: {
    position: 'absolute',
    justifyContent: 'flex-start',
    alignItems: 'center',
    top: -30,
    left: 0,
    zIndex: 5,
    backgroundColor: 'transparent',
  },
  alertOverlay: {
    backgroundColor: 'transparent',
  },
  alertContent: {
    borderColor: COLORS.textSecondary,
    borderWidth: 1,
    backgroundColor: COLORS.background,
  },
  confirmButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 15,
    width: '10%',
  },
});

function EpisodeList({
  style,
  episodes,
  seasonNumber,
  numberOfEpisodes,
  seasonId,
  scrollRef,
}) {
  const { t } = useTranslation();

  const [showAlert, setShowAlert] = useState(false);

  return (
    <View style={({ ...styles.container, ...style })}>
      <AwesomeAlert
        show={showAlert}
        alertContainerStyle={styles.alertContainer}
        contentContainerStyle={styles.alertContent}
        titleStyle={{ color: 'white' }}
        messageStyle={{ color: 'white' }}
        overlayStyle={styles.alertOverlay}
        title={t('progress.episodeLocked')}
        message={t('progress.episodeLockedText')}
        showConfirmButton
        confirmText="Ok"
        confirmButtonStyle={styles.confirmButton}
        confirmButtonColor={COLORS.primary}
        onConfirmPressed={() => {
          setShowAlert(false);
        }}
      />
      <Text style={styles.title}>{t('progress.episodesList')}</Text>
      {episodes.map((prop, key) => {
        const isLastEpisode = (key === numberOfEpisodes - 1);
        return (
          <EpisodeCard
            key={`episode-S${seasonNumber}-${prop.title}`}
            title={prop.title}
            episode={prop}
            seasonNumber={seasonNumber}
            seasonId={seasonId}
            pendingPage={prop.currentPageIndex}
            isLastEpisode={isLastEpisode}
            onPress={() => {
              if (!prop.isAvailable) {
                setShowAlert(true);
                scrollRef.current?.scrollTo({
                  y: 0,
                  animated: true,
                });
              }
            }}
          />
        );
      })}
    </View>
  );
}

EpisodeList.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  episodes: PropTypes.arrayOf(PropTypes.object),
  seasonNumber: PropTypes.number,
  numberOfEpisodes: PropTypes.number,
  seasonId: PropTypes.number,
};

EpisodeList.defaultProps = {
  style: {},
  episodes: [],
  seasonNumber: 1,
  numberOfEpisodes: 21,
  seasonId: 0,
};

export default EpisodeList;
