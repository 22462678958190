import React, { useEffect, useState, useRef } from 'react';
import * as Linking from 'expo-linking';
import {
  StyleSheet, View, Text, ScrollView,
} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import {
  ArrowLeft, Copy, Bookmark, BookmarkSolid, WarningCircle,
} from 'iconoir-react-native';
import { useTranslation } from 'react-i18next';

import { useNavigationContextState } from '../../contexts/NavigationContext';
import { useUserContextState } from '../../contexts/UserContext';
import { useContentContextState } from '../../contexts/ContentContext';
import Layout from '../../components/Common/Layout/Layout';
import Reader from '../../components/Article/Reader/Reader';
import newsApi from '../../api/newsApi';
import COLORS from '../../utils/colors';
import Button from '../../components/Common/Button/Button';
import ComparisonList from '../../components/Article/ComparisonList/ComparisonList';
import BookmarkModal from '../../components/Article/BookmarkModal/BookmarkModal';
import ReportModal from '../../components/Article/ReportModal/ReportModal';
import analytics from '../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    backgroundColor: COLORS.background,
  },
  scrollview: {
    height: '100%',
    backgroundColor: COLORS.background,
  },
  backButton: {
    borderWidth: 1,
    borderColor: COLORS.textSecondary,
    borderRadius: 50,
    width: '15%',
    marginLeft: '2%',
    marginVertical: '2%',
  },
  comparisonList: {
    height: '50%',
    marginTop: '5%',
  },
  articleButtons: {
    width: '40%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    paddingHorizontal: '10%',
    marginTop: '2%',
    paddingBottom: '2%',
  },
});

function Article({ route }) {
  const userContext = useUserContextState();
  const contentContext = useContentContextState();
  const navigationContext = useNavigationContextState();
  const [article, setArticle] = useState(null);
  const [linkedArticles, setLinkedArticles] = useState(null);
  const [prevArticlesUrls, setPrevArticlesUrls] = useState([]);
  const [cleandedArticle, setCleanedArticle] = useState(null);
  const [isCopyHovered, setIsCopyHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState('Copier le lien');
  const [isBookmarkHovered, setIsBookmarkHovered] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [editoId, setEditoId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [isReportHovered, setIsReportHovered] = useState(false);

  const { t } = useTranslation();
  const navigation = useNavigation();
  const scrollViewRef = useRef();

  async function loadArticle(url) {
    return newsApi.getArticleByUrl(url.trim());
  }

  async function getShareLink() {
    analytics.track('user clicked on copy article link button', { article: article.url });
    setCopyText('Lien copié !');
    setIsCopied(true);
    const uri = await Linking.createURL('/article/');
    navigator.clipboard.writeText(`${uri}${encodeURIComponent(article.url)}`);
    setTimeout(() => {
      setCopyText('Copier le lien');
      setIsCopied(false);
    }, 3000);
  }

  async function navigateToLinkedArticle(item) {
    analytics.track('user clicked on article', {
      from: 'comparison', isBig: false, article: item.title, source: item.sourceName,
    });
    userContext.executeAction('booth');
    setPrevArticlesUrls((prevdata) => [...prevdata, article.url.trim()]);
    const res = await loadArticle(item.url.trim());
    if (res && !res.error) {
      setArticle(res);
    }
  }

  async function getArticle() {
    let articleUrl = '';
    if (route.path) {
      articleUrl = route.path.replace('/article/', '');
    } else {
      return;
    }
    const res = await loadArticle(articleUrl);
    if (res && !res.error) {
      setArticle(res);
    } else {
      navigation.navigate('Accueil', {});
    }
  }

  async function getLinkedArticle() {
    if (article && typeof article === 'object' && article.tagCategory !== '' && !article.url.includes('/api/v2/Documents/')) {
      const linked = await newsApi.getLinkedArticle(article.url.trim());
      if (linked && !linked.error) {
        setLinkedArticles(linked);
      } else {
        console.log(linked);
      }
    }
  }

  async function downloadAndCleanArticle() {
    if (article && article.url) {
      const res = await newsApi.getCleanedArticleByUrl(article.url.trim());
      if (!res.error) {
        let html = res;
        setIsError(false);
        if (userContext.isSso) {
          html = html.replaceAll('<a', '<span');
          html = html.replaceAll('/a>', '/span>');
        }
        setCleanedArticle(html);
      } else {
        setIsError(true);
        console.log(res);
      }
    } else {
      setArticle(null);
      getArticle();
    }
  }

  async function onPressBack() {
    analytics.track('user clicked on navigation back button');
    if (prevArticlesUrls.length > 0) {
      const res = await loadArticle(prevArticlesUrls[prevArticlesUrls.length - 1]);
      if (res && !res.error) {
        setArticle(res);
      } else {
        navigation.navigate('Accueil', {});
      }
      setPrevArticlesUrls((previousArr) => (previousArr.slice(0, -1)));
    } else if (navigationContext.prevPage) {
      navigation.navigate(navigationContext.prevPage, {});
    } else {
      navigation.navigate('Accueil', {});
    }
  }

  function checkBookmark() {
    if (article) {
      const res = contentContext.checkBookmarkedArticle(article.url);
      setIsBookmarked(res.isBookmarked);
      setEditoId(res.editoId);
    }
  }

  useEffect(() => {
    checkBookmark();
    downloadAndCleanArticle();
    getLinkedArticle();
    scrollViewRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, [article]);

  useEffect(() => {
    const pressedArticle = navigationContext.currentArticle;
    if (pressedArticle) {
      setArticle(pressedArticle);
      userContext.executeAction('read');
    } else {
      getArticle();
    }
  }, [navigationContext.currentArticle]);

  return (
    <Layout style={styles.container} currentPage="article">
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={styles.scrollview}
        showsVerticalScrollIndicator={false}
      >
        {isModalVisible && (
        <BookmarkModal
          modalVisible={isModalVisible}
          onClose={() => { setIsModalVisible(false); checkBookmark(); }}
          article={article}
        />
        )}
        {isReportModalVisible && (
          <ReportModal
            modalVisible={isReportModalVisible}
            onClose={() => { setIsReportModalVisible(false); }}
            articleUrl={article.url}
          />
        )}
        <Button
          backgroundColor="transparent"
          style={styles.backButton}
          accessibilityLabel="Retour"
          accessibilityHint="Retour"
          hoverable={false}
          onPress={onPressBack}
        >
          <View style={{ flexDirection: 'row' }}>
            <ArrowLeft role="none" color="white" height={15} width={15} />
            <Text style={{ color: 'white', marginLeft: 10 }}>{t('common.previous')}</Text>
          </View>
        </Button>
        {!isError && (
          <Reader
            cleandedArticle={cleandedArticle}
            url={article && article.url && article.url.trim()}
            title={article && article.title}
          />
        )}
        {isError && (
          <View style={{
            width: '100%', height: '40%', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Text style={{ color: COLORS.primary, textAlign: 'center', fontSize: 16 }}>{"Désolé, cet article n'est pas disponible.\n\nN'hésitez pas à aller voir un des articles similaires du comparateur de point de vue "}</Text>
          </View>
        )}
        {!isError && (
          <View style={styles.articleButtons}>
            <Button onPress={() => { getShareLink(); }} backgroundColor="transparent" hoverable={false} onHoverIn={() => { setIsCopyHovered(true); }} onHoverOut={() => { setIsCopyHovered(false); }}>
              <View style={{ flexDirection: 'row' }}>
                <Copy style={{ marginRight: 10 }} role="none" color={(isCopyHovered || isCopied) ? COLORS.primary : COLORS.textSecondary} height={20} width={20} />
                <Text style={{ color: (isCopyHovered || isCopied) ? COLORS.primary : 'white' }}>{copyText}</Text>
              </View>
            </Button>
            {!isBookmarked && (
            <Button onHoverIn={() => { setIsBookmarkHovered(true); }} onHoverOut={() => { setIsBookmarkHovered(false); }} hoverable={false} backgroundColor="transparent" onPress={() => { setIsModalVisible(true); }} accessibilityLabel={t('accessibility.labels.article.delete')} accessibilityHint={t('accessibility.hints.article.delete')}>
              <Bookmark role="none" color={isBookmarkHovered ? COLORS.primary : COLORS.textSecondary} height={20} width={20} />
            </Button>
            )}
            {isBookmarked && (
            <Button
              hoverable={false}
              backgroundColor="transparent"
              onPress={() => {
                if (article.url.includes('/api/v2/Documents/')) {
                  contentContext.removeArticleFromEdito(editoId, [article]);
                } else {
                  contentContext.removeArticleFromEdito(editoId, [article.url]);
                }
                setIsBookmarked(false);
              }}
              accessibilityLabel={t('accessibility.labels.article.delete')}
              accessibilityHint={t('accessibility.hints.article.delete')}
            >
              <BookmarkSolid role="none" color={COLORS.primary} height={20} width={20} />
            </Button>
            )}
            <Button
              onHoverIn={() => { setIsReportHovered(true); }}
              onHoverOut={() => { setIsReportHovered(false); }}
              hoverable={false}
              backgroundColor="transparent"
              onPress={() => {
                analytics.track('user clicked on report article button', { article: article.url });
                setIsReportModalVisible(true);
              }}
              accessibilityLabel={t('accessibility.labels.article.delete')}
              accessibilityHint={t('accessibility.hints.article.delete')}
            >
              <WarningCircle role="none" color={isReportHovered ? COLORS.primary : COLORS.textSecondary} height={20} width={20} />
            </Button>
          </View>
        )}
        {(
          linkedArticles && linkedArticles.length > 0)
          && (
            <ComparisonList
              onClickArticle={navigateToLinkedArticle}
              style={styles.comparisonList}
              articles={linkedArticles}
            />
          )}
      </ScrollView>
    </Layout>
  );
}

export default Article;
