import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, Text, Modal, ScrollView, Image, Linking,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Circle, CheckCircle } from 'iconoir-react-native';
import COLORS from '../../../utils/colors';

import Card from '../../Common/Card/Card.web';
import BmmModal from '../../Common/Modal/BmmModal';
import Button from '../../Common/Button/Button.web';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  modalContent: {
    width: '35%',
    height: '70%',
    backgroundColor: 'black',
  },
  modalContentFullScreen: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    borderRadius: 0,
  },
  metadata: {
    fontSize: 13,
    color: COLORS.textSecondary,
    marginTop: -25,
    marginBottom: 5,
    alignSelf: 'center',
  },
  title: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '3%',
  },
  columnCenter: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '90%',
    paddingHorizontal: '5%',
  },
  captionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  prevButton: {
    backgroundColor: 'transparent',
    marginRight: 10,
  },
  nextButton: {
    width: '20%',
  },
  contentText: {
    color: 'white',
    fontSize: 15,
    textAlign: 'center',
    marginBottom: '3%',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  imageContainer: {
    width: '40%',
    height: '40%',
  },
  recapButton: {
    width: '35%',
    height: '10%',
  },
  recapContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    paddingBottom: '10%',
    alignItems: 'center',
  },
  cardLeft: {
    backgroundColor: COLORS.backgroundSecondary,
    width: '100%',
    height: '20%',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '1%',
    paddingBottom: '2%',
    paddingHorizontal: '3%',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'green',
    marginTop: '10%',
  },
  cardMetadata: {
    flexDirection: 'row',
  },
  icon: {
    marginRight: '1%',
  },
  metadataText: {
    color: COLORS.textSecondary,
    fontSize: 13,
    marginBottom: '1.5%',
  },
  cardTitle: {
    color: 'white',
    fontSize: 14,
  },
});

function EpisodeModal({
  style,
  modalVisible,
  onPressClose,
  pendingPage,
  episode,
  seasonNumber,
}) {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(pendingPage > 1 ? pendingPage - 1 : 0);
  const [isExpanded, setIsExpanded] = useState(false);

  const renderCaption = () => {
    const res = [];
    for (let i = 0; i <= episode.pages.length; i += 1) {
      res.push(<Circle style={i === episode.pages.length ? {} : { marginRight: 3 }} key={`caption-page-${i}`} width={10} height={10} color="transparent" fill={currentIndex === i ? COLORS.primary : COLORS.textSecondary} />);
    }
    return res;
  };

  const endEpisode = (status, page = 1) => {
    analytics.track(`user ${status === 'pending' ? 'paused' : status} episode`, { episode: episode.number, seasonNumber });
    onPressClose(status, page);
  };

  const renderImageText = () => {
    if (episode.pages[currentIndex].image && episode.pages[currentIndex].imagePosition === 'Top') {
      const texts = episode.pages[currentIndex].texts.join('\n');
      return (
        <View style={styles.contentContainer}>
          <View style={styles.imageContainer}>
            <Image
              source={{ uri: episode.pages[currentIndex].image, width: '100%', height: '100%' }}
              accessibilityIgnoresInvertColors
            />
          </View>
          <Text style={styles.contentText}>{texts}</Text>
        </View>
      );
    } if (episode.pages[currentIndex].image && episode.pages[currentIndex].imagePosition === 'Middle') {
      return (
        <View style={styles.contentContainer}>
          <Text style={styles.contentText}>{episode.pages[currentIndex].texts[0]}</Text>
          <View style={styles.imageContainer}>
            <Image
              source={{ uri: episode.pages[currentIndex].image, width: '100%', height: '100%' }}
              accessibilityIgnoresInvertColors
            />
          </View>
          {episode.pages[currentIndex].texts.length > 1
            && (<Text style={styles.contentText}>{episode.pages[currentIndex].texts[1]}</Text>
            )}
        </View>
      );
    }
    const texts = episode.pages[currentIndex].texts.join('\n');
    return (
      <View style={styles.contentContainer}>
        <Text style={styles.contentText}>{texts}</Text>
        {episode.pages[currentIndex].image && (
        <View style={styles.imageContainer}>
          <Image
            source={{ uri: episode.pages[currentIndex].image, width: '100%', height: '100%' }}
            accessibilityIgnoresInvertColors
          />
        </View>
        )}
      </View>
    );
  };

  const renderPage = () => (
    <ScrollView style={{ height: '100%', width: '100%' }} contentContainerStyle={{ height: '100%', width: '100%' }}>
      {currentIndex === episode.pages.length && (
        <View style={styles.contentContainer}>
          <Text style={[styles.title, { marginBottom: 10 }]}>{t('progress.congratulations')}</Text>
          <Text style={styles.contentText}>{t('progress.episodeEnd', { episode: episode.number })}</Text>
          <View style={styles.cardLeft}>
            <View style={styles.cardMetadata}>
              <CheckCircle style={styles.icon} role="none" color="green" height={18} width={18} />
              <Text style={styles.metadataText}>
                S0
                {seasonNumber}
                {' '}
                - Épisode
                {' '}
                {episode.number}
              </Text>
            </View>
            <Text style={styles.cardTitle}>{episode.title}</Text>
          </View>
        </View>
      )}
      {(currentIndex !== episode.pages.length && episode.pages[currentIndex].type !== 'Recap') && (
        renderImageText()
      )}
      {(currentIndex !== episode.pages.length && episode.pages[currentIndex].type === 'Recap') && (
      <View style={styles.recapContainer}>
        <Text style={styles.contentText}>{episode.pages[currentIndex].texts[0]}</Text>
        <Button
          style={styles.recapButton}
          textStyle={{ fontSize: 13 }}
          text={t('progress.recap')}
          onPress={async () => {
            const supported = await Linking.canOpenURL(episode.pages[currentIndex].image);
            if (supported) {
              await Linking.openURL(episode.pages[currentIndex].image);
            }
          }}
        />
      </View>
      )}
    </ScrollView>
  );

  return (
    <BmmModal modalVisible={modalVisible}>
      <Card isExpandable isClosable style={isExpanded ? styles.modalContentFullScreen : styles.modalContent} onExpand={() => setIsExpanded(!isExpanded)} onClose={() => endEpisode(currentIndex === episode.pages.length ? 'completed' : 'pending', currentIndex + 1)}>
        <View style={styles.columnCenter}>
          <View style={{ flex: 1 }}>
            <Text style={styles.metadata}>
              S0
              {seasonNumber}
              {' '}
              - Épisode
              {' '}
              {episode.number}
            </Text>
            <Text style={styles.title}>{episode.title}</Text>
          </View>
          <View style={{ flex: 5, width: isExpanded ? '50%' : '100%' }}>
            {renderPage()}
          </View>
          <View style={{ flex: 1, width: '100%' }}>
            <View style={styles.captionContainer}>
              {renderCaption()}
            </View>
            <View style={[styles.captionContainer, { width: '100%', marginTop: 10 }]}>
              {currentIndex - 1 >= 0 && (<Button style={styles.prevButton} hoverable={false} textStyle={{ textDecorationLine: 'underline', fontSize: 13 }} text={t('common.previous')} onPress={() => { analytics.track('user switch page in episode modal', { page: currentIndex - 1 }); setCurrentIndex(currentIndex - 1); }} />)}
              {currentIndex + 1 <= episode.pages.length && (<Button style={styles.nextButton} textStyle={{ fontSize: 13 }} text={t('common.next')} onPress={() => { analytics.track('user switch page in episode modal', { page: currentIndex + 1 }); setCurrentIndex(currentIndex + 1); }} />)}
              {currentIndex === episode.pages.length && (<Button style={styles.nextButton} textStyle={{ fontSize: 13 }} text={t('progress.finish')} onPress={() => endEpisode('completed')} />)}
            </View>
          </View>
        </View>
      </Card>
    </BmmModal>
  );
}

EpisodeModal.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  modalVisible: PropTypes.bool,
  onPressClose: PropTypes.func,
  pendingPage: PropTypes.number,
  episode: PropTypes.object,
  seasonNumber: PropTypes.number,
};

EpisodeModal.defaultProps = {
  style: {},
  modalVisible: false,
  pendingPage: 1,
  onPressClose: () => {},
  episode: {},
  seasonNumber: 0,
};

export default EpisodeModal;
