import React, { useState, useRef, useEffect } from 'react';
import {
  StyleSheet, View, Text, FlatList, Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';

import { NavArrowLeft, NavArrowRight } from 'iconoir-react-native';
import COLORS from '../../../utils/colors';
import Button from '../../Common/Button/Button.web';
import ArticleCard from '../ArticleCard/ArticleCard.web';
import { articleType, articleDefault } from '../../../validation/article';

const windowDimensions = Dimensions.get('window');
const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '1%',
  },
  editoTitle: {
    fontSize: 18,
    marginBottom: '1.5%',
  },
  title: {
    fontSize: 27,
    fontWeight: 700,
    marginBottom: '1%',
  },
  subtitle: {
    fontSize: 13,
    marginBottom: '2%',
  },
  verticalContainer: {
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1%',
    height: '90%',
  },
  horizontalContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  card: {
    paddingVertical: '2%',
    paddingHorizontal: '2%',
    paddingBottom: '3%',
  },
  description: {
    color: 'white',
    fontSize: 13,
  },
  articleList: {
    width: '100%',
    height: '70%',
    marginBottom: '2%',
  },
  article: {
    height: '100%',
  },
});

function ComparisonList({
  backgroundColor,
  style,
  textColor,
  title,
  articles,
  onClickArticle,
}) {
  const { t } = useTranslation();
  const [isArrowLeftHovered, setIsArrowLeftHovered] = useState(false);
  const [dimensions, setDimensions] = useState(windowDimensions);
  const [isArrowRightHovered, setIsArrowRightHovered] = useState(false);
  const [currentScrollIndex, setCurrentScrollIndex] = useState(0);
  const listRef = useRef();

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window }) => {
        setDimensions({ window });
      },
    );
    return () => subscription?.remove();
  });

  const getCardWidth = () => ((dimensions.width * 0.85) / 3.5);

  const renderArticle = ({ item, index, separators }) => (
    <ArticleCard
      style={({
        ...styles.article,
        marginRight: index === articles.length - 1 ? 0 : getCardWidth() / 45,
        width: getCardWidth(),
      })}
      onPress={() => onClickArticle(item)}
      bookmarkable={false}
      article={item}
    />
  );

  const scrollNext = () => {
    if ((currentScrollIndex + 2) <= (articles.length - 1)) {
      listRef.current.scrollToIndex({ animated: true, index: currentScrollIndex + 2 });
      setCurrentScrollIndex(currentScrollIndex + 2);
    } else {
      listRef.current.scrollToIndex({ animated: true, index: articles.length - 1 });
      setCurrentScrollIndex(articles.length - 1);
    }
  };

  const scrollPrev = () => {
    if ((currentScrollIndex - 2) >= 0) {
      listRef.current.scrollToIndex({ animated: true, index: currentScrollIndex - 2 });
      setCurrentScrollIndex(currentScrollIndex - 2);
    } else {
      listRef.current.scrollToIndex({ animated: true, index: 0 });
      setCurrentScrollIndex(0);
    }
  };

  return (
    <View style={[styles.container, { backgroundColor }, { ...style }]}>
      <Text style={[styles.title, { color: textColor }]}>{title}</Text>
      <View style={[styles.verticalContainer]}>
        <View style={styles.articleList}>
          <FlatList
            showsHorizontalScrollIndicator={false}
            horizontal
            ref={listRef}
            data={articles}
            renderItem={renderArticle}
          />
        </View>
        {articles.length >= 4 && (
        <View style={[styles.horizontalContainer, { alignItems: 'center' }]}>
          <Button
            onHoverIn={() => { setIsArrowLeftHovered(true); }}
            onHoverOut={() => { setIsArrowLeftHovered(false); }}
            backgroundColor="transparent"
            onPress={scrollPrev}
            hoverable={false}
            accessibilityLabel={t('accessibility.labels.navigation.previous')}
            accessibilityHint={t('accessibility.hints.navigation.previous')}
          >
            <NavArrowLeft role="none" color={isArrowLeftHovered ? COLORS.primary : 'white'} height={22} width={22} />
          </Button>
          <Button
            onHoverIn={() => { setIsArrowRightHovered(true); }}
            onHoverOut={() => { setIsArrowRightHovered(false); }}
            backgroundColor="transparent"
            hoverable={false}
            onPress={scrollNext}
            accessibilityLabel={t('accessibility.labels.navigation.previous')}
            accessibilityHint={t('accessibility.hints.navigation.previous')}
          >
            <NavArrowRight role="none" color={isArrowRightHovered ? COLORS.primary : 'white'} height={22} width={22} />
          </Button>
        </View>
        )}
      </View>

    </View>
  );
}

ComparisonList.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  articles: PropTypes.arrayOf(articleType),
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onClickArticle: PropTypes.func,
};
ComparisonList.defaultProps = {
  backgroundColor: COLORS.backgroundTernary,
  textColor: 'white',
  title: 'Comparer les points de vue',
  articles: [
    articleDefault,
    articleDefault,
    articleDefault,
    articleDefault,
    articleDefault,
    articleDefault,
    articleDefault,
  ],
  style: {},
  onClickArticle: null,
};

export default ComparisonList;
