import React, { useState, useEffect } from 'react';
import {
  StyleSheet, View, Image, Text,
} from 'react-native';
import { useTranslation } from 'react-i18next';

import { useAuthContextState } from '../../contexts/AuthContext';
import { useUserContextState } from '../../contexts/UserContext';
import COLORS from '../../utils/colors';
import { images } from '../../utils/constants';
import analytics from '../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: COLORS.primary,
    paddingTop: '5%',
  },
  text: {
    color: 'white',
    fontSize: 20,
  },
});

function Sso({ route }) {
  const authContext = useAuthContextState();
  const userContext = useUserContextState();
  const { t } = useTranslation();

  const authUser = async (token) => {
    const res = await authContext.authSsoUser(token);
    if (!res.error) {
      authContext.setUser('LoggedIn');
    }
    userContext.setSso(true);
  };

  useEffect(() => {
    if (route.params) {
      analytics.track('user is getting authenticated with SSO redirect');
      authUser(route.params.token);
    }
  }, []);

  return (
    <View style={styles.container}>
      <Image
        accessibilityIgnoresInvertColors
        source={{ uri: images.logoWhite, width: 305, height: 85 }}
        style={{ marginBottom: '10%' }}
      />
      {/* Loader */}
      <Text style={styles.text}>{t('auth.sso.loadingAuth')}</Text>
    </View>
  );
}

export default Sso;
