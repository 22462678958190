import React, { useEffect, useState, useRef } from 'react';
import * as Linking from 'expo-linking';
import {
  StyleSheet, View, Text, ScrollView, ActivityIndicator,
} from 'react-native';
import { TextInput } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import {
  ArrowLeft, EditPencil, Plus, Search as SearchIcon, Copy,
} from 'iconoir-react-native';
import { useTranslation } from 'react-i18next';

import { useContentContextState } from '../../contexts/ContentContext';
import { useNavigationContextState } from '../../contexts/NavigationContext';
import { useUserContextState } from '../../contexts/UserContext';
import editoApi from '../../api/editoApi';
import ArticleCard from '../../components/Article/ArticleCard/ArticleCard';
import Layout from '../../components/Common/Layout/Layout';
import COLORS from '../../utils/colors';
import Button from '../../components/Common/Button/Button';
import EditoModal from '../../components/Edito/EditoModal/EditoModal';
import analytics from '../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    backgroundColor: COLORS.background,
  },
  scrollview: {
    height: '100%',
    backgroundColor: COLORS.background,
    paddingHorizontal: '5%',
    alignItems: 'center',
  },
  backButton: {
    borderWidth: 1,
    borderColor: COLORS.textSecondary,
    borderRadius: 50,
    alignSelf: 'flex-start',
    width: '15%',
    marginVertical: '2%',
  },
  editButton: {
    borderWidth: 1,
    borderColor: COLORS.textSecondary,
    borderRadius: 50,
    width: '33%',
  },
  addArticle: {
    width: '40%',
    marginRight: '5%',
  },
  title: {
    color: 'white',
    fontSize: 27,
    fontWeight: 700,
    marginBottom: '2%',
    width: '100%',
    textAlign: 'center',
    paddingLeft: '5%',
    paddingRight: '7%',
  },
  description: {
    color: 'white',
    fontSize: 15,
    width: '80%',
    textAlign: 'center',
    marginBottom: '3%',
    paddingLeft: '5%',
    paddingRight: '7%',
  },
  buttonContainer: {
    flexDirection: 'row',
    width: '35%',
    justifyContent: 'center',
    paddingRight: '4%',
    marginBottom: '3%',
  },
  content: {
    backgroundColor: COLORS.background,
    height: '70%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    paddingHorizontal: '5%',
    paddingBottom: '5%',
  },
  article: {
    width: '24%',
    height: '28vh',
    marginVertical: '0.5%',
    marginHorizontal: '0.5%',
  },
  input: {
    backgroundColor: COLORS.backgroundSecondary,
    color: COLORS.primary,
    height: '100%',
    width: '100%',
    fontSize: 18,
    outlineStyle: 'none',
    paddingLeft: '2%',
  },
  inputContainer: {
    paddingHorizontal: '5%',
    borderRadius: 20,
    backgroundColor: COLORS.backgroundSecondary,
    height: 50,
    width: '50%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2%',
  },
  confirmButton: {
    width: '20%',
    alignSelf: 'center',
    bottom: '3%',
  },
});

function EditoDetail({ route }) {
  const navigation = useNavigation();
  const scrollViewRef = useRef();
  const { t } = useTranslation();
  const contentContext = useContentContextState();
  const navigationContext = useNavigationContextState();
  const userContext = useUserContextState();

  const [edito, setEdito] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isMine, setIsMine] = useState(false);
  const [isCopyHovered, setIsCopyHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState('Copier le lien');
  const [searchText, setSearchText] = useState('');
  const [addArticle, setAddArticle] = useState(false);
  const [articlesUrl, setArticlesUrl] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [editoImage, setEditoImage] = useState(null);

  async function searchArticles(text) {
    await contentContext.searchArticles(text);
  }

  async function getShareLink() {
    analytics.track('user shared an edito', { edito: edito.title, type: edito.type ? edito.type : null });
    setCopyText('Lien copié !');
    setIsCopied(true);
    const uri = await Linking.createURL('/edito/');
    navigator.clipboard.writeText(`${uri}${encodeURIComponent(edito.id)}`);
    setTimeout(() => {
      setCopyText('Copier le lien');
      setIsCopied(false);
    }, 3000);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchText !== '') {
        analytics.track('user searched for articles to add to edito', { text: searchText });
        searchArticles(searchText);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText]);

  async function onPressBack() {
    contentContext.searchArticles('');
    navigation.navigate('Revues', {});
  }

  async function getEditoById(id) {
    let editoId = id;
    if (editoId === null) {
      editoId = route.path.replace('/edito/', '');
    }
    const res = await editoApi.readEditoById(editoId);
    setArticlesUrl(res.articles.map((article) => article.url));
    setEdito(res);
    if (res.image) {
      setEditoImage(res.image);
    }
  }

  async function onSubmitSelection() {
    analytics.track('user added articles to edito', { articlesCount: selectedArticles.length });
    await contentContext.addArticleToEdito(edito.id, selectedArticles, editoImage);
    await getEditoById(edito.id);
  }

  async function onSelectArticle(article, image) {
    if (editoImage === null && image !== null) {
      setEditoImage(image);
    }
    if (article.url && article.url.includes('/api/v2/Documents/')) {
      setSelectedArticles(selectedArticles.concat([article]));
    } else {
      setSelectedArticles(selectedArticles.concat([article]));
    }
  }

  async function onDeleteArticle(article) {
    if (article.url.includes('/api/v2/Documents/')) {
      await contentContext.removeArticleFromEdito(edito.id, [article]);
    } else {
      await contentContext.removeArticleFromEdito(edito.id, [article.url]);
    }
    await getEditoById(edito.id);
  }

  useEffect(() => {
    if (edito) {
      setIsMine(edito.author === userContext.user.displayedName);
    }
  }, [edito]);

  useEffect(() => {
    const pressedEdito = navigationContext.currentEdito;
    if (pressedEdito) {
      getEditoById(pressedEdito.id);
    } else {
      getEditoById(null);
    }
  }, [navigationContext.currentEdito]);

  return (
    <Layout style={styles.container} currentPage="editoDetail">
      {modalVisible && (
        <EditoModal
          modalVisible={modalVisible}
          edito={edito}
          onClose={
            async () => {
              await getEditoById(edito.id); setModalVisible(false);
            }
        }
        />
      )}
      {edito && (
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={styles.scrollview}
        showsVerticalScrollIndicator={false}
      >
        {!addArticle && (
          <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Button
              backgroundColor="transparent"
              style={styles.backButton}
              accessibilityLabel={t('accessibility.labels.common.goBack')}
              accessibilityHint={t('accessibility.labels.common.goBack')}
              hoverable={false}
              onPress={onPressBack}
            >
              <View style={{ flexDirection: 'row' }}>
                <ArrowLeft role="none" color="white" height={15} width={15} />
                <Text style={{ color: 'white', marginLeft: 10 }}>{t('editos.back')}</Text>
              </View>
            </Button>

            <Button onPress={() => { getShareLink(); }} backgroundColor="transparent" hoverable={false} onHoverIn={() => { setIsCopyHovered(true); }} onHoverOut={() => { setIsCopyHovered(false); }}>
              <View style={{ flexDirection: 'row' }}>
                <Copy style={{ marginRight: 10 }} role="none" color={(isCopyHovered || isCopied) ? COLORS.primary : COLORS.textSecondary} height={20} width={20} />
                <Text style={{ color: (isCopyHovered || isCopied) ? COLORS.primary : 'white' }}>{copyText}</Text>
              </View>
            </Button>
          </View>
        )}
        {addArticle && (
        <Button
          backgroundColor="transparent"
          style={styles.backButton}
          accessibilityLabel={t('common.previous')}
          accessibilityHint={t('common.previous')}
          hoverable={false}
          onPress={() => { setAddArticle(false); }}
        >
          <View style={{ flexDirection: 'row' }}>
            <ArrowLeft role="none" color="white" height={15} width={15} />
            <Text style={{ color: 'white', marginLeft: 10 }}>{t('common.previous')}</Text>
          </View>
        </Button>
        )}
        <Text style={styles.title}>{edito.title}</Text>
        {(edito && edito.description && edito.description.length > 0) && (
        <Text style={styles.description}>{edito.description}</Text>
        )}
        {(isMine && !addArticle) && (
          <View style={styles.buttonContainer}>
            <Button style={styles.addArticle} onPress={() => { analytics.track('user clicked on add articles to edito button'); setAddArticle(true); }}>
              <View style={{ flexDirection: 'row' }}>
                <Plus style={{ marginTop: 2 }} role="none" color="white" height={15} width={15} />
                <Text style={{ color: 'white' }}>{t('editos.addArticle')}</Text>
              </View>
            </Button>
            <Button
              backgroundColor="transparent"
              style={styles.editButton}
              accessibilityLabel={t('common.edit')}
              accessibilityHint={t('common.edit')}
              hoverable={false}
              onPress={() => { analytics.track('user clicked on edit edito button', { edito: edito.title }); setModalVisible(true); }}
            >
              <View style={{ flexDirection: 'row' }}>
                <EditPencil style={{ marginTop: 2 }} role="none" color="white" height={15} width={15} />
                <Text style={{ color: 'white', marginLeft: 10 }}>{t('common.edit')}</Text>
              </View>
            </Button>
          </View>
        )}
        {(!addArticle && edito && edito.articles && edito.articles.length > 0) && (
        <View style={styles.content}>
          {edito.articles.map((prop, key) => (
            <ArticleCard
              key={`${prop.url}-${key}`}
              bookmarkable={false}
              article={prop}
              deletable
              onDelete={() => { onDeleteArticle(prop); }}
              style={styles.article}
              prevPage="Revue"
            />
          ))}
        </View>
        )}
        {addArticle && (
        <View style={{ width: '100%', alignItems: 'center' }}>
          <View style={styles.inputContainer}>
            <SearchIcon role="none" color="white" height={25} width={25} />
            <TextInput value={searchText} onChangeText={(text) => setSearchText(text)} style={styles.input} accessibilityLabel="Text input field" accessibilityHint="Rechercher un article" placeholder="Rechercher un article..." placeholderTextColor={COLORS.primary} />
          </View>
          <View style={styles.content}>
            {contentContext.isLoading && (
              <ActivityIndicator style={{ marginLeft: '50%' }} size="large" color={COLORS.primary} />
            )}
            {!contentContext.isLoading && contentContext.searchedArticles.map((prop, key) => (
              <ArticleCard
                key={`${prop.url}-${key}`}
                bookmarkable={false}
                selectable
                onSelect={() => { onSelectArticle(prop.url, prop.image); }}
                isSelected={(articlesUrl && articlesUrl.includes(prop.url))}
                article={prop}
                style={styles.article}
                prevPage="Revue"
              />
            ))}
          </View>
        </View>
        )}
        {(addArticle && contentContext.searchedArticles.length > 0) && (<Button style={styles.confirmButton} onPress={() => { onSubmitSelection(); setAddArticle(false); contentContext.searchArticles(''); }} text={t('editos.confirmSelection')} />)}
      </ScrollView>
      )}
    </Layout>
  );
}

export default EditoDetail;
