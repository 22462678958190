import PropTypes from 'prop-types';
import { articleType, articleDefault } from './article';

const editoType = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  isPublic: PropTypes.bool,
  articles: PropTypes.arrayOf(PropTypes.oneOfType([articleType, PropTypes.string])),
  type: PropTypes.oneOf(['inapp', 'actu', null]),
  author: PropTypes.string,
  authorFirstName: PropTypes.string,
  image: PropTypes.string,
});

const editoDefault = {
  id: 7086,
  title: "07/03 📜 Liberté d'avorter : un premier pas historique",
  description: "Cette semaine dans \"1 actu / 5 points de vue\" nous mettons le focus sur : \n👉 L'inscription de la liberté de recourir à l'IVG inscrite dans la constitution \n👉 L'histoire de cette liberté, ses combats et les conditions concrètes de sa mise en œuvre vues par des prismes variés\n👉 L'histoire de cette liberté, ses combats et les conditions concrètes de sa mise en œuvre vues par des prismes variés",
  imageUrl: 'https://www.humanite.fr/wp-content/uploads/2024/03/p21-ivg_MAR.jpg',
  isPublic: true,
  articles: [articleDefault, articleDefault, articleDefault, articleDefault, articleDefault, articleDefault, articleDefault, articleDefault, articleDefault, articleDefault],
  type: 'actu',
  author: 'be. my media',
  authorFirstName: 'be. my media',
  image: 'https://huffpost-focus.sirius.press/2024/03/05/75/0/1024/576/1820/1023/75/0/a5e6c91_1709648666451-000-34kt263.jpg',
};

export { editoType, editoDefault };
