export default {
  screens: {
    Welcome: 'welcome',
    Sso: 'sso',
    Disconnected: 'disconnected',
    Accueil: 'home',
    Error: 'error',
    Article: 'article/:url',
    Progresser: 'progress',
    Recherche: 'search',
    Revues: 'editos',
    Revue: 'edito/:id',
    Account: 'account',
  },
};
