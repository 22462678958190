import Api from '../utils/api';

const getUserProgress = async () => {
  try {
    const res = await Api.hero.get('/progress/webapp/getProgress');
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const startFinalQuiz = async (firstName, lastName) => {
  try {
    const body = {
      firstName,
      lastName,
    };
    const res = await Api.hero.post('/progress/webapp/startFinalQuiz', body);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const endFinalQuiz = async (mark) => {
  try {
    const body = {
      mark,
    };
    const res = await Api.hero.post('/progress/webapp/endFinalQuiz', body);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const updateUserProgress = async (
  episodeId,
  seasonId,
  isEpisodeCompleted,
  isSeasonCompleted,
  pageIndex,
) => {
  try {
    const body = {
      episodeId,
      seasonId,
      isEpisodeCompleted,
      isSeasonCompleted,
      pageIndex,
    };
    const res = await Api.hero.post('/progress/{webapp}/update', body);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const sendMail = async () => {
  try {
    const res = await Api.hero.post('/progress/webapp/getMail');
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

export default {
  getUserProgress,
  updateUserProgress,
  startFinalQuiz,
  endFinalQuiz,
  sendMail,
};
