import React from 'react';
import {
  View, StyleSheet, Modal,
} from 'react-native';
import PropTypes from 'prop-types';
import COLORS from '../../../utils/colors';

const styles = StyleSheet.create({
  modalContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.primaryTransparent,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

function BmmModal({
  children,
  style,
  modalVisible,
}) {
  return (
    <Modal
      visible={modalVisible}
      transparent
    >
      <View style={({ ...styles.modalContainer, ...style })}>
        {children}
      </View>
    </Modal>
  );
}

BmmModal.propTypes = {
  children: PropTypes.element,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  modalVisible: PropTypes.bool,
};

BmmModal.defaultProps = {
  children: undefined,
  style: {},
  modalVisible: false,
};

export default BmmModal;
