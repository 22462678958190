import AsyncStorage from '@react-native-async-storage/async-storage';
import StorageKeys from '../utils/StorageKeys';
import Api from '../utils/api';

const loginUser = async (email, password) => {
  try {
    const res = await Api.hero.post('/auth', {
      mail: email,
      password,
    });
    await AsyncStorage.setItem(StorageKeys.userToken, res);
    Api.hero.setAccessToken(res);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const registerUser = async (email, password) => {
  try {
    const res = await Api.hero.post('/users', {
      mail: email,
      password,
    });
    await AsyncStorage.setItem(StorageKeys.userToken, res.token);
    Api.hero.setAccessToken(res.token);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const askResetPassword = async (email) => {
  try {
    const res = await Api.hero.post('/resetpassword', {
      mail: email,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const getUserInfos = async () => {
  try {
    const res = await Api.hero.get('/users/not_needed');
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const updateUser = async (interests) => {
  try {
    const res = await Api.hero.patch('/users/webapp', { interests });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const executeAction = async (action) => {
  try {
    const res = await Api.hero.post('/action/webapp', {
      action,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

export default {
  loginUser,
  getUserInfos,
  executeAction,
  registerUser,
  askResetPassword,
  updateUser,
};
