import React, { useState, useEffect } from 'react';
import {
  StyleSheet, View, Image, Text,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';

import { useAuthContextState } from '../../contexts/AuthContext';
import { useUserContextState } from '../../contexts/UserContext';
import COLORS from '../../utils/colors';
import { images } from '../../utils/constants';

import Button from '../../components/Common/Button/Button';
import Card from '../../components/Common/Card/Card';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import RegisterForm from '../../components/Forms/RegisterForm/RegisterForm';
import SsoAuths from '../../components/Sso/SsoAuths/SsoAuths';
import analytics from '../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: COLORS.primary,
    paddingTop: '5%',
  },
  buttonsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    paddingHorizontal: 20,
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontSize: 22,
  },
});

function Welcome({ route }) {
  const authContext = useAuthContextState();
  const userContext = useUserContextState();
  const { t } = useTranslation();
  const [isLoginForm, setIsLoginForm] = useState(false);
  const [isRegisterForm, setIsRegisterForm] = useState(false);
  const [isSsoAuths, setIsSsoAuths] = useState(false);
  const [logoOffset, setLogoOffset] = useState('10%');
  const [cardSize, setCardSize] = useState(200);
  const [isSsoLogout, setIsSsoLogout] = useState(false);

  useEffect(() => {
    setIsSsoLogout(userContext.isSso);
  }, []);

  useEffect(() => {
    if (isLoginForm || isSsoAuths) {
      setLogoOffset('5%');
      setCardSize(400);
    } else if (isRegisterForm) {
      setLogoOffset('5%');
      setCardSize(450);
    } else {
      setLogoOffset('10%');
      setCardSize(200);
    }
  }, [isLoginForm, isRegisterForm, isSsoAuths]);

  return (
    <View style={styles.container}>
      <Image
        accessibilityIgnoresInvertColors
        source={{ uri: images.logoWhite, width: 305, height: 85 }}
        style={{ marginBottom: logoOffset }}
      />
      {!isSsoLogout && (
        <Card
          canGoBack={isLoginForm || isRegisterForm || isSsoAuths}
          onGoBack={() => { analytics.track('user clicked on back to welcome page button'); setIsLoginForm(false); setIsRegisterForm(false); setIsSsoAuths(false); }}
          style={{
            width: '30%', height: cardSize,
          }}
        >
          {isLoginForm && (<LoginForm onSubmit={authContext.login} onResetPassword={authContext.askResetPassword} />)}
          {isRegisterForm && (<RegisterForm onSubmit={authContext.register} />)}
          {isSsoAuths && (<SsoAuths />)}
          {(!isLoginForm && !isRegisterForm && !isSsoAuths) && (
          <View style={styles.buttonsContainer}>
            <Button style={{ width: '80%', height: '20%', marginBottom: 20 }} onPress={() => { analytics.track('user clicked on first connection button'); setIsRegisterForm(true); }} text={t('auth.firstConnexion')} />
            <Button
              backgroundColor="transparent"
              color={COLORS.primary}
              hoveredColor={COLORS.primary}
              hoveredTextColor="white"
              style={{
                width: '80%', height: '20%', borderWidth: 1, borderColor: COLORS.primary, marginBottom: 20,
              }}
              onPress={() => { analytics.track('user clicked on connection button'); setIsLoginForm(true); }}
              text={t('auth.alreadyRegistered')}
            />
            <Text onPress={() => { analytics.track('user clicked on SSO connection button'); setIsSsoAuths(true); }} style={{ color: COLORS.primary, textDecorationLine: 'underline' }}>Autres moyens de connexion</Text>
          </View>
          )}
        </Card>
      )}
      {isSsoLogout && (
        <Text style={styles.text}>{t('auth.sso.disconnected')}</Text>
      )}

    </View>
  );
}

export default Welcome;
