import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, Text, Pressable, Modal,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import {
  Circle, CheckCircle, Pause, PauseSolid, PlaySolid, Lock,
} from 'iconoir-react-native';
import PropTypes from 'prop-types';
import COLORS from '../../../utils/colors';

import EpisodeModal from '../EpisodeModal/EpisodeModal.web';
import EpisodeModalQuiz from '../EpisodeModalQuiz/EpisodeModalQuiz.web';
import { useProgressContextState } from '../../../contexts/ProgressContext';
import analytics from '../../../utils/analytics';
import { useUserContextState } from '../../../contexts/UserContext';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: '2%',
  },
  cardLeft: {
    backgroundColor: COLORS.backgroundSecondary,
    flex: 7,
    flexDirection: 'column',
    paddingTop: '1%',
    paddingBottom: '2%',
    paddingHorizontal: '1.5%',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRightColor: '#3c3c3c',
    borderRightWidth: 2,
  },
  cardRight: {
    backgroundColor: COLORS.backgroundTernary,
    flex: 1,
    flexDirection: 'column',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  metadata: {
    flexDirection: 'row',
  },
  metadataText: {
    color: COLORS.textSecondary,
    fontSize: 13,
    marginBottom: '1.5%',
  },
  title: {
    color: 'white',
    fontSize: 14,
  },
  icon: {
    marginRight: '1%',
  },
  iconButton: {
    backgroundColor: COLORS.primary,
    borderRadius: 100,
    width: 32,
    height: 32,
    marginBottom: '2%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  roundedIcon: {
    borderRadius: 100,
    width: 18,
    height: 18,
    borderColor: '#ad6c1d',
    borderWidth: 1,
    marginRight: '1%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function EpisodeCard({
  style,
  title,
  seasonNumber,
  seasonId,
  episode,
  pendingPage,
  onPress,
  isLastEpisode,
}) {
  const progressContext = useProgressContextState();
  const userContext = useUserContextState();
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  function onEpisodePress() {
    analytics.track('user clicked on episode item', {
      from: 'progress-tab', episode: episode.position, isQuiz: episode.isQuiz, season: seasonNumber, status: episode.status,
    });
    if (episode.isAvailable) {
      setModalVisible(true);
    }
    onPress();
  }

  async function onEpisodeModalClose(status, page = 1) {
    analytics.track('user closed episode modal', {
      from: 'progress-tab', episode: episode.position, isQuiz: episode.isQuiz, season: seasonNumber, status: episode.status,
    });
    if (status !== 'failed') {
      let isSeasonCompleted = false;
      const isEpisodeCompleted = (status === 'completed');
      if (isLastEpisode) {
        isSeasonCompleted = true;
      }
      await progressContext.updateProgress(episode.id, seasonId, isEpisodeCompleted, isSeasonCompleted, page);
      if (isSeasonCompleted) {
        await userContext.loadUserData();
      }
    }
    setModalVisible(false);
  }

  return (
    <View key={`episode-S${seasonNumber}-ep${episode.number}`} style={({ ...styles.container, ...style })}>
      {(modalVisible && episode && episode.pages[0].type !== 'Quiz' && episode.pages[0].type !== 'QuizImageText' && episode.pages[0].type !== 'QuizImage')
      && (
      <EpisodeModal
        onPressClose={onEpisodeModalClose}
        modalVisible={modalVisible}
        episode={episode}
        seasonNumber={seasonNumber}
        pendingPage={pendingPage}
      />
      )}
      {(modalVisible && episode && (episode.pages[0].type === 'Quiz' || episode.pages[0].type === 'QuizImageText' || episode.pages[0].type === 'QuizImage'))
      && (
        <EpisodeModalQuiz
          onPressClose={onEpisodeModalClose}
          modalVisible={modalVisible}
          episode={episode}
          seasonNumber={seasonNumber}
        />
      )}
      <View key={`episode-S${seasonNumber}-ep${episode.number}-left`} style={styles.cardLeft}>
        <View key={`episode-S${seasonNumber}-ep${episode.number}-meta`} style={styles.metadata}>
          {(episode.isAvailable && episode.status === 'completed') && (
            <CheckCircle key={`episode-S${seasonNumber}-ep${episode.number}-metaicon`} style={styles.icon} role="none" color="green" height={18} width={18} />
          )}
          {(episode.status === 'not_started' || (episode.status === 'started' && episode.currentPageIndex === 1) || !episode.isAvailable) && (
            <Circle key={`episode-S${seasonNumber}-ep${episode.number}-metaicon`} style={styles.icon} role="none" color={COLORS.textSecondary} height={18} width={18} />
          )}
          {(episode.isAvailable && episode.status === 'started' && episode.currentPageIndex !== 1) && (
            <View key={`episode-S${seasonNumber}-ep${episode.number}-metaiconrounded`} style={styles.roundedIcon}>
              <Pause key={`episode-S${seasonNumber}-ep${episode.number}-metaicon`} role="none" color="#ad6c1d" height={13} width={13} />
            </View>
          )}
          <Text key={`episode-S${seasonNumber}-ep${episode.number}-metatext`} style={styles.metadataText}>
            S0
            {seasonNumber}
            {' '}
            - Épisode
            {' '}
            {episode.number}
          </Text>
        </View>
        <Text key={`episode-S${seasonNumber}-ep${episode.number}-title`} style={styles.title}>{title}</Text>
      </View>
      <Pressable
        key={`episode-S${seasonNumber}-ep${episode.number}-right`}
        accessibilityRole="button"
        style={styles.cardRight}
        onPress={() => { onEpisodePress(); }}
      >
        {(episode.isAvailable && episode.status === 'completed') && (
          [
            <View key={`episode-S${seasonNumber}-ep${episode.number}-iconround`} style={[styles.iconButton, { backgroundColor: COLORS.background }]}>
              <PlaySolid key={`episode-S${seasonNumber}-ep${episode.number}-icon`} role="none" color="white" height={15} width={15} />
            </View>,
            <Text key={`episode-S${seasonNumber}-ep${episode.number}-icontext`} style={{ color: 'white', fontSize: 13 }}>{t('progress.reRead')}</Text>,
          ]
        )}
        {(episode.isAvailable && (episode.status === 'not_started' || (episode.status === 'started' && episode.currentPageIndex === 1))) && (
          [
            <View key={`episode-S${seasonNumber}-ep${episode.number}-iconround`} style={styles.iconButton}>
              <PlaySolid key={`episode-S${seasonNumber}-ep${episode.number}-icon`} role="none" color="white" height={15} width={15} />
            </View>,
            <Text key={`episode-S${seasonNumber}-ep${episode.number}-icontext`} style={{ color: 'white', fontSize: 13 }}>{t('progress.read')}</Text>,
          ]
        )}
        {(episode.isAvailable && episode.status === 'started' && episode.currentPageIndex !== 1) && (
          [
            <View key={`episode-S${seasonNumber}-ep${episode.number}-iconround`} style={styles.iconButton}>
              <PauseSolid key={`episode-S${seasonNumber}-ep${episode.number}-icon`} role="none" color="white" height={15} width={15} />
            </View>,
            <Text key={`episode-S${seasonNumber}-ep${episode.number}-icontext`} style={{ color: 'white', fontSize: 13 }}>{t('progress.continue')}</Text>,
          ]
        )}
        {!episode.isAvailable && (
          [
            <View key={`episode-S${seasonNumber}-ep${episode.number}-iconround`} style={[styles.iconButton, { backgroundColor: COLORS.background }]}>
              <Lock key={`episode-S${seasonNumber}-ep${episode.number}-icon`} role="none" color="white" height={15} width={15} />
            </View>,
            <Text key={`episode-S${seasonNumber}-ep${episode.number}-icontext`} style={{ color: 'white', fontSize: 13 }}>{t('progress.locked')}</Text>,
          ]
        )}
      </Pressable>
    </View>
  );
}

EpisodeCard.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  title: PropTypes.string,
  seasonNumber: PropTypes.number,
  seasonId: PropTypes.number,
  episode: PropTypes.object,
  pendingPage: PropTypes.number,
  onPress: PropTypes.func,
  isLastEpisode: PropTypes.bool,
};

EpisodeCard.defaultProps = {
  style: {},
  title: 'Le rôle des médias',
  seasonNumber: 2,
  seasonId: 0,
  pendingPage: 1,
  episode: { number: 17 },
  onPress: () => {},
  isLastEpisode: false,
};

export default EpisodeCard;
