import React, { useState } from 'react';
import {
  StyleSheet, View, Text, TextInput, Linking,
} from 'react-native';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { CheckBox } from 'react-native-web';

import { externalLinks } from '../../../utils/constants';
import COLORS from '../../../utils/colors';
import Button from '../../Common/Button/Button.web';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: '5%',
    flex: 8,
  },
  title: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '3%',
  },
  label: {
    color: COLORS.textSecondary,
    fontSize: 14,
    marginBottom: '2%',
    alignSelf: 'flex-start',
  },
  input: {
    backgroundColor: COLORS.backgroundSecondary,
    width: '100%',
    borderRadius: 10,
    outlineStyle: 'none',
    paddingHorizontal: '5%',
    minHeight: '9%',
    color: 'white',
    borderColor: COLORS.textSecondary,
    borderWidth: 1,
    marginBottom: '3%',
    fontSize: 14,
  },
  transText: {
    color: 'white',
    fontSize: 14,
    alignSelf: 'flex-start',
    fontFamily: null,
    textAlign: 'left',
  },
  error: {
    fontSize: 12,
    color: COLORS.error,
    marginBottom: '2%',
  },
});

function RegisterForm({
  onSubmit,
  style,
}) {
  const { t } = useTranslation();
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState(null);

  async function submitForm() {
    analytics.track('user submitted registration form');
    if (password !== passwordConfirmation) {
      analytics.track('user registration failed', { error: 'password is invalid' });
      setError(t('auth.form.error.passwordError'));
      return;
    }
    if (!isChecked) {
      analytics.track('user registration failed', { error: 'cgu not accepted' });
      setError(t('auth.form.error.cguError'));
    }
    const res = await onSubmit(mail, password);
    if (res.error) {
      analytics.track('user registration failed', { error: res.error.message });
      setError(res.error);
    } else {
      analytics.track('user successfully registered');
    }
  }

  return (
    <View style={[styles.container, { ...style }]}>
      <Text style={[styles.title, { marginBottom: error ? '1%' : '3%' }]}>{t('auth.firstConnexion')}</Text>
      {error && (<Text style={styles.error}>{error}</Text>)}
      <Text style={styles.label}>
        {t('auth.form.mail')}
        {' '}
        {t('auth.form.invitedMail')}
      </Text>
      <TextInput accessibilityLabel="Text input field password" value={mail} onChangeText={(text) => setMail(text)} style={styles.input} accessibilityHint={t('auth.form.mail')} />
      <Text style={styles.label}>{t('auth.form.password')}</Text>
      <TextInput accessibilityLabel="Text input field" secureTextEntry value={password} onChangeText={(text) => setPassword(text)} style={styles.input} accessibilityHint={t('auth.form.password')} />
      <Text style={styles.label}>{t('auth.form.passwordConfirmation')}</Text>
      <TextInput accessibilityLabel="Text input field" secureTextEntry value={passwordConfirmation} onChangeText={(text) => setPasswordConfirmation(text)} style={styles.input} accessibilityHint={t('auth.form.passwordConfirmation')} />
      <View style={{ flexDirection: 'row', marginBottom: '2%' }}>
        <CheckBox
          style={{ marginRight: '2%', marginTop: '3%' }}
          color={isChecked ? COLORS.primary : COLORS.backgroundSecondary}
          value={isChecked}
          onValueChange={(value) => { setIsChecked(value); }}
        />
        <Text style={styles.transText}>
          <Trans
            t={t}
            i18nKey="auth.form.acceptCgu"
            style={styles.transText}
            components={[
              <Text key={0} style={[styles.transText, { textDecorationLine: 'underline' }]} onPress={() => { analytics.track('user click on cgu link'); Linking.openURL(externalLinks.cgu); }} />,
              <Text key={1} style={[styles.transText, { textDecorationLine: 'underline' }]} onPress={() => { analytics.track('user click on confidentiality link'); Linking.openURL(externalLinks.privacy); }} />,
            ]}
          />
        </Text>
      </View>
      <Button
        style={{
          width: '80%', height: '10%', marginBottom: 20, marginTop: 10,
        }}
        onPress={() => { submitForm(); }}
        text={t('auth.loginButton')}
      />
    </View>
  );
}

RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};
RegisterForm.defaultProps = {
  onSubmit: () => {},
  style: {},
};

export default RegisterForm;
