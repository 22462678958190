import React from 'react';
import {
  Text, StyleSheet, View, ProgressBar,
} from 'react-native';
import PropTypes from 'prop-types';

import COLORS from '../../../utils/colors';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
  },
  mainContainer: { marginBottom: 14 },
  level: {
    fontSize: 12,
    color: 'white',
    textAlign: 'center',
    lineHeight: 10,
  },
  progressbar: {
    marginHorizontal: 12,
    flex: 1,
    paddingTop: 2.5,
    width: '100%',
  },
  progress: {
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    paddingHorizontal: 8,
    paddingBottom: 3,
    marginTop: 4,
    alignSelf: 'flex-start',
  },
  progressText: {
    color: 'white',
    fontSize: 12,
  },
});

function CustomProgressBar({
  style,
  userExperience,
  requiredExperience,
  previousExperience,
}) {
  let percentage = 0;
  if (userExperience >= previousExperience) percentage = ((userExperience - previousExperience) * 100) / (requiredExperience - previousExperience);
  return (
    <View style={({ ...styles.container, ...style })}>
      <Text style={styles.level}>
        {previousExperience}
      </Text>
      <View style={styles.progressbar}>
        <ProgressBar
          progress={percentage * 0.01}
          color={COLORS.primary}
          trackColor="#e2e2e2"
        />
        {userExperience >= previousExperience && (
        <View
          style={[
            styles.progress,
            {
              marginLeft:
                      (userExperience > requiredExperience * 0.85)
                        ? 'auto'
                        : `${percentage - 5}%`,
            },
          ]}
        >
          <Text style={[styles.progressText]}>{userExperience}</Text>
        </View>
        )}
      </View>
      <Text style={styles.level}>
        {requiredExperience}
      </Text>
    </View>
  );
}

CustomProgressBar.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  userExperience: PropTypes.number,
  requiredExperience: PropTypes.number,
  previousExperience: PropTypes.number,
};

CustomProgressBar.defaultProps = {
  style: {},
  userExperience: 100,
  requiredExperience: 500,
  previousExperience: 0,
};

export default CustomProgressBar;
