import React, {
  createContext, useContext, useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import userApi from '../api/userApi';
import analytics from '../utils/analytics';

const UserContext = createContext();

const useUserContextState = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContextState was used outside of its Provider');
  }

  return context;
};

function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isUpgradingLevel, setIsUpgradingLevel] = useState(null);
  const [isSso, setIsSso] = useState(false);
  const [haveAgree, setHaveAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const logout = useCallback(() => {
    setUser(null);
  }, []);

  const getUserInterests = useCallback(() => {
    if (user) {
      return ['Ma une'].concat(user.group.categories).concat(user.interests);
    }
    return [];
  }, [user && user.group && user.group.categories, user && user.interests]);

  async function executeAction(action) {
    analytics.track('user did an action', { action });
    const actionRes = await userApi.executeAction(action);
    const res = await userApi.getUserInfos();
    if (!res.error) {
      setUser(res);
      if (actionRes.newBadges && actionRes.newBadges.length > 0) {
        setIsUpgradingLevel(actionRes.newBadges[actionRes.newBadges.length - 1]);
      }
    }
  }

  const loadUserData = useCallback(async () => {
    setIsLoading(true);
    const userInfos = await userApi.getUserInfos();
    if (!userInfos.error) {
      if (userInfos.affiliate && userInfos.affiliate.sso_id !== null) {
        setIsSso(true);
      }
      if (userInfos !== user) {
        setUser(userInfos);
      }
    }
    setIsLoading(false);
  }, []);

  const updateUserInterests = useCallback(async (interests) => {
    const res = await userApi.updateUser(interests);
    if (res.error) {
      console.log(res);
    }
    executeAction('my_feed');
    loadUserData();
  }, []);

  const setSso = useCallback((sso) => {
    setIsSso(sso);
  });

  const contextValue = useMemo(() => ({
    user,
    isLoading,
    setSso,
    isSso,
    executeAction,
    getUserInterests,
    loadUserData,
    logout,
    updateUserInterests,
    isUpgradingLevel,
    setIsUpgradingLevel,
    haveAgree,
    setHaveAgree,
  }), [user,
    setSso,
    isSso,
    isLoading,
    logout,
    loadUserData,
    getUserInterests,
    executeAction,
    isUpgradingLevel,
    setIsUpgradingLevel,
    haveAgree,
    setHaveAgree,
  ]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.element,
};
UserContextProvider.defaultProps = {
  children: null,
};

export { UserContextProvider, useUserContextState };
