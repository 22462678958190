import Api from '../utils/api';

// const oldGetNewsList = async ({
//   userid,
//   from,
//   article,
//   tagTopics,
//   geo,
//   tagCategory,
//   isEnterprise,
//   editos,
// }) => {
//   try {
//     const res = await Api.hero.post(`/articles/${userid}/newsfeed`, {
//       tagTopics,
//       tagCategory,
//       // geo,
//       article: article || null,
//       from,
//       isEnterprise,
//       editos,
//     });
//     return res;
//   } catch (e) {
//     return { error: { message: e.message } };
//   }
// };

const getNewsList = async (
  from,
  tagCategory,
  isEnterprise,
  cisionSearchId,
  cisionOffset,
) => {
  try {
    const res = await Api.hero.post('/articles/webapp/newsfeed', {
      tagCategory,
      from: [from || new Date(Date.now()).toISOString()],
      isEnterprise,
      cisionSearchId,
      offset: cisionOffset,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const reportArticle = async (articlePath, reason) => {
  try {
    const res = await Api.hero.post('/article/webapp/signal', {
      id: articlePath,
      reason,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const searchArticle = async (text) => {
  try {
    const res = await Api.hero.post('/content/search', {
      content: text,
      type: 'article',
      userid: 'webapp',
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const getArticleByUrl = async (url) => {
  try {
    const res = await Api.hero.get(`/article?article=${url}`);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const getCleanedArticleByUrl = async (url) => {
  try {
    const res = await Api.hero.post('/cleanhtml', {
      article: url,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const getLinkedArticle = async (url) => {
  try {
    const res = await Api.hero.post('/articles/webapp/newsfeed', {
      article: url,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const getCategories = async () => {
  try {
    const res = await Api.hero.get('/categories');
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

export default {
  getNewsList,
  getCategories,
  getLinkedArticle,
  searchArticle,
  getArticleByUrl,
  getCleanedArticleByUrl,
  reportArticle,
};
