import React, { useState } from 'react';
import {
  StyleSheet, View, Text, Image,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUserContextState } from '../../../contexts/UserContext';
import { images } from '../../../utils/constants';

import Button from '../../Common/Button/Button';
import { editoType } from '../../../validation/edito';
import COLORS from '../../../utils/colors';
import BmmModal from '../../Common/Modal/BmmModal';
import Card from '../../Common/Card/Card';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  modalContent: {
    width: '25%',
    height: '35%',
    backgroundColor: COLORS.background,
  },
  cardContent: {
    flex: 5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%',
  },
  title: {
    fontSize: 18,
    color: 'white',
    fontWeight: 700,
    marginBottom: '5%',
    marginTop: '2%',
  },
  text: {
    fontSize: 14,
    color: 'white',
    marginBottom: '10%',
  },
  button: {
    width: '20%',
  },
});

function LevelUpgradeModal({
  style,
  isModalVisible,
  onClose,
}) {
  const { t } = useTranslation();
  const userContext = useUserContextState();

  return (
    <BmmModal modalVisible={isModalVisible} style={{ ...style }}>
      <Card style={styles.modalContent}>
        <View style={styles.cardContent}>
          <Image
            accessibilityIgnoresInvertColors
            source={{ uri: images[`level${userContext.isUpgradingLevel.id}`], width: 80, height: 80 }}
          />
          <Text style={styles.title}>{t('gamification.congrats')}</Text>
          <Text style={styles.text}>{t('gamification.unlockedBadge', { badgeName: userContext.isUpgradingLevel.name })}</Text>
          <Button onPress={() => { analytics.track('user closed level upgrade modal'); onClose(); }} text="Ok" style={styles.button} />
        </View>
      </Card>
    </BmmModal>
  );
}

LevelUpgradeModal.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  isModalVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

LevelUpgradeModal.defaultProps = {
  style: {},
  isModalVisible: false,
  onClose: () => {},
};

export default LevelUpgradeModal;
