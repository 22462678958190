import React, { useState, useEffect } from 'react';
import {
  StyleSheet, View, Image, Text, Pressable,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';
import moment from 'moment';
import { Bookmark, TrashSolid, BookmarkSolid } from 'iconoir-react-native';
import { useNavigation } from '@react-navigation/native';
import { CheckBox } from 'react-native-web';

import BookmarkModal from '../BookmarkModal/BookmarkModal.web';
import { articleType, articleDefault } from '../../../validation/article';
import Card from '../../Common/Card/Card.web';
import COLORS from '../../../utils/colors';
import Button from '../../Common/Button/Button.web';
import { getCategoryImage, getCisionImage } from '../../../utils/constants';
import { useContentContextState } from '../../../contexts/ContentContext';
import { useNavigationContextState } from '../../../contexts/NavigationContext';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  card: {
    borderRadius: 7,
  },
  articleImage: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    overflow: 'hidden',
  },
  footer: {
    paddingHorizontal: 15,
    paddingVertical: '2%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    color: 'white',
    fontSize: 15,
    maxHeight: '15%',
    marginBottom: '3%',
  },
  metadata: {
    color: COLORS.textSecondary,
    fontSize: 13,
  },
});

function ArticleCard({
  backgroundColor,
  index,
  style,
  titleColor,
  article,
  metadataColor,
  bigPicture,
  prevPage,
  bookmarkable,
  onPress,
  selectable,
  deletable,
  isSelected,
  onSelect,
  onDelete,
}) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const contentContext = useContentContextState();
  const navigationContext = useNavigationContextState();
  const [isIconHovered, setIsIconHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(isSelected);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [editoId, setEditoId] = useState(null);

  function getArticleImage() {
    if (article.url.includes('/api/v2/Documents/')) {
      if (contentContext.currentCategories.length === 1) {
        const isBlack = (index % 2 === 0);
        return (
          <Image
            accessibilityIgnoresInvertColors
            resizeMode="center"
            source={{ uri: getCisionImage(contentContext.currentCategories[0], isBlack), width: '100%', height: bigPicture ? '70%' : '50%' }}
            style={[styles.articleImage, (index % 2 === 0) ? { backgroundColor: 'black' } : { backgroundColor: 'white' }]}
          />
        );
      }
    }
    return (
      <Image
        accessibilityIgnoresInvertColors
        source={{ uri: article.image ? article.image : getCategoryImage(article.tagCategory), width: '100%', height: bigPicture ? '70%' : '50%' }}
        style={styles.articleImage}
      />
    );
  }

  function checkBookmark() {
    const res = contentContext.checkBookmarkedArticle(article.url);
    setIsBookmarked(res.isBookmarked);
    setEditoId(res.editoId);
  }

  useEffect(() => {
    checkBookmark();
  }, [contentContext.bookmarkedArticles]);

  async function onPressArticle() {
    analytics.track('user clicked on feed item', {
      type: 'article', isBig: bigPicture, article, source: article.sourceName,
    });
    analytics.track('user clicked on article', {
      from: prevPage, isBig: bigPicture, article: article.title, source: article.sourceName,
    });
    onPress(article.url.trim());
    await navigationContext.onPressArticle(article, prevPage);
    navigation.navigate('Article', {
      url: article.url.trim(),
    });
  }

  function getDislayedTitle(title) {
    const limit = 80;
    if (title.length > limit) {
      return title.slice(0, limit - 5).concat('[...]');
    }
    return title;
  }

  return (
    <Card backgroundColor={backgroundColor} style={{ ...style }}>
      {isModalVisible && (
        <BookmarkModal
          modalVisible={isModalVisible}
          onClose={() => { setIsModalVisible(false); checkBookmark(); }}
          article={article}
        />
      )}
      <Pressable
        onPress={() => { onPressArticle(); }}
        accessibilityRole="button"
        style={{ width: '100%', height: '100%' }}
      >
        {getArticleImage()}
        <View style={({ height: bigPicture ? '30%' : '50%', ...styles.footer })}>
          <Text
            style={[styles.title, { color: titleColor }]}
          >
            {getDislayedTitle(article.title)}
          </Text>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Text style={[styles.metadata, { color: metadataColor }]}>
              {article.sourceName}
              {' • '}
              {Array.isArray(article.tagCategory) ? article.tagCategory[0] : article.tagCategory}
              {' '}
              {article.timestamp && moment(article.timestamp).fromNow()}
            </Text>
            {(bookmarkable && !isBookmarked) && (
              <Button
                onHoverIn={() => { setIsIconHovered(true); }}
                onHoverOut={() => { setIsIconHovered(false); }}
                hoverable={false}
                backgroundColor="transparent"
                onPress={() => {
                  analytics.track('user bookmarked an article', { article: article.url });
                  setIsModalVisible(true);
                }}
                accessibilityLabel={t('accessibility.labels.article.delete')}
                accessibilityHint={t('accessibility.hints.article.delete')}
              >
                <Bookmark role="none" color={isIconHovered ? COLORS.primary : metadataColor} height={20} width={20} />
              </Button>
            )}
            {(bookmarkable && isBookmarked) && (
              <Button
                hoverable={false}
                backgroundColor="transparent"
                onPress={() => {
                  analytics.track('user unbookmarked an article', { article: article.url });
                  if (article.url.includes('/api/v2/Documents/')) {
                    contentContext.removeArticleFromEdito(editoId, [article]);
                  } else {
                    contentContext.removeArticleFromEdito(editoId, [article.url]);
                  }
                }}
                accessibilityLabel={t('accessibility.labels.article.delete')}
                accessibilityHint={t('accessibility.hints.article.delete')}
              >
                <BookmarkSolid role="none" color={COLORS.primary} height={20} width={20} />
              </Button>
            )}
            {deletable && (
              <Button onHoverIn={() => { setIsIconHovered(true); }} onHoverOut={() => { setIsIconHovered(false); }} hoverable={false} backgroundColor="transparent" onPress={onDelete} accessibilityLabel={t('accessibility.labels.article.delete')} accessibilityHint={t('accessibility.hints.article.delete')}>
                <TrashSolid role="none" color={isIconHovered ? COLORS.primary : metadataColor} height={18} width={18} />
              </Button>
            )}
            {selectable && (
              <Pressable accessibilityRole="button" onPress={onSelect}>
                <CheckBox
                  style={{ borderWidth: 1, borderColor: metadataColor }}
                  color={isChecked ? COLORS.primary : COLORS.backgroundSecondary}
                  value={isChecked}
                  onValueChange={(value) => { setIsChecked(value); }}
                />
              </Pressable>
            )}
          </View>
        </View>
      </Pressable>
    </Card>
  );
}

ArticleCard.propTypes = {
  backgroundColor: PropTypes.string,
  index: PropTypes.number,
  titleColor: PropTypes.string,
  metadataColor: PropTypes.string,
  prevPage: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  bigPicture: PropTypes.bool,
  bookmarkable: PropTypes.bool,
  selectable: PropTypes.bool,
  deletable: PropTypes.bool,
  article: articleType,
  onPress: PropTypes.func,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  isSelected: PropTypes.bool,
};
ArticleCard.defaultProps = {
  backgroundColor: COLORS.backgroundSecondary,
  index: 0,
  style: {},
  titleColor: 'white',
  prevPage: 'Accueil',
  metadataColor: COLORS.textSecondary,
  bigPicture: false,
  bookmarkable: true,
  selectable: false,
  deletable: false,
  article: articleDefault,
  isSelected: false,
  onPress: () => {},
  onSelect: () => {},
  onDelete: () => {},
};

export default ArticleCard;
