import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, Text,
  Image,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '../../Common/Button/Button';
import COLORS from '../../../utils/colors';
import { images } from '../../../utils/constants';
import FinalQuizModal from '../FinalQuizModal/FinalQuizModal';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: '2%',
  },
  status: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    paddingHorizontal: 20,
    paddingVertical: 5,
    backgroundColor: COLORS.backgroundSecondary,
    marginBottom: '2%',
  },
  statusText: {
    color: 'white',
  },
  title: {
    color: 'white',
    width: '60%',
    fontSize: 25,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '1%',
  },
  paragraph: {
    width: '100%',
    minHeight: '55%',
    color: 'white',
    textAlign: 'left',
    fontSize: 17,
    paddingLeft: '20%',
    paddingRight: '20%',
    marginBottom: 30,
  },
  button: {
    width: '10%',
    height: '5%',
  },
});

function FinalQuiz({
  style,
  status,
  xpLastTry,
  currentXp,
  sendMail,
  seasonIndex,
}) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);

  async function pressSendMail() {
    analytics.track('user asked for final attestation');
    const res = await sendMail();
    if (res === null) {
      // Erreur d'envoi
    } else {
      setIsMailSent(true);
      setTimeout(() => {
        setIsMailSent(false);
      }, 3000);
    }
  }

  return (
    <View style={({ ...styles.container, ...style })}>
      {isModalVisible && (
        <FinalQuizModal
          onClose={() => { setIsModalVisible(false); }}
          modalVisible={isModalVisible}
          seasonIndex={seasonIndex}
        />
      )}
      {!isModalVisible && (
      <View style={{
        flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%',
      }}
      >
        <View style={styles.status}>
          <Text style={styles.statusText}>{(status === 'Todo' || status === 'Retry') ? t('progress.doing') : t('progress.done')}</Text>
        </View>
        <Text style={styles.title}>La Grande Épreuve</Text>
        <Image
          accessibilityIgnoresInvertColors
          style={{ marginBottom: '3%' }}
          source={{ uri: images[`level6${(status === 'Todo' || status === 'Retry' || status === 'Fail') ? 'lock' : ''}`], width: 80, height: 80 }}
        />
        <Text style={styles.paragraph}>{t(`progress.finalQuiz.${status.toLowerCase()}`, { xpLastTry })}</Text>
        {(status === 'Todo' || (status === 'Retry' && currentXp >= (xpLastTry + 2000))) && (
        <Button onPress={() => { setIsModalVisible(true); }} style={styles.button} text={t('progress.start')} />
        )}
        {(status === 'Retry' && currentXp < (xpLastTry + 2000)) && (
        <Button backgroundColor={COLORS.textSecondary} style={styles.button} hoverable={false} text={t('progress.start')} />
        )}
        {(status === 'Success') && (
        <Button onPress={pressSendMail} style={styles.button} text={isMailSent ? 'Mail envoyé !' : t('progress.finalQuiz.sendMail')} />
        )}
      </View>
      )}
    </View>
  );
}

FinalQuiz.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  status: PropTypes.string,
  xpLastTry: PropTypes.number,
  currentXp: PropTypes.number,
  sendMail: PropTypes.func,
  seasonIndex: PropTypes.number,
};

FinalQuiz.defaultProps = {
  style: {},
  status: 'Todo',
  xpLastTry: 0,
  currentXp: 0,
  sendMail: () => {},
  seasonIndex: 5,
};

export default FinalQuiz;
