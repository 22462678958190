import React, { useState } from 'react';
import {
  StyleSheet, View, Text, TextInput,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../utils/colors';

import Button from '../../Common/Button/Button.web';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: '5%',
    paddingVertical: '2%',
    flex: 8,
  },
  title: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '4%',
  },
  label: {
    color: COLORS.textSecondary,
    fontSize: 14,
    marginBottom: '2%',
    alignSelf: 'flex-start',
  },
  input: {
    backgroundColor: COLORS.backgroundSecondary,
    width: '100%',
    borderRadius: 10,
    outlineStyle: 'none',
    paddingHorizontal: '5%',
    minHeight: '12%',
    color: 'white',
    borderColor: COLORS.textSecondary,
    borderWidth: 1,
    marginBottom: '4%',
    fontSize: 14,
  },
  error: {
    fontSize: 12,
    color: COLORS.error,
    marginBottom: '2%',
    textAlign: 'center',
  },
  confirmText: {
    fontSize: 13,
    color: 'white',
    marginBottom: '2%',
    textAlign: 'center',
  },
});

function LoginForm({
  onSubmit,
  onResetPassword,
  style,
}) {
  const { t } = useTranslation();
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [confirmText, setConfirmText] = useState(null);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  async function submitForm() {
    analytics.track('user clicked on login button');
    const res = await onSubmit(mail, password);
    if (res.error && res.error.message === 'Authentication Required') {
      analytics.track('user login failed', { error: res.error.message });
      setError(t('auth.form.error.loginError'));
    } else if (res.error) {
      analytics.track('user login failed', { error: res.error.message });
      setError(t('auth.form.error.serverError'));
    } else {
      analytics.track('user successfully logged in');
    }
  }

  async function submitResetPassword() {
    analytics.track('user asked for password reset');
    if (mail) {
      const res = await onResetPassword(mail);
      if (res.error) {
        analytics.track('user password reset request failed', { error: res.error.message });
        setError(res.error);
      } else {
        analytics.track('user successfully asked for password reset');
        setError(null);
        setConfirmText(t('auth.form.mailSent'));
      }
    } else {
      analytics.track('user password reset request failed', { error: 'No Mail' });
      setError(t('auth.form.error.missingMail'));
    }
  }

  const handleEnterPress = ({ nativeEvent: { key: keyValue } }) => {
    if (keyValue === 'Enter') {
      submitForm();
    }
  };

  if (isForgotPassword) {
    return (
      <View style={[styles.container, { ...style }]}>
        <Text style={styles.title}>{t('auth.form.forgotPassword')}</Text>
        {error && (<Text style={styles.error}>{error}</Text>)}
        {confirmText && (<Text style={styles.confirmText}>{confirmText}</Text>)}
        <Text style={{ color: 'white', textAlign: 'center', marginBottom: '3%' }}>{t('auth.form.forgotPasswordText')}</Text>
        <Text style={styles.label}>{t('auth.form.mail')}</Text>
        <TextInput accessibilityLabel="Text input field" value={mail} onChangeText={(text) => setMail(text)} style={styles.input} accessibilityHint={t('auth.form.mail')} />
        <Button style={{ width: '80%', height: '12%', marginBottom: 20 }} onPress={() => { submitResetPassword(); }} text={t('common.confirm')} />
      </View>
    );
  }
  return (
    <View style={[styles.container, { ...style }]}>
      <Text style={[styles.title, { marginBottom: error ? '2%' : '4%' }]}>{t('auth.alreadyRegistered')}</Text>
      {error && (<Text style={styles.error}>{error}</Text>)}
      <Text style={styles.label}>{t('auth.form.mail')}</Text>
      <TextInput onKeyPress={handleEnterPress} accessibilityLabel="Text input field" value={mail} onChangeText={(text) => setMail(text)} style={styles.input} accessibilityHint={t('auth.form.mail')} />
      <Text style={styles.label}>{t('auth.form.password')}</Text>
      <TextInput onKeyPress={handleEnterPress} accessibilityLabel="Text input field" secureTextEntry value={password} onChangeText={(text) => setPassword(text)} style={styles.input} accessibilityHint={t('auth.form.password')} />
      <Text style={{ color: 'white', marginBottom: '4%', textAlign: 'center' }}>{t('auth.form.ssoHelper')}</Text>
      <Text onPress={() => { analytics.track('user clicked on forget password button'); setIsForgotPassword(true); setError(null); }} style={{ color: COLORS.primary, textDecorationLine: 'underline', marginBottom: '4%' }}>
        {t('auth.form.forgotPassword')}
        {' '}
        ?
      </Text>
      <Button style={{ width: '80%', height: '12%', marginBottom: 20 }} onPress={() => { submitForm(); }} text={t('auth.loginButton')} />
    </View>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  onResetPassword: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};
LoginForm.defaultProps = {
  onSubmit: () => {},
  onResetPassword: () => {},
  style: {},
};

export default LoginForm;
