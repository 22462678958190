import React, { useEffect, useState } from 'react';
import {
  StyleSheet, View, Text, Dimensions, Pressable,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';

import { Refresh } from 'iconoir-react-native';
import { useNavigation } from '@react-navigation/native';
import { useNavigationContextState } from '../../../contexts/NavigationContext';
import { editoType, editoDefault } from '../../../validation/edito';
import COLORS from '../../../utils/colors';
import EditoItem from '../EditoItem/EditoItem';
import ItemList from '../../Common/ItemList/ItemList';
import Card from '../../Common/Card/Card';
import analytics from '../../../utils/analytics';

const windowDimensions = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: '5%',
    paddingTop: '1%',
    paddingBottom: '0.5%',
  },
  editoTitle: {
    fontSize: 18,
    marginBottom: '1.5%',
  },
  title: {
    fontSize: 27,
    fontWeight: 700,
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 13,
    marginBottom: 10,
  },
  card: {
    paddingVertical: '2%',
    paddingHorizontal: '2%',
    paddingBottom: '3%',
  },
  description: {
    color: 'white',
    fontSize: 13,
  },
  article: {
    height: '100%',
  },
  loadMore: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
});

function EditoList({
  backgroundColor,
  style,
  textColor,
  title,
  subtitle,
  editoBackgroundColor,
  editos,
  loadMore,
  onPress,
}) {
  const { t } = useTranslation();
  const [dimensions, setDimensions] = useState(windowDimensions);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);
  const navigation = useNavigation();
  const navigationContext = useNavigationContextState();

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window }) => {
        setDimensions({ window });
      },
    );
    return () => subscription?.remove();
  });

  const getCardWidth = () => ((dimensions.width * 0.90) / 5);

  async function onPressEdito(edito) {
    onPress(edito);
    await navigationContext.onPressEdito(edito, 'Revue');
    navigation.navigate('Revue', { id: edito.id });
  }

  const renderArticle = ({ item, index, separators }) => {
    const res = [<EditoItem
      style={({
        marginRight: getCardWidth() / 45,
        width: getCardWidth(),
        height: '100%',
      })}
      onPress={() => {
        analytics.track('user clicked on edito from editos page', { edito: item.title, type: item.type ? item.type : null });
        onPressEdito(item);
      }}
      backgroundColor={editoBackgroundColor}
      titleColor={textColor}
      edito={item}
    />];
    if (index === editos.length - 1) {
      res.push(
        <Card
          backgroundColor={editoBackgroundColor}
          style={{
            marginRight: 0,
            width: getCardWidth() / 2,
            height: '100%',
          }}
        >
          <Pressable
            onPress={loadMore}
            style={styles.loadMore}
            accessibilityRole="button"
            onHoverIn={() => { setIsRefreshHovered(true); }}
            onHoverOut={() => { setIsRefreshHovered(false); }}
          >
            <Refresh role="none" color={isRefreshHovered ? COLORS.primary : textColor} height={30} width={30} />
            <View style={{ marginTop: '10%' }}>
              <Text style={{ color: textColor, textAlign: 'center' }}>{t('editos.loadMore')}</Text>
            </View>
          </Pressable>
        </Card>,
      );
    }
    return res;
  };

  return (
    <View style={[styles.container, { backgroundColor }, { ...style }]}>
      <Text style={[styles.title, { color: textColor, flex: 1 }]}>{title}</Text>
      {subtitle && (
        <Text style={[styles.subtitle, { color: textColor, flex: 1 }]}>{subtitle}</Text>
      )}
      <ItemList
        renderItem={renderArticle}
        items={editos}
        style={{ flex: 6, width: '100%' }}
        listContainerStyle={{ flex: 6, width: '100%' }}
        captionContainerStyle={{ flex: 1 }}
      />
    </View>
  );
}

EditoList.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  editoBackgroundColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  editos: PropTypes.arrayOf(editoType),
  loadMore: PropTypes.func,
  onPress: PropTypes.func,
};
EditoList.defaultProps = {
  backgroundColor: null,
  textColor: null,
  title: null,
  subtitle: null,
  editoBackgroundColor: null,
  style: {},
  loadMore: () => {},
  onPress: () => {},
  editos: [editoDefault, editoDefault, editoDefault, editoDefault, editoDefault, editoDefault],
};

export default EditoList;
