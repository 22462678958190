import React, { useState } from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import PropTypes from 'prop-types';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useTranslation } from 'react-i18next';
import { TextInput, CheckBox } from 'react-native-web';
import { TrashSolid } from 'iconoir-react-native';
import { useUserContextState } from '../../../contexts/UserContext';
import analytics from '../../../utils/analytics';
import Button from '../../Common/Button/Button';
import { editoType } from '../../../validation/edito';
import COLORS from '../../../utils/colors';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: '8%',
    alignItems: 'flex-start',
    flex: 12,
  },
  title: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '4%',
  },
  parametersTitle: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '4%',
  },
  label: {
    color: COLORS.textSecondary,
    fontSize: 14,
    marginBottom: '2%',
  },
  input: {
    backgroundColor: COLORS.backgroundSecondary,
    width: '100%',
    borderRadius: 10,
    outlineStyle: 'none',
    paddingHorizontal: '5%',
    minHeight: '7%',
    color: 'white',
    borderColor: COLORS.textSecondary,
    borderWidth: 1,
    marginBottom: '6%',
    fontSize: 14,
  },
  separator: {
    height: 2,
    width: '100%',
    backgroundColor: COLORS.backgroundSecondary,
    marginBottom: '6%',
  },
  column: {
    flexDirection: 'column',
    width: '94%',
  },
  row: {
    flexDirection: 'row',
    width: '100%',
  },
  parametersLabel: {
    color: 'white',
    fontSize: 14,
    marginBottom: '2%',
  },
  parametersDescription: {
    color: COLORS.textSecondary,
    fontSize: 14,
    marginBottom: '6%',
  },
  checkbox: {
    marginRight: '5%',
  },
  deleteButton: {
    backgroundColor: 'transparent',
    width: 20,
    height: 20,
    padding: 0,
    marginRight: '5%',
  },
  submitButton: {
    alignSelf: 'center',
    width: '35%',
    height: '5%',
  },
  alertContainer: {
    position: 'absolute',
    zIndex: 5,
    backgroundColor: 'transparent',
  },
  alertOverlay: {
    backgroundColor: 'transparent',
  },
  alertContent: {
    borderColor: COLORS.textSecondary,
    borderWidth: 1,
    backgroundColor: COLORS.background,
    marginRight: '16%',
    minWidth: '110%',
  },
  confirmButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 15,
    width: '25%',
  },
});

function EditoForm({
  style,
  edito,
  onDelete,
  onSubmit,
}) {
  const userContext = useUserContextState();
  const [editoTitle, setEditoTitle] = useState(edito ? edito.title : '');
  const [showAlert, setShowAlert] = useState(false);
  const [isPublic, setIsPublic] = useState(edito ? edito.isPublic : false);
  const [isInapp, setIsInapp] = useState(edito ? edito.type === 'inapp' : false);
  const [isActu, setIsActu] = useState(edito ? edito.type === 'actu' : false);
  const [editoDescription, setEditoDescription] = useState(edito ? edito.description : '');
  const { t } = useTranslation();

  return (
    <View style={[styles.container, { ...style }]}>
      <AwesomeAlert
        show={showAlert}
        alertContainerStyle={styles.alertContainer}
        contentContainerStyle={styles.alertContent}
        titleStyle={{ color: 'white' }}
        messageStyle={{ color: 'white', textAlign: 'center' }}
        overlayStyle={styles.alertOverlay}
        title={t('editos.form.confirmDeletionTitle')}
        message={t('editos.form.confirmDeletion')}
        showConfirmButton
        showCancelButton
        confirmText={t('editos.form.submit')}
        cancelText={t('editos.form.cancel')}
        confirmButtonStyle={styles.confirmButton}
        cancelButtonStyle={[styles.confirmButton, { borderWidth: 1, borderColor: 'white' }]}
        confirmButtonColor={COLORS.primary}
        cancelButtonColor={COLORS.background}
        onConfirmPressed={() => {
          setShowAlert(false);
          onDelete();
        }}
        onCancelPressed={() => {
          setShowAlert(false);
        }}
      />
      <Text style={styles.title}>{edito ? t('editos.form.editTitle') : t('editos.form.createTitle')}</Text>
      <Text style={styles.label}>{t('editos.form.titleLabel')}</Text>
      <TextInput value={editoTitle} onChangeText={(text) => setEditoTitle(text)} style={styles.input} accessibilityLabel="Text input field" accessibilityHint="Nom de la revue" />
      <Text style={styles.label}>{t('editos.form.descriptionLabel')}</Text>
      <TextInput value={editoDescription} onChangeText={(text) => setEditoDescription(text)} multiline style={[styles.input, { minHeight: '20%', paddingVertical: '2%' }]} accessibilityLabel="Text input field" accessibilityHint="Description de la revue" />
      <View style={styles.separator} />
      {(edito || (userContext.user && userContext.user.can_publish_editos)) && (
        <Text style={styles.parametersTitle}>{t('editos.form.parameters')}</Text>
      )}
      {(userContext.user && userContext.user.can_publish_editos) && (
        <View style={styles.row}>
          <CheckBox
            style={styles.checkbox}
            color={isPublic ? COLORS.primary : COLORS.backgroundSecondary}
            value={isPublic}
            onValueChange={(value) => { setIsPublic(value); }}
          />
          <View style={styles.column}>
            <Text style={styles.parametersLabel}>{t('editos.form.shareLabel')}</Text>
            <Text style={edito ? styles.parametersDescription : [styles.parametersDescription, { marginBottom: '15%' }]}>{t('editos.form.shareDescription')}</Text>
          </View>
        </View>
      )}
      {(userContext.user && userContext.user.is_b2m_publisher) && (
        <View style={[styles.row, { marginBottom: 20 }]}>
          <CheckBox
            style={styles.checkbox}
            color={isInapp ? COLORS.primary : COLORS.backgroundSecondary}
            value={isInapp}
            onValueChange={(value) => { setIsInapp(value); if (value && isActu) { setIsActu(false); } }}
          />
          <View style={styles.column}>
            <Text style={styles.parametersLabel}>Inap'perçues</Text>
          </View>
        </View>
      )}
      {(userContext.user && userContext.user.is_b2m_publisher) && (
        <View style={[styles.row, { marginBottom: 20 }]}>
          <CheckBox
            style={styles.checkbox}
            color={isActu ? COLORS.primary : COLORS.backgroundSecondary}
            value={isActu}
            onValueChange={(value) => { setIsActu(value); if (value && isInapp) { setIsInapp(false); } }}
          />
          <View style={styles.column}>
            <Text style={styles.parametersLabel}>1 actu / 5 pdv</Text>
          </View>
        </View>
      )}
      {edito && (
      <View style={styles.row}>
        <Button
          style={styles.deleteButton}
          hoverable={false}
          onPress={() => { analytics.track('user clicked on delete edito button', { edito: editoTitle }); setShowAlert(true); }}
          accessibilityLabel="Supprimer la revue"
          accessibilityHint="Supprimer la revue"
        >
          <TrashSolid role="none" color="white" height={20} width={20} />
        </Button>
        <View style={styles.column}>
          <Text style={styles.parametersLabel}>{t('editos.form.deleteLabel')}</Text>
          <Text style={styles.parametersDescription}>{t('editos.form.deleteDescription')}</Text>
        </View>
      </View>
      )}
      <Button
        style={styles.submitButton}
        onPress={() => {
          let type = null;
          if (isActu) {
            type = 'actu';
          }
          if (isInapp) {
            type = 'inapp';
          }
          onSubmit(editoTitle, editoDescription, isPublic, type);
        }}
        text={t('editos.form.submit')}
      />
    </View>
  );
}

EditoForm.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  edito: PropTypes.oneOf([editoType, null]),
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

EditoForm.defaultProps = {
  style: {},
  edito: null,
  onSubmit: () => {},
  onDelete: () => {},
};

export default EditoForm;
