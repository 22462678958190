import React from 'react';
import {
  StyleSheet, View, Text, Image, Pressable,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Search, Bookmark, ProfileCircle } from 'iconoir-react-native';
import { useNavigation } from '@react-navigation/native';
import { useUserContextState } from '../../../contexts/UserContext';
import { useAuthContextState } from '../../../contexts/AuthContext';
import analytics from '../../../utils/analytics';

import { images } from '../../../utils/constants';
import COLORS from '../../../utils/colors';
import Button from '../Button/Button.web';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: '5%',
    paddingVertical: '1%',
    alignItems: 'center',
    height: 63,
    maxHeight: 63,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 250,
  },
  button: {
    width: 120,
  },
  separator: {
    height: 18,
    width: 2,
    backgroundColor: 'white',
    marginHorizontal: 10,
  },
  xpText: {
    marginHorizontal: 5,
    color: 'white',
  },
  accountButton: {
    padding: 0,
  },
});

function AppHeader({
  backgroundColor,
  style,
  currentPage,
}) {
  const { t } = useTranslation();
  const userContext = useUserContextState();
  const navigation = useNavigation();

  function navigateTo(page) {
    analytics.track('user clicked on bottom bar navigation', { route: page });
    if (page !== currentPage) {
      navigation.navigate(page, {});
    }
  }

  return (
    <View style={[styles.container, { backgroundColor }, { ...style }]}>
      <Pressable accessibilityRole="button" style={{ width: 160 }} onPress={() => { navigateTo('Accueil'); }}>
        <Image
          accessibilityIgnoresInvertColors
          source={{ uri: images.logoWhite, width: 160, height: 45 }}
        />
      </Pressable>
      <View style={[styles.flexRow, { marginLeft: 70 }]}>
        <Button
          style={styles.button}
          hoveredColor={COLORS.secondary}
          backgroundColor={currentPage === 'home' ? 'white' : COLORS.ternary}
          color={currentPage === 'home' ? COLORS.primary : 'white'}
          onPress={() => { navigateTo('Accueil'); }}
          text={t('home.title')}
          textStyle={{ fontWeight: '600' }}
          accessibilityHint={t('accessibility.hints.navigation.home')}
        />
        <Button
          style={styles.button}
          hoveredColor={COLORS.secondary}
          backgroundColor={currentPage === 'progress' ? 'white' : COLORS.ternary}
          color={currentPage === 'progress' ? COLORS.primary : 'white'}
          onPress={() => { navigateTo('Progresser'); }}
          textStyle={{ fontWeight: '600' }}
          text={t('progress.title')}
          accessibilityHint={t('accessibility.hints.navigation.progress')}
        />
      </View>
      <View style={[styles.flexRow, { justifyContent: 'flex-end' }]}>
        <Button hoverable={false} onPress={() => { analytics.track('user clicked on search button'); navigateTo('Recherche'); }} accessibilityLabel={t('accessibility.labels.header.search')} accessibilityHint={t('accessibility.hints.header.search')}>
          <Search role="none" color="white" height={20} width={20} />
        </Button>
        <Button hoverable={false} onPress={() => { analytics.track('user clicked on editos button'); navigateTo('Revues'); }} accessibilityLabel={t('accessibility.labels.header.editos')} accessibilityHint={t('accessibility.hints.header.editos')}>
          <Bookmark role="none" color="white" fill={currentPage === 'editos' || currentPage === 'editoDetail' ? 'white' : 'transparent'} height={20} width={20} />
        </Button>
        <View style={styles.separator} />
        <Text key={userContext.user.experience} style={styles.xpText}>
          {userContext.user.experience}
          xp
        </Text>
        <Button style={styles.accountButton} hoverable={false} onPress={() => { analytics.track('user clicked on account page button'); navigateTo('Account'); }} accessibilityLabel={t('accessibility.labels.header.account')} accessibilityHint={t('accessibility.hints.header.account')}>
          <ProfileCircle role="none" color="white" height={20} width={20} />
        </Button>
      </View>
    </View>
  );
}

AppHeader.propTypes = {
  backgroundColor: PropTypes.string,
  currentPage: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};
AppHeader.defaultProps = {
  backgroundColor: COLORS.primary,
  style: {},
  currentPage: 'home',
};

export default AppHeader;
