import React, {
  createContext, useContext, useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import AsyncStorage from '@react-native-async-storage/async-storage';
import StorageKeys from '../utils/StorageKeys';
import api from '../utils/api';
import userApi from '../api/userApi';
import analytics from '../utils/analytics';

const AuthContext = createContext();

const useAuthContextState = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContextState was used outside of its Provider');
  }

  return context;
};

function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const logout = useCallback(async () => {
    await AsyncStorage.removeItem(StorageKeys.userToken);
    await AsyncStorage.removeItem('ssoAgree');
    api.hero.setAccessToken(null);
    setUser(null);
  }, []);

  const login = useCallback(async (mail, password) => {
    const res = await userApi.loginUser(mail, password);
    if (!res.error) {
      setUser('LoggedIn');
    }
    return res;
  }, []);

  const register = useCallback(async (mail, password) => {
    const res = await userApi.registerUser(mail, password);
    if (!res.error) {
      setUser('LoggedIn');
    }
    return res;
  }, []);

  const askResetPassword = useCallback(async (mail) => {
    const res = await userApi.askResetPassword(mail);
    return res;
  }, []);

  const authSsoUser = useCallback(async (token) => {
    if (token) {
      analytics.track('user successfully logged in with SSO redirect');
      await AsyncStorage.setItem(StorageKeys.userToken, token);
      api.hero.setAccessToken(token);
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    async function fetchUser() {
      const userBearer = await AsyncStorage.getItem(StorageKeys.userToken);
      if (userBearer) {
        api.hero.setAccessToken(userBearer);
        const res = await userApi.getUserInfos();
        if (!res.error) {
          setUser('LoggedIn');
        }
      }
      setTimeout(() => {
        setIsLoading(false);
      }, '500');
    }
    fetchUser();
  }, []);

  const contextValue = useMemo(() => ({
    user,
    isLoading,
    logout,
    login,
    authSsoUser,
    register,
    askResetPassword,
    setUser,
  }), [user, isLoading, logout, login, register, authSsoUser, setUser]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.element,
};
AuthContextProvider.defaultProps = {
  children: null,
};

export { AuthContextProvider, useAuthContextState };
