import React, { useEffect, useRef, useState } from 'react';
import {
  StyleSheet, View, ActivityIndicator, ScrollView,
} from 'react-native';
import { TextInput } from 'react-native-web';

import { Search as SearchIcon } from 'iconoir-react-native';
import Layout from '../../components/Common/Layout/Layout.web';
import { useContentContextState } from '../../contexts/ContentContext';
import ArticleCard from '../../components/Article/ArticleCard/ArticleCard.web';
import COLORS from '../../utils/colors';
import analytics from '../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    backgroundColor: COLORS.background,
  },
  input: {
    backgroundColor: COLORS.backgroundSecondary,
    color: COLORS.primary,
    height: '100%',
    width: '100%',
    fontSize: 25,
    outlineStyle: 'none',
    paddingLeft: '2%',
  },
  inputContainer: {
    paddingHorizontal: '5%',
    backgroundColor: COLORS.backgroundSecondary,
    height: 63,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  scrollview: {
    height: '100%',
    backgroundColor: COLORS.background,
  },
  content: {
    backgroundColor: COLORS.background,
    height: '100%',
    paddingBottom: 50,
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    paddingHorizontal: '5%',
    paddingTop: '1%',
  },
  article: {
    width: '24%',
    height: '40%',
    marginVertical: '0.5%',
    marginHorizontal: '0.5%',
  },
});

function Search() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const contentContext = useContentContextState();

  async function searchArticles(text) {
    setIsLoading(true);
    await contentContext.searchArticles(text);
    setIsLoading(false);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchText !== '') {
        analytics.track('user searched for articles', { text: searchText });
        searchArticles(searchText);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText]);

  return (
    <Layout currentPage="search">
      <View style={{ width: '100%', height: '100%', paddingBottom: '5%' }}>
        <View style={styles.inputContainer}>
          <SearchIcon role="none" color="white" height={25} width={25} />
          <TextInput value={searchText} onChangeText={(text) => setSearchText(text)} style={styles.input} accessibilityLabel="Text input field" accessibilityHint="Rechercher un article" placeholder="Rechercher un article..." placeholderTextColor={COLORS.primary} />
        </View>
        {!isLoading && (
          <ScrollView
            style={{ marginBottom: 20 }}
            contentContainerStyle={styles.scrollview}
            showsVerticalScrollIndicator={false}
          >
            <View style={styles.content}>
              {contentContext.searchedArticles.map((prop, key) => (
                <ArticleCard
                  key={`${prop.url}-${key}`}
                  bookmarkable
                  article={prop}
                  style={styles.article}
                  prevPage="Recherche"
                />
              ))}
            </View>
          </ScrollView>
        )}
        {isLoading && (
          <View style={{
            width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <ActivityIndicator size="large" color={COLORS.primary} />
          </View>
        )}
      </View>
    </Layout>
  );
}

export default Search;
