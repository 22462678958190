import React, { useEffect, useState, useRef } from 'react';
import {
  StyleSheet, View, Text, FlatList, Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';
import { NavArrowLeft, NavArrowRight } from 'iconoir-react-native';

import { useUserContextState } from '../../../contexts/UserContext';
import { editoType, editoDefault } from '../../../validation/edito';
import COLORS from '../../../utils/colors';
import Button from '../../Common/Button/Button';
import ArticleCard from '../../Article/ArticleCard/ArticleCard';
import Card from '../../Common/Card/Card';
import analytics from '../../../utils/analytics';

const windowDimensions = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '1%',
    marginTop: '1%',
  },
  editoTitle: {
    fontSize: 18,
    marginBottom: '1.5%',
  },
  title: {
    fontSize: 27,
    fontWeight: 700,
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 13,
    marginBottom: '2%',
  },
  horizontalContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  card: {
    paddingVertical: '2%',
    paddingHorizontal: '2%',
    paddingBottom: '3%',
  },
  description: {
    color: 'white',
    fontSize: 13,
  },
  articleList: {
    width: '75%',
  },
  article: {
    height: '100%',
  },
});

function EditoCard({
  backgroundColor,
  style,
  textColor,
  metadataColor,
  title,
  subtitle,
  articleBackgroundColor,
  edito,
}) {
  const editoColors = {
    inapp: {
      background: COLORS.inappEdito,
      text: 'white',
      article: COLORS.inappArticle,
      metadata: COLORS.inappMetadata,
    },
    actu: {
      background: COLORS.actuEdito,
      text: 'black',
      article: COLORS.actuArticle,
      metadata: COLORS.actuMetadata,
    },
    orga: {
      background: COLORS.background,
      text: COLORS.background,
      article: COLORS.background,
      metadata: COLORS.background,
    },
  };
  const { t } = useTranslation();
  const [dimensions, setDimensions] = useState(windowDimensions);
  const [isArrowLeftHovered, setIsArrowLeftHovered] = useState(false);
  const [isArrowRightHovered, setIsArrowRightHovered] = useState(false);
  const [currentScrollIndex, setCurrentScrollIndex] = useState(0);
  const userContext = useUserContextState();

  let finalBackground = backgroundColor;
  let finalTextColor = textColor;
  let finalArticleBackgroundColor = articleBackgroundColor;
  let finalMetadataColor = metadataColor;
  const isBigPicture = false;
  const finalDescription = edito.description ? edito.description.replaceAll('\n', '\n\n') : null;
  const listRef = useRef();

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window }) => {
        setDimensions({ window });
      },
    );
    return () => subscription?.remove();
  });

  const getCardWidth = () => ((dimensions.width * 0.85) / 4);

  if (!finalBackground) {
    finalBackground = edito.type ? editoColors[edito.type].background : editoColors.orga.background;
  }
  if (!finalTextColor) {
    finalTextColor = edito.type ? editoColors[edito.type].text : editoColors.orga.text;
  }
  if (!finalArticleBackgroundColor) {
    finalArticleBackgroundColor = edito.type
      ? editoColors[edito.type].article
      : editoColors.orga.article;
  }
  if (!metadataColor) {
    finalMetadataColor = edito.type ? editoColors[edito.type].metadata : editoColors.orga.metadata;
  }

  function onPressArticle(item) {
    if (edito.type === 'inapp') {
      userContext.executeAction('inapp_edito_read');
    } else if (edito.type === 'actu') {
      userContext.executeAction('actu_edito_read');
    }
    if (edito.type) {
      analytics.track('user clicked on feed item', { type: `article-from-${edito.type}`, isBig: true });
      analytics.track('user clicked on article', {
        from: edito.type, isBig: true, article: item.title, source: item.sourceName,
      });
    } else {
      analytics.track('user clicked on feed item', { type: 'article-from-orga', isBig: true });
      analytics.track('user clicked on article', {
        from: 'orga', isBig: true, article: item.title, source: item.sourceName,
      });
    }
  }

  const renderArticle = ({ item, index, separators }) => (
    <ArticleCard
      bigPicture={isBigPicture}
      onPress={() => { onPressArticle(item); }}
      style={({
        ...styles.article,
        marginRight: index === edito.articles.length - 1 ? 0 : getCardWidth() / 45,
        width: getCardWidth(),
      })}
      bookmarkable={false}
      article={item}
      backgroundColor={finalArticleBackgroundColor}
      metadataColor={finalMetadataColor}
      titleColor={finalTextColor}
    />
  );

  const scrollNext = () => {
    if ((currentScrollIndex + 2) <= (edito.articles.length - 1)) {
      listRef.current.scrollToIndex({ animated: true, index: currentScrollIndex + 2 });
      setCurrentScrollIndex(currentScrollIndex + 2);
    } else {
      listRef.current.scrollToIndex({ animated: true, index: edito.articles.length - 1 });
      setCurrentScrollIndex(edito.articles.length - 1);
    }
  };

  const scrollPrev = () => {
    if ((currentScrollIndex - 2) >= 0) {
      listRef.current.scrollToIndex({ animated: true, index: currentScrollIndex - 2 });
      setCurrentScrollIndex(currentScrollIndex - 2);
    } else {
      listRef.current.scrollToIndex({ animated: true, index: 0 });
      setCurrentScrollIndex(0);
    }
  };

  return (
    <View style={[styles.container, { backgroundColor: finalBackground }, { ...style }]}>
      <Text style={[styles.title, { color: finalTextColor }]}>{title || (edito.type ? t(`editos.${edito.type}.title`) : '')}</Text>
      <Text style={[styles.subtitle, { color: finalTextColor }]}>{subtitle || (edito.type ? t(`editos.${edito.type}.subtitle`) : '')}</Text>
      {(edito && edito.title) && (
        <Text style={[styles.editoTitle, { color: finalTextColor }]}>{edito.title}</Text>
      )}
      <View style={[styles.horizontalContainer, { marginBottom: '1%' }]}>
        {(finalDescription && finalDescription.length > 0) && (
          <Card style={({
            ...styles.card,
            width: getCardWidth(),
            marginRight: getCardWidth() / 40,
          })}
          >
            <Text style={styles.description}>{finalDescription}</Text>
          </Card>
        )}
        <View style={styles.articleList}>
          <FlatList
            showsHorizontalScrollIndicator={false}
            horizontal
            ref={listRef}
            data={edito.articles}
            renderItem={renderArticle}
          />
        </View>
      </View>
      <View style={[styles.horizontalContainer, { alignItems: 'center' }]}>
        <Button
          onHoverIn={() => { setIsArrowLeftHovered(true); }}
          onHoverOut={() => { setIsArrowLeftHovered(false); }}
          backgroundColor="transparent"
          onPress={scrollPrev}
          hoverable={false}
          accessibilityLabel={t('accessibility.labels.navigation.previous')}
          accessibilityHint={t('accessibility.hints.navigation.previous')}
        >
          <NavArrowLeft role="none" color={isArrowLeftHovered ? COLORS.primary : 'black'} height={22} width={22} />
        </Button>
        <Button
          onHoverIn={() => { setIsArrowRightHovered(true); }}
          onHoverOut={() => { setIsArrowRightHovered(false); }}
          backgroundColor="transparent"
          hoverable={false}
          onPress={scrollNext}
          accessibilityLabel={t('accessibility.labels.navigation.previous')}
          accessibilityHint={t('accessibility.hints.navigation.previous')}
        >
          <NavArrowRight role="none" color={isArrowRightHovered ? COLORS.primary : 'black'} height={22} width={22} />
        </Button>
      </View>
    </View>
  );
}

EditoCard.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  metadataColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  articleBackgroundColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  edito: editoType,
};
EditoCard.defaultProps = {
  backgroundColor: null,
  textColor: null,
  metadataColor: null,
  title: null,
  subtitle: null,
  articleBackgroundColor: null,
  style: {},
  edito: editoDefault,
};

export default EditoCard;
