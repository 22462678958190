import React, { useState, useRef, useEffect } from 'react';
import {
  StyleSheet, View, Text, ScrollView, FlatList,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Plus, NavArrowLeft, NavArrowRight } from 'iconoir-react-native';
import { useAuthContextState } from '../../../contexts/AuthContext';

import COLORS from '../../../utils/colors';
import Button from '../Button/Button';
import CategoriesModal from '../../Home/CategoriesModal/CategoriesModal';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: '5%',
    paddingVertical: '1%',
    alignItems: 'center',
  },
  button: {
    marginRight: 10,
  },
  roundButton: {
    borderRadius: 100,
    width: 25,
    height: 25,
    padding: 0,
    marginRight: 10,
  },
  navButton: {
    borderWidth: 1,
    borderColor: COLORS.textSecondary,
  },
});

function HorizontalMenu({
  backgroundColor,
  style,
  isScrollable,
  isCustomizable,
  onPressItem,
  items,
  selectedIndex,
}) {
  const { t } = useTranslation();
  const authContext = useAuthContextState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentScrollIndex, setCurrentScrollIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const listRef = useRef();

  useEffect(() => {
    setCurrentIndex(selectedIndex);
  }, []);

  const onPress = (index, item) => {
    if (typeof item === 'object') {
      if (!item.isDisable) {
        setCurrentIndex(index);
      }
    } else {
      setCurrentIndex(index);
    }
    onPressItem(index, item);
  };

  const scrollNext = () => {
    if ((currentScrollIndex + 10) <= (items.length - 1)) {
      listRef.current.scrollToIndex({ animated: true, index: currentScrollIndex + 10 });
      setCurrentScrollIndex(currentScrollIndex + 10);
    } else {
      listRef.current.scrollToIndex({ animated: true, index: items.length - 1 });
      setCurrentScrollIndex(items.length - 1);
    }
  };

  const scrollPrev = () => {
    if ((currentScrollIndex - 15) >= 0) {
      listRef.current.scrollToIndex({ animated: true, index: currentScrollIndex - 15 });
      setCurrentScrollIndex(currentScrollIndex - 15);
    } else {
      listRef.current.scrollToIndex({ animated: true, index: 0 });
      setCurrentScrollIndex(0);
    }
  };

  const renderButton = ({ item, index, separators }) => {
    if (typeof item === 'object') {
      return (
        <Button
          text={item.text}
          hoveredColor={currentIndex === index ? COLORS.primary : COLORS.background}
          key={index}
          color={item.isDisable ? COLORS.textSecondary : 'white'}
          style={styles.button}
          backgroundColor={currentIndex === index ? COLORS.primary : COLORS.backgroundSecondary}
          onPress={() => { onPress(index, item); }}
        />
      );
    }
    return (
      <Button
        text={item}
        hoveredColor={currentIndex === index ? COLORS.primary : COLORS.background}
        key={index}
        style={styles.button}
        backgroundColor={currentIndex === index ? COLORS.primary : COLORS.backgroundSecondary}
        onPress={() => { onPress(index, item); }}
      />
    );
  };

  if (isScrollable) {
    return (
      <View style={[styles.container, { backgroundColor }, { ...style }]}>
        {isModalVisible && (
          <CategoriesModal
            modalVisible={isModalVisible}
            onClose={() => { setIsModalVisible(false); }}
          />
        )}
        {isCustomizable && (
        <Button
          backgroundColor={COLORS.primary}
          style={styles.roundButton}
          accessibilityLabel={t('accessibility.labels.home.customizeCategories')}
          accessibilityHint={t('accessibility.hints.home.customizeCategories')}
          onPress={() => { analytics.track('user clicked on edit interests button'); setIsModalVisible(true); }}
        >
          <Plus role="none" color="white" height={18} width={18} />
        </Button>
        )}
        {(isScrollable && items.length > 10) && (
          <Button
            backgroundColor={COLORS.backgroundSecondary}
            hoveredColor={COLORS.background}
            accessibilityLabel={t('accessibility.labels.navigation.previous')}
            accessibilityHint={t('accessibility.hints.navigation.previous')}
            style={({ ...styles.roundButton, ...styles.navButton })}
            onPress={scrollPrev}
          >
            <NavArrowLeft role="none" color="white" height={18} width={18} />
          </Button>
        )}
        <FlatList
          showsHorizontalScrollIndicator={false}
          horizontal
          ref={listRef}
          data={items}
          renderItem={renderButton}
        />
        {(isScrollable && items.length > 10) && (
          <Button
            backgroundColor={COLORS.backgroundSecondary}
            hoveredColor={COLORS.background}
            accessibilityLabel={t('accessibility.labels.navigation.previous')}
            accessibilityHint={t('accessibility.hints.navigation.previous')}
            style={({ ...styles.roundButton, ...styles.navButton, marginRight: 0 })}
            onPress={scrollNext}
          >
            <NavArrowRight role="none" color="white" height={18} width={18} />
          </Button>
        )}
      </View>
    );
  }
  return (
    <View style={[styles.container, { backgroundColor }, { ...style }]} />
  );
}

HorizontalMenu.propTypes = {
  backgroundColor: PropTypes.string,
  isScrollable: PropTypes.bool,
  isCustomizable: PropTypes.bool,
  selectedIndex: PropTypes.number,
  onPressItem: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};
HorizontalMenu.defaultProps = {
  backgroundColor: COLORS.backgroundSecondary,
  items: [],
  isCustomizable: true,
  onPressItem: () => {},
  isScrollable: true,
  style: {},
  selectedIndex: 0,
};

export default HorizontalMenu;
