import React, { useState } from 'react';
import {
  StyleSheet, View, Image, Text, Pressable,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';
import moment from 'moment';
import { Bookmark } from 'iconoir-react-native';
import { useNavigation } from '@react-navigation/native';

import { articleType, articleDefault } from '../../../validation/article';
import Card from '../../Common/Card/Card.web';
import COLORS from '../../../utils/colors';
import Button from '../../Common/Button/Button.web';
import { getCategoryImage } from '../../../utils/constants';
import { editoDefault, editoType } from '../../../validation/edito';

const styles = StyleSheet.create({
  card: {
    borderRadius: 7,
  },
  articleImage: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  footer: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    color: 'white',
    fontSize: 15,
    maxHeight: '15%',
    marginBottom: '3%',
  },
  metadata: {
    color: COLORS.textSecondary,
    fontSize: 13,
  },
});

function EditoItem({
  backgroundColor,
  style,
  titleColor,
  edito,
  onPress,
}) {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <Card backgroundColor={backgroundColor} style={{ ...style }}>
      <Pressable
        onPress={onPress ? () => { onPress(); } : () => {}}
        accessibilityRole="button"
        style={{ width: '100%', height: '100%' }}
      >
        {edito.image && (
          <Image
            accessibilityIgnoresInvertColors
            source={{ uri: edito.image, width: '100%', height: '50%' }}
            style={styles.articleImage}
          />
        )}
        <View style={({ height: '50%', ...styles.footer })}>
          <Text style={[styles.title, { color: titleColor }]}>{edito.title.length > 100 ? edito.title.slice(0, 95).concat('[...]') : edito.title}</Text>
        </View>
      </Pressable>
    </Card>
  );
}

EditoItem.propTypes = {
  backgroundColor: PropTypes.string,
  titleColor: PropTypes.string,
  prevPage: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  edito: editoType,
  onPress: PropTypes.func,
};
EditoItem.defaultProps = {
  backgroundColor: COLORS.backgroundSecondary,
  style: {},
  titleColor: 'white',
  prevPage: 'Accueil',
  edito: editoDefault,
  onPress: null,
};

export default EditoItem;
