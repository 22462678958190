import React, { useState, useRef } from 'react';
import {
  StyleSheet, View, FlatList,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';

import { NavArrowLeft, NavArrowRight } from 'iconoir-react-native';
import { editoDefault, editoType } from '../../../validation/edito';
import { articleType } from '../../../validation/article';
import COLORS from '../../../utils/colors';
import Button from '../Button/Button';

const styles = StyleSheet.create({
  horizontalContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  list: {
    width: '100%',
  },
});

function ItemList({
  style,
  captionContainerStyle,
  listContainerStyle,
  hasCaption,
  captionColor,
  renderItem,
  items,
}) {
  const { t } = useTranslation();
  const [isArrowLeftHovered, setIsArrowLeftHovered] = useState(false);
  const [isArrowRightHovered, setIsArrowRightHovered] = useState(false);
  const [currentScrollIndex, setCurrentScrollIndex] = useState(0);
  const listRef = useRef();

  const scrollNext = () => {
    if ((currentScrollIndex + 2) <= (items.length - 1)) {
      listRef.current.scrollToIndex({ animated: true, index: currentScrollIndex + 2 });
      setCurrentScrollIndex(currentScrollIndex + 2);
    } else {
      listRef.current.scrollToIndex({ animated: true, index: items.length - 1 });
      setCurrentScrollIndex(items.length - 1);
    }
  };

  const scrollPrev = () => {
    if ((currentScrollIndex - 2) >= 0) {
      listRef.current.scrollToIndex({ animated: true, index: currentScrollIndex - 2 });
      setCurrentScrollIndex(currentScrollIndex - 2);
    } else {
      listRef.current.scrollToIndex({ animated: true, index: 0 });
      setCurrentScrollIndex(0);
    }
  };

  return (
    <View style={{ ...style }}>
      <View style={[styles.horizontalContainer, { ...listContainerStyle }]}>
        <View style={styles.list}>
          <FlatList
            showsHorizontalScrollIndicator={false}
            horizontal
            ref={listRef}
            data={items}
            renderItem={renderItem}
          />
        </View>
      </View>
      {hasCaption && (
      <View style={[styles.horizontalContainer, { ...captionContainerStyle }]}>
        <Button
          onHoverIn={() => { setIsArrowLeftHovered(true); }}
          onHoverOut={() => { setIsArrowLeftHovered(false); }}
          backgroundColor="transparent"
          onPress={scrollPrev}
          hoverable={false}
          accessibilityLabel={t('accessibility.labels.navigation.previous')}
          accessibilityHint={t('accessibility.hints.navigation.previous')}
        >
          <NavArrowLeft role="none" color={isArrowLeftHovered ? COLORS.primary : captionColor} height={22} width={22} />
        </Button>
        <Button
          onHoverIn={() => { setIsArrowRightHovered(true); }}
          onHoverOut={() => { setIsArrowRightHovered(false); }}
          backgroundColor="transparent"
          hoverable={false}
          onPress={scrollNext}
          accessibilityLabel={t('accessibility.labels.navigation.previous')}
          accessibilityHint={t('accessibility.hints.navigation.previous')}
        >
          <NavArrowRight role="none" color={isArrowRightHovered ? COLORS.primary : captionColor} height={22} width={22} />
        </Button>
      </View>
      )}
    </View>
  );
}

ItemList.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  captionContainerStyle:
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  listContainerStyle:
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  renderItem: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.oneOfType([editoType, articleType])),
  hasCaption: PropTypes.bool,
  captionColor: PropTypes.string,
};
ItemList.defaultProps = {
  style: {},
  captionContainerStyle: {},
  listContainerStyle: {},
  renderItem: () => {},
  hasCaption: true,
  items: [editoDefault, editoDefault, editoDefault],
  captionColor: 'black',
};

export default ItemList;
