import React, { useState, useEffect } from 'react';
import {
  StyleSheet, View, Image, Text,
} from 'react-native';
import { useTranslation } from 'react-i18next';

import { useAuthContextState } from '../../contexts/AuthContext';
import { useUserContextState } from '../../contexts/UserContext';
import COLORS from '../../utils/colors';
import { images } from '../../utils/constants';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: COLORS.primary,
    paddingTop: '5%',
  },
  text: {
    color: 'white',
    fontSize: 20,
  },
});

function SsoDisconnected({ route }) {
  const authContext = useAuthContextState();
  const userContext = useUserContextState();
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Image
        accessibilityIgnoresInvertColors
        source={{ uri: images.logoWhite, width: 305, height: 85 }}
        style={{ marginBottom: '10%' }}
      />
      {/* Loader */}
      <Text style={styles.text}>Disconnected</Text>
    </View>
  );
}

export default SsoDisconnected;
