import React, { useEffect, useRef, useState } from 'react';
import {
  StyleSheet, View, Text, ScrollView, FlatList, Pressable, Image, Dimensions,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import { Plus } from 'iconoir-react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from '@react-navigation/native';

import { useContentContextState } from '../../contexts/ContentContext';
import { useUserContextState } from '../../contexts/UserContext';
import { useNavigationContextState } from '../../contexts/NavigationContext';
import Layout from '../../components/Common/Layout/Layout.web';
import COLORS from '../../utils/colors';
import EditoList from '../../components/Edito/EditoList/EditoList';
import Button from '../../components/Common/Button/Button';
import ItemList from '../../components/Common/ItemList/ItemList.web';
import EditoModal from '../../components/Edito/EditoModal/EditoModal.web';
import analytics from '../../utils/analytics';

const windowDimensions = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    backgroundColor: COLORS.background,
  },
  section: {
    height: '40vh',
    width: '100%',
    alignItems: 'center',
  },
  title: {
    fontSize: 27,
    fontWeight: 700,
    marginBottom: '1%',
    color: 'white',
  },
  addReview: {
    height: '10%',
    marginBottom: '2%',
    width: '22%',
  },
  myEditos: {
    width: '100%',
  },
  editoImage: {
    borderRadius: 7,
    marginBottom: '3%',
  },
  edito: {
    flexDirection: 'column',
    width: '25%',
  },
  editoText: {
    color: 'white',
  },
  placeholder: {
    width: '100%',
    height: '80%',
    borderRadius: 7,
    marginBottom: '3%',
  },
});

function Editos() {
  const contentContext = useContentContextState();
  const userContext = useUserContextState();
  const navigationContext = useNavigationContextState();
  const [dimensions, setDimensions] = useState(windowDimensions);
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window }) => {
        setDimensions({ window });
      },
    );
    return () => subscription?.remove();
  });

  const getCardWidth = () => ((dimensions.width * 0.75) / 6);
  const navigation = useNavigation();

  async function onPressEdito(edito) {
    await navigationContext.onPressEdito(edito, 'Revue');
    navigation.navigate('Revue', { id: edito.id });
  }

  useEffect(() => {
    contentContext.getMyEditos();
    contentContext.getEditos();
  }, []);

  const renderPlaceholders = ({ item, index, separators }) => {
    let i = 0;
    const res = [];
    do {
      res.push(
        <View style={({
          marginRight: getCardWidth() / 10,
          width: getCardWidth(),
          height: '100%',
          alignItems: 'center',
        })}
        >
          <LinearGradient
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 0.5 }}
            colors={['#1a1a1a', '#232323']}
            style={styles.placeholder}
          />
        </View>,
      );
      i += 1;
    }
    while (i < 7);
    return res;
  };

  const renderMyEditos = ({ item, index, separators }) => {
    const res = [
      <Pressable
        accessibilityRole="button"
        onPress={() => {
          analytics.track('user clicked on edito from editos page', { edito: item.title, type: item.type ? item.type : null });
          onPressEdito(item);
        }}
        style={({
          marginRight: getCardWidth() / 10,
          width: getCardWidth(),
          height: '100%',
          alignItems: 'center',
        })}
      >
        <Image
          accessibilityIgnoresInvertColors
          source={{ uri: item.image ? item.image : 'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Les+grands+titres-min.jpg', width: '100%', height: '80%' }}
          style={styles.editoImage}
        />
        <Text style={[styles.editoText, { fontSize: 14, fontWeight: 600, marginBottom: 2 }]}>
          {item.title}
        </Text>
        <Text style={[styles.editoText, { fontSize: 12 }]}>
          {item.articles.length}
          {' '}
          article(s)
        </Text>
      </Pressable>];
    if (index === contentContext.myEditos.length - 1) {
      let i = contentContext.myEditos.length;
      do {
        res.push(
          <View style={({
            marginRight: getCardWidth() / 10,
            width: getCardWidth(),
            height: '100%',
            alignItems: 'center',
          })}
          >
            <LinearGradient
              start={{ x: 0, y: 0.5 }}
              end={{ x: 1, y: 0.5 }}
              colors={['#1a1a1a', '#232323']}
              style={styles.placeholder}
            />
          </View>,
        );
        i += 1;
      }
      while (i < 7);
    }
    return res;
  };

  return (
    <Layout style={styles.container} currentPage="editos">
      {modalVisible && (
        <EditoModal
          modalVisible={modalVisible}
          edito={null}
          onClose={() => { setModalVisible(false); }}
        />
      )}
      <ScrollView style={{ width: '100%', height: '100%', paddingBottom: '5%' }} contentContainerStyle={{ alignItems: 'center', paddingTop: '2%' }}>
        <View style={[styles.section, { paddingHorizontal: '5%' }]}>
          <Text style={styles.title}>{t('editos.myEditos')}</Text>
          <Button style={styles.addReview} onPress={() => { analytics.track('user clicked on created edito button'); setModalVisible(true); }}>
            <View style={{ flexDirection: 'row' }}>
              <Plus role="none" color="white" height={20} width={20} />
              <Text style={{ color: 'white', marginTop: 1 }}>{t('editos.create')}</Text>
            </View>
          </Button>
          {(contentContext.myEditos.length > 0) && (
            <ItemList
              renderItem={renderMyEditos}
              items={contentContext.myEditos}
              style={{ width: '100%', flex: 6 }}
              listContainerStyle={{ width: '100%', flex: 6 }}
              captionContainerStyle={{ flex: 1 }}
              captionColor="white"
            />
          )}
          {(contentContext.myEditos.length === 0) && (
            <ItemList
              renderItem={renderPlaceholders}
              items={['placeholders']}
              style={{ width: '100%', flex: 6 }}
              listContainerStyle={{ width: '100%', flex: 6 }}
              captionContainerStyle={{ flex: 1 }}
              captionColor="white"
            />
          )}
        </View>
        {contentContext.orgaEditosList.length > 0 && <EditoList loadMore={contentContext.getEditos} backgroundColor={COLORS.backgroundSecondary} textColor="white" title={t('editos.orgaEditos')} editoBackgroundColor={COLORS.background} editos={contentContext.orgaEditosList} style={styles.section} />}
        <EditoList onPress={() => { userContext.executeAction('actu_edito_read'); }} loadMore={contentContext.getEditos} backgroundColor={COLORS.actuEdito} textColor="black" title={t('editos.actu.title')} subtitle={t('editos.actu.subtitle')} editoBackgroundColor={COLORS.actuArticle} editos={contentContext.actuEditosList} style={styles.section} />
        <EditoList onPress={() => { userContext.executeAction('inapp_edito_read'); }} loadMore={contentContext.getEditos} backgroundColor={COLORS.inappEdito} textColor="white" title={t('editos.inapp.title')} subtitle={t('editos.inapp.subtitle')} editoBackgroundColor={COLORS.inappArticle} editos={contentContext.inappEditosList} style={styles.section} />
      </ScrollView>
    </Layout>
  );
}

export default Editos;
