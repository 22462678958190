import React from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import PropTypes from 'prop-types';
import { ArrowLeft, Xmark, Expand } from 'iconoir-react-native';
import { useTranslation } from 'react-i18next';

import COLORS from '../../../utils/colors';
import Button from '../Button/Button.web';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    color: 'white',
    borderRadius: 10,
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeButton: {
    backgroundColor: 'transparent',
    width: 45,
    height: 45,
    marginTop: 10,
    marginRight: 10,
    padding: 0,
  },
  backButton: {
    backgroundColor: 'transparent',
    width: 45,
    height: 45,
    alignSelf: 'flex-start',
    marginTop: 10,
    marginLeft: 10,
  },
});

function Card({
  children,
  backgroundColor,
  isClosable,
  isExpandable,
  onExpand,
  onClose,
  canGoBack,
  onGoBack,
  style,
}) {
  const { t } = useTranslation();

  return (
    <View style={[styles.container, { backgroundColor }, { ...style }]}>
      {(canGoBack || isClosable || isExpandable) && (
      <View style={[styles.headerContainer]}>
        {isExpandable
          ? (
            <Button style={styles.backButton} onPress={onExpand} accessibilityLabel={t('accessibility.labels.common.goBack')} accessibilityHint={t('accessibility.hints.common.goBack')}>
              <Expand role="none" color="white" height={24} width={24} />
            </Button>
          ) : <></>}
        {canGoBack
          ? (
            <Button style={styles.backButton} onPress={onGoBack} accessibilityLabel={t('accessibility.labels.common.goBack')} accessibilityHint={t('accessibility.hints.common.goBack')}>
              <ArrowLeft role="none" color="white" height={24} width={24} />
            </Button>
          ) : <></>}
        {isClosable
          ? (
            <Button style={styles.closeButton} onPress={onClose} accessibilityLabel={t('accessibility.labels.common.goBack')} accessibilityHint={t('accessibility.hints.common.goBack')}>
              <Xmark role="none" color="white" height={30} width={30} />
            </Button>
          ) : <></>}
      </View>
      )}
      {children}
    </View>
  );
}

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  backgroundColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  isClosable: PropTypes.bool,
  isExpandable: PropTypes.bool,
  onClose: PropTypes.func,
  canGoBack: PropTypes.bool,
  onGoBack: PropTypes.func,
  onExpand: PropTypes.func,
};
Card.defaultProps = {
  children: null,
  backgroundColor: COLORS.background,
  style: {},
  isClosable: false,
  isExpandable: false,
  canGoBack: false,
  onClose: () => {},
  onGoBack: () => {},
  onExpand: () => {},
};

export default Card;
