import PropTypes from 'prop-types';

const articleType = PropTypes.shape({
  title: PropTypes.string,
  image: PropTypes.string,
  sourceName: PropTypes.string,
  sourceType: PropTypes.string,
  tagCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  url: PropTypes.string,
  timestamp: PropTypes.string,
});

const articleDefault = {
  title: 'Guerre Israël-Hamas : les tensions s’intensifient entre Israël et les Nations unies',
  image: 'https://img.lemde.fr/2024/02/10/0/0/8640/5760/1440/960/60/0/1973503_0fc0767fa6ee443fac849611481e75ca-2-56e985b2f0c2450b99eb382fc5b3f36a.jpg',
  sourceName: 'LeMonde',
  tagCategory: 'International',
  url: 'https://www.lemonde.fr/international/article/2024/03/05/guerre-israel-hamas-les-tensions-s-intensifient-entre-israel-et-les-nations-unies_6220204_3210.html',
  timestamp: '2024-03-05T10:26:47+01:00',
  sourceType: "Presse d'Information Générale",
};

export {
  articleType,
  articleDefault,
};
