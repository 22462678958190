import React, { useEffect, useState } from 'react';
import {
  StyleSheet, Text, View, ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { useUserContextState } from '../../../contexts/UserContext';
import { useContentContextState } from '../../../contexts/ContentContext';
import COLORS from '../../../utils/colors';
import Button from '../../Common/Button/Button';
import Card from '../../Common/Card/Card';
import BmmModal from '../../Common/Modal/BmmModal';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  modalContent: {
    width: '40%',
    height: '80%',
    backgroundColor: COLORS.background,
  },
  content: {
    paddingHorizontal: '5%',
    flex: 12,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    color: 'white',
    fontSize: 18,
    marginBottom: '5%',
    fontWeight: 700,
  },
  scrollviewContainer: {
    borderColor: COLORS.textSecondary,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingTop: '3%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    flex: 8,
  },
  submitButtonContainer: {
    ƒlex: 2,
    paddingVertical: '3%',
    width: '100%',
    alignItems: 'center',
  },
  category: {
    marginRight: '2%',
    marginBottom: '3%',
    height: '5%',
  },
});

function CategoriesModal({
  style,
  modalVisible,
  onClose,
  onSubmit,
}) {
  const { t } = useTranslation();
  const contentContext = useContentContextState();
  const userContext = useUserContextState();

  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    setSelectedCategories(userContext.user.interests);
  }, []);

  function onPressCategory(category) {
    if (selectedCategories && selectedCategories.includes(category)) {
      analytics.track('user toggled interest', { interest: category, isActive: true });
      const res = selectedCategories.filter((elem) => elem !== category);
      setSelectedCategories(res);
    } else {
      analytics.track('user toggled interest', { interest: category, isActive: false });
      setSelectedCategories((prevState) => prevState.concat(category));
    }
  }

  function saveInterests() {
    analytics.track('user saved interests');
    onSubmit(selectedCategories);
    userContext.updateUserInterests(selectedCategories);
    onClose();
  }

  return (
    <BmmModal modalVisible={modalVisible} style={{ ...style }}>
      <Card isClosable style={styles.modalContent} onClose={onClose}>
        <View style={styles.content}>
          <Text style={styles.title}>{t('interests.title')}</Text>
          <ScrollView
            contentContainerStyle={styles.scrollviewContainer}
            style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}
          >
            {contentContext.beCategories.map((category) => (
              <Button
                text={category}
                key={`cat-${category}`}
                style={styles.category}
                backgroundColor={(selectedCategories && selectedCategories.includes(category))
                  ? COLORS.primary : COLORS.backgroundSecondary}
                onPress={() => { onPressCategory(category); }}
              />
            ))}
          </ScrollView>
          <View style={styles.submitButtonContainer}>
            <Button text={t('interests.save')} style={{ width: '20%' }} onPress={() => { saveInterests(); }} />
          </View>
        </View>
      </Card>
    </BmmModal>
  );
}

CategoriesModal.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  modalVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CategoriesModal.defaultProps = {
  style: {},
  modalVisible: false,
  onSubmit: () => {},
  onClose: () => {},
};

export default CategoriesModal;
