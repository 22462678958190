import React, { useState, useMemo } from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RadioGroup from 'react-native-radio-buttons-group';

import newsApi from '../../../api/newsApi';
import COLORS from '../../../utils/colors';
import Card from '../../Common/Card/Card';
import BmmModal from '../../Common/Modal/BmmModal';
import Button from '../../Common/Button/Button';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  modalContent: {
    width: '35%',
    height: '50%',
    backgroundColor: COLORS.background,
  },
  modalContainer: {
    height: '100%',
    justifyContent: 'flex-start',
    marginTop: 50,
    alignItems: 'flex-start',
  },
  why: {
    color: 'white',
    fontSize: 16,
    marginBottom: '5%',
    marginLeft: 40,
  },
  radioButtonsContainer: {
    height: '60%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingHorizontal: '10%',
  },
  radioButtonsLabel: {
    color: 'white',
    fontSize: 15,
    marginLeft: 20,
  },
  submitButton: {
    alignSelf: 'center',
    width: '50%',
    marginTop: 15,
    height: '8%',
  },
});

function ReportModal({
  style,
  modalVisible,
  onClose,
  articleUrl,
}) {
  const { t } = useTranslation();

  const [selectedId, setSelectedId] = useState(-1);

  const radioButtons = useMemo(() => ([
    {
      id: 1,
      label: t('report.vicious'),
      value: 'option1',
      borderSize: 1,
      borderColor: 'white',
      color: COLORS.primary,
    },
    {
      id: 2,
      label: t('report.persecution'),
      value: 'option2',
      borderSize: 1,
      borderColor: 'white',
      color: COLORS.primary,
    },
    {
      id: 3,
      label: t('report.brutal'),
      value: 'option2',
      borderSize: 1,
      borderColor: 'white',
      color: COLORS.primary,
    },
    {
      id: 4,
      label: t('report.misinformation'),
      value: 'option2',
      borderSize: 1,
      borderColor: 'white',
      color: COLORS.primary,
    },
    {
      id: 5,
      label: t('report.property'),
      value: 'option2',
      borderSize: 1,
      borderColor: 'white',
      color: COLORS.primary,
    },
  ]), []);

  const submitForm = async () => {
    if (selectedId !== -1) {
      analytics.track('user sent a report request', { type: 'article', reason: selectedId });
      const res = await newsApi.reportArticle(articleUrl, selectedId);
      if (!res.error) {
        onClose();
      }
    }
  };

  return (
    <BmmModal modalVisible={modalVisible} style={{ ...style }}>
      <Card isClosable style={styles.modalContent} onClose={() => { analytics.track('user closed report modal'); onClose(); }}>
        <View style={styles.modalContainer}>
          <Text style={styles.why}>{t('report.why')}</Text>
          <RadioGroup
            containerStyle={styles.radioButtonsContainer}
            labelStyle={styles.radioButtonsLabel}
            radioButtons={radioButtons}
            onPress={setSelectedId}
            selectedId={selectedId}
          />
          <Button style={styles.submitButton} onPress={() => { submitForm(); }} text={t('editos.form.submit')} />
        </View>
      </Card>
    </BmmModal>
  );
}

ReportModal.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  modalVisible: PropTypes.bool,
  onClose: PropTypes.func,
  articleUrl: PropTypes.string,
};

ReportModal.defaultProps = {
  style: {},
  modalVisible: false,
  onClose: () => {},
  articleUrl: '',
};

export default ReportModal;
