import 'react-native-gesture-handler';
import React from 'react';
import AppContainer from './src/AppContainer';
import { AuthContextProvider } from './src/contexts/AuthContext';
import { UserContextProvider } from './src/contexts/UserContext';
import { ContentContextProvider } from './src/contexts/ContentContext';
import { ProgressContextProvider } from './src/contexts/ProgressContext';
import { NavigationContextProvider } from './src/contexts/NavigationContext';
import './i18n';

function App() {
  return (
    <AuthContextProvider>
      <NavigationContextProvider>
        <UserContextProvider>
          <ContentContextProvider>
            <ProgressContextProvider>
              <AppContainer />
            </ProgressContextProvider>
          </ContentContextProvider>
        </UserContextProvider>
      </NavigationContextProvider>
    </AuthContextProvider>
  );
}

export default App;
