import React, { useState } from 'react';
import {
  StyleSheet, Text, Pressable,
} from 'react-native';
import PropTypes from 'prop-types';
import COLORS from '../../../utils/colors';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 15,
  },
  textButton: {
    cursor: 'pointer',
  },
});

function Button({
  children,
  backgroundColor,
  color,
  onPress,
  onHoverIn,
  onHoverOut,
  text,
  hoveredColor,
  hoveredTextColor,
  accessibilityLabel,
  accessibilityHint,
  style,
  textStyle,
  hoverable,
}) {
  const [isHovered, setIsHovered] = useState(false);

  function handleHoverIn() {
    onHoverIn();
    setIsHovered(true);
  }

  function handleHoverOut() {
    onHoverOut();
    setIsHovered(false);
  }

  let element = null;
  let role = null;
  if (children) {
    element = children;
    role = 'button';
  } else if (text) {
    element = <Text role="button" style={[textStyle, (isHovered && hoverable) ? { color: hoveredTextColor } : { color }, styles.textButton]}>{text}</Text>;
  }
  return (
    <Pressable
      accessible
      role={role}
      aria-label={accessibilityLabel || null}
      accessibilityHint={accessibilityHint || null}
      style={[styles.container,
        hoverable && isHovered ? { backgroundColor: hoveredColor } : { backgroundColor },
        { ...style }]}
      onPress={onPress}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
    >
      {element}
    </Pressable>
  );
}

Button.propTypes = {
  children: PropTypes.element,
  backgroundColor: PropTypes.string,
  hoveredColor: PropTypes.string,
  hoveredTextColor: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  textStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onPress: PropTypes.func,
  onHoverIn: PropTypes.func,
  onHoverOut: PropTypes.func,
  text: PropTypes.string,
  hoverable: PropTypes.bool,
  accessibilityLabel(props, propName, componentName) {
    if ((!props.text && (props[propName] === undefined || typeof (props[propName]) !== 'string'))) {
      return new Error(`Please provide a valide accessibilityLabel or text props to ${componentName}`);
    }
    return null;
  },
  accessibilityHint(props, propName, componentName) {
    if ((!props.text && (props[propName] === undefined || typeof (props[propName]) !== 'string'))) {
      return new Error(`Please provide a valide accessibilityHint or text props to ${componentName}`);
    }
    return null;
  },
};
Button.defaultProps = {
  children: undefined,
  backgroundColor: COLORS.primary,
  hoveredColor: COLORS.secondary,
  color: 'white',
  hoveredTextColor: 'white',
  textStyle: {},
  text: null,
  hoverable: true,
  accessibilityLabel: undefined,
  accessibilityHint: undefined,
  style: {},
  onPress: () => {},
  onHoverIn: () => {},
  onHoverOut: () => {},
};

export default Button;
