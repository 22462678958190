import React, { useState } from 'react';
import {
  StyleSheet, View, Text, Linking,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import COLORS from '../../../utils/colors';
import analytics from '../../../utils/analytics';

import Button from '../../Common/Button/Button.web';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: '5%',
    flex: 8,
  },
  title: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '5%',
  },
});

function SsoAuths({
  style,
}) {
  const { t } = useTranslation();

  function onPressEduservices() {
    analytics.track('user tried to connect using eduservices');
    Linking.openURL('https://api.be-my-media.com/sso/eduservices/login');
  }

  return (
    <View style={[styles.container, { ...style }]}>
      <Text style={styles.title}>{t('auth.otherConnections')}</Text>
      <Button
        style={{ width: '80%', height: '12%', marginBottom: 20 }}
        onPress={() => { onPressEduservices(); }}
        text={t('auth.sso.eduservices')}
      />
    </View>
  );
}

SsoAuths.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};
SsoAuths.defaultProps = {
  style: {},
};

export default SsoAuths;
