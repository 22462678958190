import React, {
  createContext, useContext, useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import newsApi from '../api/newsApi';
import editoApi from '../api/editoApi';

const ContentContext = createContext();

const useContentContextState = () => {
  const context = useContext(ContentContext);

  if (context === undefined) {
    throw new Error('useContentContextState was used outside of its Provider');
  }

  return context;
};

function ContentContextProvider({ children }) {
  const [articles, setArticles] = useState([]);
  const [searchedArticles, setSearchedArticles] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [myEditos, setMyEditos] = useState([]);
  const [bookmarkedArticles, setBookmarkedArticles] = useState([]);
  const [inappEditos, setInappEditos] = useState([]);
  const [actuEditos, setActuEditos] = useState([]);
  const [inappEditosList, setInappEditosList] = useState([]);
  const [actuEditosList, setActuEditosList] = useState([]);
  const [orgaEditosList, setOrgaEditosList] = useState([]);
  const [beCategories, setBeCategories] = useState([]);
  const [isOrgaFeed, setIsOrgaFeed] = useState(true);
  const [from, setFrom] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cisionSearchId, setCisionSearchId] = useState(null);
  const [cisionOffset, setCisionOffset] = useState(0);

  useEffect(() => {
    async function getCategories() {
      const res = await newsApi.getCategories();
      setBeCategories(res);
    }
    getCategories();
  }, []);

  const searchArticles = useCallback(async (searchQuery) => {
    setIsLoading(true);
    if (searchQuery !== '') {
      let res = await newsApi.searchArticle(searchQuery);
      if (res.queryRangeId) {
        res = res.articles;
      }
      if (!res.error) {
        setIsLoading(false);
        setSearchedArticles(res);
      }
    } else {
      setSearchedArticles([]);
    }
  }, []);

  const setCategories = useCallback((categories) => {
    setFrom(null);
    setArticles([]);
    setCisionSearchId(null);
    setCisionOffset(0);
    setCurrentCategories(categories);
  }, []);

  const getArticles = useCallback(async () => {
    if (currentCategories.length > 0) {
      let listArticle = await newsApi.getNewsList(from, currentCategories, isOrgaFeed, cisionSearchId, cisionOffset);
      if (listArticle.queryRangeId) {
        setCisionSearchId(listArticle.queryRangeId);
        listArticle = listArticle.articles;
      }
      setCisionOffset(cisionOffset + 20);
      setArticles((prevData) => prevData.concat(listArticle));
      if (listArticle.length > 0 && listArticle[listArticle.length - 1].timestamp) {
        setFrom(listArticle[listArticle.length - 1].timestamp);
      }
    }
  }, [currentCategories, from, isOrgaFeed, cisionOffset, cisionSearchId]);

  useEffect(() => {
    async function getFeed() {
      if (currentCategories.length > 0) {
        await getArticles();
      }
    }
    getFeed();
  }, []);

  useEffect(() => {
    const tmp = [];
    myEditos.forEach((edito) => {
      tmp[edito.id] = edito.articles;
    });
    setBookmarkedArticles(tmp);
  }, [myEditos]);

  const setBookmarked = () => {
    const tmp = [];
    myEditos.forEach((edito) => {
      tmp[edito.id] = edito.articles;
    });
    setBookmarkedArticles(tmp);
  };

  const checkBookmarkedArticle = (url) => {
    const res = { editoId: null, isBookmarked: false };
    bookmarkedArticles.forEach((urls, editoId) => {
      urls.forEach((article) => {
        if ((typeof article === 'string' && article === url)
          || (typeof article === 'object' && article.url === url)) {
          res.editoId = editoId;
          res.isBookmarked = true;
        }
      });
    });
    return (res);
  };

  const getBeEditos = useCallback(async () => {
    const editos = await editoApi.getFeedEditos();
    setActuEditos(editos.actu);
    setInappEditos(editos.inapp);
  }, []);

  const getMyEditos = useCallback(async () => {
    const editos = await editoApi.getMyEditos();
    setMyEditos(editos);
    setBookmarked();
  }, []);

  const getEditos = useCallback(async () => {
    const beEditosPage = actuEditosList.length / 5;
    const orgaEditosPage = orgaEditosList.length / 5;
    const beEditos = await editoApi.getBeEditos(beEditosPage);
    const orgaEditos = await editoApi.getOrgaEditos(orgaEditosPage);
    setActuEditosList((prevData) => prevData.concat(beEditos.actu));
    setInappEditosList((prevData) => prevData.concat(beEditos.inapp));
    setOrgaEditosList(orgaEditos);
  }, [actuEditosList, inappEditosList, orgaEditosList]);

  const createEdito = async (title, description, isPublic) => {
    await editoApi.createEdito(title, description, isPublic);
    await getMyEditos();
  };

  const updateEdito = async (editoId, title, description, editoArticles, isPublic, image, type) => {
    await editoApi.updateEdito(editoId, title, description, editoArticles, isPublic, image, type);
    await getMyEditos();
  };

  const deleteEdito = async (editoId) => {
    await editoApi.deleteEdito(editoId);
    setMyEditos(myEditos.filter((edito) => (edito.id !== editoId)));
  };

  const addArticleToEdito = async (editoId, newArticles, image) => {
    await editoApi.addArticleToEdito(editoId, newArticles, image);
    await getMyEditos();
  };

  const addArticleToDefault = async (newArticles) => {
    await editoApi.addArticleToDefault(newArticles);
    await getMyEditos();
  };

  const removeArticleFromEdito = async (editoId, removedArticles) => {
    await editoApi.removeArticleFromEdito(editoId, removedArticles);
    await getMyEditos();
  };

  const contextValue = useMemo(
    () => ({
      getArticles,
      setCategories,
      getBeEditos,
      getEditos,
      searchArticles,
      searchedArticles,
      inappEditos,
      actuEditos,
      isLoading,
      inappEditosList,
      myEditos,
      actuEditosList,
      orgaEditosList,
      currentCategories,
      setIsOrgaFeed,
      articles,
      getMyEditos,
      deleteEdito,
      createEdito,
      updateEdito,
      addArticleToEdito,
      removeArticleFromEdito,
      addArticleToDefault,
      checkBookmarkedArticle,
      bookmarkedArticles,
      beCategories,
      cisionOffset,
      cisionSearchId,
    }),
    [isLoading,
      getArticles,
      setCategories,
      setIsOrgaFeed,
      articles,
      inappEditos,
      isLoading,
      actuEditos,
      inappEditosList,
      myEditos,
      actuEditosList,
      orgaEditosList,
      getBeEditos,
      getEditos,
      searchArticles,
      searchedArticles,
      getMyEditos,
      deleteEdito,
      createEdito,
      updateEdito,
      addArticleToEdito,
      removeArticleFromEdito,
      addArticleToDefault,
      checkBookmarkedArticle,
      myEditos,
      bookmarkedArticles,
      beCategories,
      cisionOffset,
      cisionSearchId,
    ],
  );

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  );
}

ContentContextProvider.propTypes = {
  children: PropTypes.element,
};
ContentContextProvider.defaultProps = {
  children: null,
};

export { ContentContextProvider, useContentContextState };
