import React, { useEffect } from 'react';
import {
  View, StyleSheet, Text,
  Image,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import { Circle, CheckCircle } from 'iconoir-react-native';
import PropTypes from 'prop-types';
import COLORS from '../../../utils/colors';
import CustomProgressBar from '../CustomProgressBar/CustomProgressBar.web';
import { images } from '../../../utils/constants';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '50vh',
    backgroundColor: COLORS.backgroundTernaryOpac,
    paddingTop: '2%',
  },
  status: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    paddingHorizontal: 20,
    paddingVertical: 5,
    backgroundColor: COLORS.backgroundSecondary,
    marginBottom: '2%',
  },
  statusText: {
    color: 'white',
  },
  title: {
    color: 'white',
    width: '60%',
    fontSize: 25,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '3%',
  },
  goalTitle: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
    marginBottom: '2%',
  },
  goal: {
    flexDirection: 'row',
    marginBottom: '2%',
  },
  goalIcon: {
    marginRight: '2%',
  },
  splittedCard: {
    width: '50%',
    // height: '45%',
    flexDirection: 'row',
    marginBottom: '1%',
  },
  cardLeft: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: 25,
    paddingVertical: 15,
    width: '70%',
    height: '100%',
    backgroundColor: COLORS.backgroundSecondary,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRightColor: '#3c3c3c',
    borderRightWidth: 2,
  },
  cardRight: {
    flexDirection: 'row',
    width: '30%',
    height: '100%',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: COLORS.backgroundTernary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  xpWarning: {
    width: '50%',
    backgroundColor: COLORS.backgroundSecondary,
    height: '10%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginBottom: '1%',
  },
});

function ProgressHeader({
  style,
  isDone,
  title,
  seasonNumber,
  userActions,
  nextLevelGoals,
  nextLevelName,
  prevLevelRequiredPoints,
  userPoints,
  nextLevelRequiredPoints,
  dailyPointsReached,
}) {
  const { t } = useTranslation();

  return (
    <View style={({ ...styles.container, ...style })}>
      <View style={styles.status}>
        <Text style={styles.statusText}>{isDone ? t('progress.done') : t('progress.doing')}</Text>
      </View>
      <Text style={styles.title}>{title}</Text>
      {dailyPointsReached && (
      <View style={styles.xpWarning}>
        <Text style={{ color: 'white' }}>{t('progress.limit')}</Text>
      </View>
      )}
      <View style={styles.splittedCard}>
        <View style={styles.cardLeft}>
          <Text style={styles.goalTitle}>
            Objectifs:
            {' '}
            <Text style={{ color: COLORS.primary }}>{nextLevelName}</Text>
          </Text>
          {nextLevelGoals.map((prop, key) => {
            if (userActions && userActions.includes(prop)) {
              return (
                <View key={`goal-${prop}`} style={styles.goal}>
                  <CheckCircle style={styles.goalIcon} role="none" color="green" height={18} width={18} />
                  <Text style={{ color: 'white', fontSize: 13 }}>{t(`progress.goals.${prop}`)}</Text>
                </View>
              );
            }
            return (
              <View key={`goal-${prop}`} style={styles.goal}>
                <Circle style={styles.goalIcon} role="none" color={COLORS.textSecondary} height={18} width={18} />
                <Text style={{ color: 'white', fontSize: 13 }}>{t(`progress.goals.${prop}`)}</Text>
              </View>
            );
          })}
          <CustomProgressBar userExperience={userPoints} previousExperience={prevLevelRequiredPoints} requiredExperience={nextLevelRequiredPoints} style={{ marginTop: '2%' }} />
        </View>
        <View style={styles.cardRight}>
          <Image
            accessibilityIgnoresInvertColors
            source={{ uri: images[`level${seasonNumber}${isDone ? '' : 'lock'}`], width: 80, height: 80 }}
          />
        </View>
      </View>
    </View>
  );
}

ProgressHeader.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  isDone: PropTypes.bool,
  title: PropTypes.string,
  seasonNumber: PropTypes.number,
  nextLevelName: PropTypes.string,
  nextLevelGoals: PropTypes.arrayOf(PropTypes.string),
  nextLevelRequiredPoints: PropTypes.number,
  prevLevelRequiredPoints: PropTypes.number,
  userPoints: PropTypes.number,
  userActions: PropTypes.arrayOf(PropTypes.string),
  dailyPointsReached: PropTypes.bool,
};

ProgressHeader.defaultProps = {
  style: {},
  isDone: false,
  title: 'Comprendre le fonctionnement et les enjeux du circuit de production de l\'information',
  seasonNumber: 2,
  nextLevelName: 'Explorateur',
  nextLevelGoals: ['read', 'season_completed_1', 'season_completed_2'],
  nextLevelRequiredPoints: 1500,
  prevLevelRequiredPoints: 200,
  userPoints: 300,
  userActions: ['read'],
  dailyPointsReached: false,
};

export default ProgressHeader;
