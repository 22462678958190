import React, { useEffect, useRef, useState } from 'react';
import {
  View, StyleSheet, ScrollView, ActivityIndicator,
} from 'react-native';

import { useTranslation } from 'react-i18next';
import AwesomeAlert from 'react-native-awesome-alerts';
import Layout from '../../components/Common/Layout/Layout.web';
import { useUserContextState } from '../../contexts/UserContext';
import { useProgressContextState } from '../../contexts/ProgressContext';
import COLORS from '../../utils/colors';

import ProgressHeader from '../../components/Progress/ProgressHeader/ProgressHeader.web';
import EpisodeList from '../../components/Progress/EpisodeList/EpisodeList.web';
import { levelNames, MAXIMUM_POINTS_PER_DAY } from '../../utils/constants';
import FinalQuiz from '../../components/Progress/FinalQuiz/FinalQuiz';
import analytics from '../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '1000%',
    backgroundColor: COLORS.background,
    paddingHorizontal: '5%',
  },
  scrollview: {
    height: '100%',
    backgroundColor: COLORS.background,
  },
  content: {
    backgroundColor: COLORS.background,
    height: '100%',
    paddingBottom: 50,
  },
  alertContainer: {
    position: 'absolute',
    zIndex: 5,
    backgroundColor: 'transparent',
  },
  alertOverlay: {
    backgroundColor: 'transparent',
  },
  alertContent: {
    borderColor: COLORS.textSecondary,
    borderWidth: 1,
    backgroundColor: COLORS.background,
  },
  confirmButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 15,
    width: '10%',
  },
});

function Progress() {
  const { t } = useTranslation();

  const userContext = useUserContextState();
  const progressContext = useProgressContextState();

  const [showAlert, setShowAlert] = useState(false);
  const [currentSeasonIndex, setCurrentSeasonIndex] = useState(0);
  const [actualSeasonIndex, setActualSeasonIndex] = useState(0);
  const [isFinalQuiz, setIsFinalQuiz] = useState(false);
  const scrollViewRef = useRef();

  useEffect(() => {
    async function loadProgress() {
      await progressContext.getProgress();
    }
    loadProgress();
  }, []);

  useEffect(() => {
    if (!progressContext.isLoading) {
      let currentIndex = 0;
      progressContext.progress.seasons.every((season, index) => {
        if (season.isAvailable) {
          currentIndex = index;
          return true;
        }
        return false;
      });
      setActualSeasonIndex(currentIndex);
      setCurrentSeasonIndex(currentIndex);
      if (progressContext.progress.seasons[currentIndex].isFinalExam) {
        setIsFinalQuiz(true);
      }
    }
  }, [progressContext.isLoading]);

  const getMenuItems = () => {
    const res = progressContext.progress.seasons.map((season) => (
      { text: `Saison ${season.number}`, isDisable: !season.isAvailable }
    ));
    return res;
  };

  const onPressSeason = (index, item) => {
    analytics.track('user clicked on season', { season: index + 1 });
    if (item.isDisable) {
      setShowAlert(true);
    } else {
      setCurrentSeasonIndex(index);
      if (progressContext.progress.seasons[index].isFinalExam) {
        setIsFinalQuiz(true);
      } else {
        setIsFinalQuiz(false);
      }
    }
  };

  if (progressContext.isLoading || !progressContext.progress.seasons) {
    return (
      <Layout
        style={[styles.container, { justifyContent: 'center', alignItems: 'center' }]}
        currentPage="progress"
        menuItems={['Saison 1', 'Saison 2', 'Saison 3', 'Saison 4', 'Saison 5', 'La grande épreuve']}
      >
        <ActivityIndicator style={{ marginTop: '20%' }} size="large" color={COLORS.primary} />
      </Layout>
    );
  }

  return (
    <Layout
      style={styles.container}
      onPressMenuItem={onPressSeason}
      menuItems={getMenuItems()}
      key={currentSeasonIndex}
      selectedIndex={currentSeasonIndex}
      currentPage="progress"
    >
      <ScrollView
        style={{ marginBottom: 20 }}
        contentContainerStyle={styles.scrollview}
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
      >
        <View style={styles.content}>
          <AwesomeAlert
            show={showAlert}
            alertContainerStyle={styles.alertContainer}
            contentContainerStyle={styles.alertContent}
            titleStyle={{ color: 'white' }}
            messageStyle={{ color: 'white' }}
            overlayStyle={styles.alertOverlay}
            title={t('progress.seasonLocked')}
            message={t('progress.seasonLockedText')}
            showConfirmButton
            confirmText="Ok"
            confirmButtonStyle={styles.confirmButton}
            confirmButtonColor={COLORS.primary}
            onConfirmPressed={() => {
              setShowAlert(false);
            }}
          />
          {!isFinalQuiz && (
            <ProgressHeader
              isDone={userContext.user.badges.filter((badge) => badge.id === currentSeasonIndex + 1).length > 0}
              title={progressContext.progress.seasons[currentSeasonIndex].title}
              seasonNumber={progressContext.progress.seasons[currentSeasonIndex].number}
              nextLevelName={levelNames[currentSeasonIndex]}
              nextLevelGoals={progressContext.progress.seasons[currentSeasonIndex].requiredActions}
              nextLevelRequiredPoints={progressContext.progress.seasons[currentSeasonIndex].requiredPoints}
              prevLevelRequiredPoints={
                progressContext.progress.seasons[currentSeasonIndex - 1]
                  ? progressContext.progress.seasons[currentSeasonIndex - 1].requiredPoints : 0
              }
              userPoints={userContext.user.experience}
              userActions={userContext.user.actions}
              dailyPointsReached={userContext.user.experienceToday >= MAXIMUM_POINTS_PER_DAY}
            />
          )}
          {!isFinalQuiz && (
          <EpisodeList
            episodes={progressContext.progress.seasons[currentSeasonIndex].episodes}
            numberOfEpisodes={progressContext.progress.seasons[currentSeasonIndex].episodes.length}
            seasonNumber={progressContext.progress.seasons[currentSeasonIndex].number}
            seasonId={progressContext.progress.seasons[currentSeasonIndex].id}
            scrollRef={scrollViewRef}
          />
          )}
          {isFinalQuiz && (
            <FinalQuiz
              status={progressContext.progress.finalQuizStatus}
              xpLastTry={progressContext.progress.finalQuizXpLastTry}
              currentXp={userContext.user.experience}
              seasonIndex={currentSeasonIndex}
              sendMail={progressContext.sendMail}
            />
          )}
        </View>
      </ScrollView>
    </Layout>
  );
}

export default Progress;
