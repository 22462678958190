import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';

function Error({ error }) {
  return (
    <View>
      <Text>
        Erreur:
        {error.message}
      </Text>
    </View>
  );
}

Error.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

Error.defaultProps = {
  error: { message: 'Unknown error' },
};

export default Error;
