import Constants from 'expo-constants';
import level1 from '../../assets/appImages/badges/level1.png';
import level2 from '../../assets/appImages/badges/level2.png';
import level3 from '../../assets/appImages/badges/level3.png';
import level4 from '../../assets/appImages/badges/level4.png';
import level5 from '../../assets/appImages/badges/level5.png';
import level6 from '../../assets/appImages/badges/level6.png';
import level1lock from '../../assets/appImages/badges/level1lock.png';
import level2lock from '../../assets/appImages/badges/level2lock.png';
import level3lock from '../../assets/appImages/badges/level3lock.png';
import level4lock from '../../assets/appImages/badges/level4lock.png';
import level5lock from '../../assets/appImages/badges/level5lock.png';
import level6lock from '../../assets/appImages/badges/level6lock.png';
import logo from '../../assets/appImages/logo.png';
import logoWhite from '../../assets/webImages/logo-white.png';

const envBackendUrl = Constants.expoConfig?.extra?.BACKEND_URL;
export const backendUrl = envBackendUrl ?? 'http://0.0.0.0:8014/api';

export const CONTENT_TYPE = 'application/json; charset=utf-8';
export const MAXIMUM_POINTS_PER_DAY = 200;
export const levelNames = [
  "L'apprenti",
  'Le correspondant',
  'Le reporter',
  'Le chroniqueur',
  "Le redac' chef",
  'Le grand reporter',
];

export const externalLinks = {
  cgu: 'https://api.be-my-media.com/cgu/',
  privacy: 'https://api.be-my-media.com/politique-confidentialite',
  faq: 'https://www.be-my-media.com/faq/',
  gameRules: 'https://www.be-my-media.com/regles-du-jeu/',
  support: 'mailto:support@be-my-media.com',
};

const CategoriesImages = {
  Arts: 'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/art-min.jpg',
  'Auto-Moto':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Auto-Moto+1-min.jpg',
  Cinéma:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/cine%CC%81ma-min.jpg',
  Consommation:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/consommation-min.jpg',
  Culture:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/culture-min.jpg',
  'Deco - Design - Architecture':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Deco+-+Design+-+Architecture-min.jpg',
  Economie:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/economie+1-min.jpg',
  Emploi:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Emploi-min.jpg',
  Entreprise:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/entreprise-min.jpg',
  Environnement:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/environement-min.jpg',
  Etudiant:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/etudiant-min.jpg',
  'Famille - Parentalité':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Famille+-+parentalite%CC%81-min.jpg',
  Foot: 'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Foot-min.jpg',
  Histoire:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Histoire-min.jpg',
  International:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/international-min.jpg',
  'Jeux vidéos':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/jeux+vide%CC%81o-min.jpg',
  'Les grands titres':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Les+grands+titres-min.jpg',
  'Lifestyle homme':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/lifestyle-min.jpg',
  'Lifestyle femme':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/lifestyle-min.jpg',
  'Mon actualité en anglais':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Mon+actualite%CC%81+en+anglais-min.jpg',
  'Mon actualité en espagnol':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Mon+actualite%CC%81+en+espagnol-min.jpg',
  Musique:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Musique-min.jpg',
  'Nouveaux médias':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Nouveaux+me%CC%81dias-min.jpg',
  Politique:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/politique-min.jpg',
  'Santé - Bien-être':
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/sante%CC%81-min.jpg',
  Sciences:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/science-min.jpg',
  Société:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/socie%CC%81te%CC%81-min.jpg',
  Sport:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/sport-min.jpg',
  Startup:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Startup-min.jpg',
  Technologies:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/tech-min.jpg',
  Voyage:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/voyage-min.jpg',
  default:
      'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/01+par+defaut-min.jpg',
};

const CisionImage = {
  white: {
    Agroalimentaire: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Agroalimentaire-white.png',
    Architecture: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Architecture-white.png',
    Art: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Art-white.png',
    Culture: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Art-white.png',
    'Design - Architecture': 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Art-white.png',
    'Communication et Marketing': 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Communication-white.png',
    Droit: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Droit-white.png',
    Ecologie: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Ecologie-white.png',
    Economie: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Economie-white.png',
    Industrie: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Industrie-white.png',
    Logistique: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Logistique-white.png',
    'Management - Gestion': 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Management-white.png',
    Finance: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Management-white.png',
    Numerique: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Numerique-white.png',
    Politique: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Politique-white.png',
    Sciences: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Science-white.png',
    Technologies: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Science-white.png',
    Société: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Societewhite.png',
    'Les grands titres': 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Societewhite.png',
    Sport: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Sport-white.png',
  },
  black: {
    Agroalimentaire: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Agroalimentaire-black.png',
    Architecture: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Architecture-black.png',
    Art: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Art-black.png',
    Culture: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Art-black.png',
    'Design - Architecture': 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Art-black.png',
    'Communication et Marketing': 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Communication-black.png',
    Droit: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Droit-black.png',
    Ecologie: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Ecologie-black.png',
    Economie: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Economie-black.png',
    Industrie: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Industrie-black.png',
    Logistique: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Logistique-black.png',
    'Management - Gestion': 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Management-black.png',
    Finance: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Management-black.png',
    Numerique: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Numerique-black.png',
    Politique: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Politique-black.png',
    Sciences: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Science-black.png',
    Technologies: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Science-black.png',
    Société: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Societe-black.png',
    'Les grands titres': 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Societe-black.png',
    Sport: 'https://b2m-prd-app-assets.s3.eu-west-3.amazonaws.com/articles/europress/BMM-Sport-black.png',
  },
};

export const getCategoryImage = (category) => {
  if (CategoriesImages[category]) {
    return CategoriesImages[category];
  }
  return CategoriesImages.default;
};

export const getCisionImage = (category, isBlack) => {
  if (CisionImage.black[category]) {
    return isBlack ? CisionImage.black[category] : CisionImage.white[category];
  }
  return CategoriesImages.default;
};

export const images = {
  level1,
  level2,
  level3,
  level4,
  level5,
  level6,
  level1lock,
  level2lock,
  level3lock,
  level4lock,
  level5lock,
  level6lock,
  logo,
  logoWhite,
};
