import React from 'react';
import { StyleSheet, ActivityIndicator, View } from 'react-native';

import COLORS from '../../utils/colors';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function LoadingScreen() {
  return (
    <View
      style={styles.container}
    >
      <ActivityIndicator size="large" color={COLORS.primary} />
    </View>
  );
}

export default LoadingScreen;
