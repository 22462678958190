import React, { useState, useEffect } from 'react';
import {
  StyleSheet, View, Text, ActivityIndicator,
} from 'react-native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import COLORS from '../../../utils/colors';
import { useContentContextState } from '../../../contexts/ContentContext';

import ArticleCard from '../../Article/ArticleCard/ArticleCard';
import EditoCard from '../../Edito/EditoCard/EditoCard';
import Button from '../../Common/Button/Button';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  container: {
    height: '90%',
    marginBottom: 30,
  },
  loaderContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  articlesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingHorizontal: '5%',
    marginBottom: '2%',
    marginTop: '1%',
    height: '120%',
  },
  card: {
    width: '29vw',
    height: '40vh',
    marginBottom: 15,
  },
  firstCard: {
    width: '59.5vw',
    height: '40vh',
  },
  button: {
    width: '15%',
    paddingVertical: 10,
    alignSelf: 'center',
    marginTop: '2%',
    marginBottom: 30,
  },
});

function Feed({ style, scrollRef }) {
  const { t } = useTranslation();
  const contentContext = useContentContextState();
  const [articlesBlock, setArticlesBlock] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let actuIndex = 0;
  let inappIndex = 0;

  const generateBlock = () => {
    const res = [];
    const sliceIndex = articlesBlock.length > 0 ? (articlesBlock.length * 5) : 0;
    const tmp = contentContext.articles.slice(sliceIndex);
    for (let i = 0; i < tmp.length; i += 5) {
      const block = [];
      const blockArticles = tmp.slice(i, i + 5);
      for (let j = 0; j < blockArticles.length; j += 1) {
        if (j === 0) {
          block.push(
            <ArticleCard
              key={blockArticles[j].title + i + j}
              index={j}
              bigPicture
              bookmarkable
              article={blockArticles[j]}
              style={styles.firstCard}
            />,
          );
        } else {
          block.push(
            <ArticleCard
              key={blockArticles[j].title + i + j}
              index={j}
              bookmarkable
              bigPicture
              article={blockArticles[j]}
              style={styles.card}
            />,
          );
        }
      }
      res.push(block);
    }
    setArticlesBlock((prevData) => prevData.concat(res));
    setIsLoading(false);
    return res;
  };

  const loadMoreArticle = async () => {
    analytics.track('user reached end of feed view', { articleCount: contentContext.articles.length, feed: contentContext.currentCategories.length > 1 ? 'main' : contentContext.currentCategories[0] });
    setIsLoading(true);
    setIsLoadingMore(true);
    await contentContext.getArticles();
  };

  useEffect(() => {
    if (!isLoading && isLoadingMore) {
      scrollRef.current.scrollTo({ y: 3000, animated: true });
      setIsLoadingMore(false);
    }
  }, [isLoading]);

  useEffect(() => {
    async function loadFeed() {
      if (contentContext.articles.length > 0) {
        generateBlock();
        setIsLoading(false);
      } else {
        setIsLoading(true);
        setArticlesBlock([]);
        await contentContext.getArticles();
      }
    }
    loadFeed();
  }, [contentContext.articles]);

  if (isLoading) {
    return (
      <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color={COLORS.primary} />
      </View>
    );
  }

  return (
    <View
      style={({ ...styles.container, ...style })}
    >
      {articlesBlock.map((prop, key) => {
        if (key % 3 === 0 && (inappIndex < 5 || actuIndex < 5)) {
          if (actuIndex > inappIndex) {
            inappIndex += 1;
            return (
              [
                <View key={`articleBlock-${key + 1}`} style={styles.articlesContainer}>
                  {prop}
                </View>, <EditoCard key={`inapp-${inappIndex}`} edito={contentContext.inappEditos[inappIndex - 1]} />,
              ]);
          }
          actuIndex += 1;
          return (
            [
              <View key={`articleBlock-${key + 1}`} style={styles.articlesContainer}>
                {prop}
              </View>, <EditoCard key={`actu-${actuIndex}`} edito={contentContext.actuEditos[actuIndex - 1]} />,
            ]);
        }
        return (
          <View key={`articleBlock-${key + 1}`} style={styles.articlesContainer}>
            {prop}
          </View>
        );
      })}
      {isLoading && (<Text>LOADING</Text>)}
      <Button onPress={loadMoreArticle} style={styles.button} text={t('home.loadMore')} />
    </View>
  );
}

Feed.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

Feed.defaultProps = {
  style: {},
};

export default Feed;
