import React, { useState } from 'react';
import {
  View, StyleSheet, Text, Modal, ScrollView, Image, Pressable,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckCircle } from 'iconoir-react-native';

import { useUserContextState } from '../../../contexts/UserContext';
import COLORS from '../../../utils/colors';
import Card from '../../Common/Card/Card';
import Button from '../../Common/Button/Button';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  modalContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.primaryTransparent,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  modalContent: {
    width: '30%',
    height: '70%',
    backgroundColor: 'black',
  },
  modalContentFullScreen: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    borderRadius: 0,
  },
  metadata: {
    fontSize: 13,
    color: COLORS.textSecondary,
    marginTop: 15,
    marginBottom: 5,
    alignSelf: 'center',
  },
  title: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    marginTop: 10,
    textAlign: 'center',
  },
  columnCenter: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '90%',
    paddingHorizontal: '5%',
  },
  nextButton: {
    width: '25%',
  },
  contentText: {
    color: 'white',
    fontSize: 15,
    textAlign: 'center',
    marginBottom: '3%',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  imageContainer: {
    width: '40%',
    height: '40%',
  },
  cardLeft: {
    backgroundColor: COLORS.backgroundSecondary,
    width: '100%',
    height: '20%',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '1%',
    paddingBottom: '2%',
    paddingHorizontal: '3%',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'green',
  },
  cardMetadata: {
    flexDirection: 'row',
  },
  icon: {
    marginRight: '1%',
  },
  metadataText: {
    color: COLORS.textSecondary,
    fontSize: 13,
    marginBottom: '1.5%',
  },
  cardTitle: {
    color: 'white',
    fontSize: 14,
  },
  questionContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingVertical: 10,
    paddingHorizontal: 5,
    width: '100%',
    backgroundColor: COLORS.backgroundSecondary,
    borderRadius: 5,
    marginBottom: 10,
  },
  quizImageContainer: {
    width: '100%',
    height: '100%',
    marginBottom: 10,
  },
  answerContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 5,
    borderWidth: 1,
    width: '100%',
    minHeight: '15%',
    padding: 5,
    borderColor: 'white',
    marginVertical: 5,
    flexDirection: 'row',
  },
  answerNumber: {
    borderRadius: 100,
    borderWidth: 1,
    borderColor: 'white',
    width: 20,
    height: 20,
    padding: 0,
    marginLeft: 10,
    color: 'white',
    textAlign: 'center',
  },
  answerTextContainer: {
    width: '85%',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  quizMark: {
    fontSize: 20,
    textDecorationLine: 'underline',
    textDecorationColor: COLORS.primary,
  },
});

function EpisodeModalQuiz({
  style,
  modalVisible,
  onPressClose,
  episode,
  seasonNumber,
}) {
  const { t } = useTranslation();
  const userContext = useUserContextState();
  const [currentIndex, setCurrentIndex] = useState(episode.pages[0].type === 'Quiz' ? -1 : 0);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const [clickedAnswer, setClickedAnswer] = useState(null);
  const [isProcessed, setIsProcessed] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [goodAnswer, setGoodAnswer] = useState(0);
  const answerNumber = ['', 'A', 'B', 'C', 'D', 'E', 'F'];

  const endEpisode = (status) => {
    analytics.track('user finished quiz', {
      season: seasonNumber, episode: episode.position, totalQuestions: getQuizLength(), goodAnswers: goodAnswer,
    });
    onPressClose(status);
  };

  function processAnswer(selected, isRight) {
    analytics.track('user answered quiz question', {
      season: seasonNumber, episode: episode.position, question: currentQuestionNumber, answer: selected, isRight,
    });
    if (isProcessed) {
      setIsProcessed(false);
      setClickedAnswer(selected);
      if (isRight) setGoodAnswer(goodAnswer + 1);
      setTimeout(() => {
        setIsProcessed(true);
        setClickedAnswer(null);
        setCurrentQuestionNumber(currentQuestionNumber + 1);
        setCurrentIndex(currentIndex + 1);
      }, 2000);
    }
  }

  function getQuizLength() {
    const count = episode.pages.reduce((a, c) => (c.type === 'Quiz' || c.type === 'QuizImage' ? ++a : a), 0);
    return count;
  }

  const renderPage = () => (
    <ScrollView style={{ height: '100%', width: '100%' }} contentContainerStyle={{ height: '100%', width: '100%' }}>
      {/* Fin de quiz image */}
      {(currentIndex === episode.pages.length && episode.pages[0].type !== 'Quiz') && (
        <View style={[styles.contentContainer, { justifyContent: 'space-evenly' }]}>
          <Text style={[styles.contentText, styles.quizMark]}>
            {goodAnswer}
            /
            {getQuizLength()}
          </Text>
          <Text style={[styles.title]}>{t('progress.congratulations')}</Text>
          <Text style={styles.contentText}>{t('progress.episodeEnd', { episode: episode.number })}</Text>
          <View style={styles.cardLeft}>
            <View style={styles.cardMetadata}>
              <CheckCircle style={styles.icon} role="none" color="green" height={18} width={18} />
              <Text style={styles.metadataText}>
                S0
                {seasonNumber}
                {' '}
                - Épisode
                {' '}
                {episode.number}
              </Text>
            </View>
            <Text style={styles.cardTitle}>{episode.title}</Text>
          </View>
          <Button style={styles.nextButton} textStyle={{ fontSize: 13 }} text={t('progress.finish')} onPress={() => endEpisode('completed')} />
        </View>
      )}
      {/* Fin de quiz de fin de saison */}
      {(currentIndex === episode.pages.length && episode.pages[0].type === 'Quiz') && (
        <View style={[styles.contentContainer, { justifyContent: 'space-evenly' }]}>
          <Text style={[styles.contentText, styles.quizMark]}>
            {goodAnswer}
            /
            {getQuizLength()}
          </Text>
          <Text style={styles.contentText}>{goodAnswer >= 7 ? t('progress.quizSuccess', { seasonNumber, nextSeason: seasonNumber + 1 }) : t('progress.quizFail', { seasonNumber })}</Text>
          <Button style={styles.nextButton} textStyle={{ fontSize: 13 }} text={t('progress.finish')} onPress={() => { endEpisode(goodAnswer >= 7 ? 'completed' : 'failed'); }} />
        </View>
      )}
      {/* Début quiz fin de saison */}
      {(currentIndex === -1 && episode.pages[0].type === 'Quiz') && (
        <View style={[styles.contentContainer, { justifyContent: 'space-between' }]}>
          <Text style={styles.contentText}>{t('progress.quizIntro', { seasonNumber })}</Text>
          <Button style={styles.nextButton} textStyle={{ fontSize: 13 }} text={t('progress.start')} onPress={() => setCurrentIndex(currentIndex + 1)} />
        </View>
      )}
      {/* Début de quiz image */}
      {(currentIndex >= 0 && currentIndex < episode.pages.length && episode.pages[currentIndex].type === 'QuizImageText') && (
        <View style={[styles.contentContainer, { justifyContent: 'space-between' }]}>
          <Text style={styles.contentText}>{episode.pages[currentIndex].texts[0]}</Text>
          {episode.pages[currentIndex].image && (
          <View style={styles.imageContainer}>
            <Image
              source={{ uri: episode.pages[currentIndex].image, width: '100%', height: '100%' }}
              accessibilityIgnoresInvertColors
            />
          </View>
          )}
          <Button style={styles.nextButton} textStyle={{ fontSize: 13 }} text={t('progress.start')} onPress={() => setCurrentIndex(currentIndex + 1)} />
        </View>
      )}
      {/* Explications quiz image */}
      {(currentIndex >= 0 && currentIndex < episode.pages.length && episode.pages[currentIndex].type === 'QuizText') && (
        <View style={[styles.contentContainer, { justifyContent: 'space-between' }]}>
          <Text style={styles.contentText}>{episode.pages[currentIndex].texts[0]}</Text>
          <Button style={styles.nextButton} textStyle={{ fontSize: 13 }} text={t('common.next')} onPress={() => setCurrentIndex(currentIndex + 1)} />
        </View>
      )}
      {/* Question/Réponses */}
      {(currentIndex >= 0 && currentIndex < episode.pages.length && episode.pages[currentIndex].type !== 'QuizText' && episode.pages[currentIndex].type !== 'QuizImageText') && (
      <View style={styles.contentContainer}>
        {episode.pages[currentIndex].texts.map((prop, key) => {
          // Question
          if (key === 0) {
            const res = [
              <View style={styles.questionContainer}>
                <Text style={[styles.metadata, { marginTop: 0 }]}>
                  Question
                  {' '}
                  {currentQuestionNumber}
                  /
                  {getQuizLength()}
                </Text>
                <Text style={[styles.contentText, { fontWeight: 700, marginBottom: 0 }]}>
                  {prop}
                </Text>
              </View>,
            ];
            if (episode.pages[currentIndex].image) {
              res.push(
                <View style={styles.quizImageContainer}>
                  <Image
                    resizeMode="contain"
                    source={{ uri: episode.pages[currentIndex].image, width: '100%', height: '100%' }}
                    accessibilityIgnoresInvertColors
                  />
                </View>,
              );
            }
            return (res);
          }
          // Réponses
          const isRight = (episode.pages[currentIndex].rightAnswer === key);
          return (
            <Pressable accessibilityRole="button" style={[styles.answerContainer, isRight && clickedAnswer ? { borderColor: 'green' } : {}, clickedAnswer && clickedAnswer === key && !isRight ? { borderColor: 'red' } : {}]} onPress={() => { processAnswer(key, isRight); }}>
              <View style={{ width: '15%' }}>
                <Text style={[styles.answerNumber, isRight && clickedAnswer ? { backgroundColor: 'green', borderColor: 'black', color: 'black' } : {}, clickedAnswer && clickedAnswer === key && !isRight ? { backgroundColor: 'red', borderColor: 'black', color: 'black' } : {}]}>{answerNumber[key]}</Text>
              </View>
              <View style={styles.answerTextContainer}>
                <Text style={[styles.contentText, { fontSize: 13, textAlign: 'left', marginBottom: 0 }]}>{prop}</Text>
              </View>
            </Pressable>
          );
        })}
      </View>
      )}
    </ScrollView>
  );

  return (
    <Modal
      visible={modalVisible}
      transparent
    >
      <View style={({ ...styles.modalContainer, ...style })}>
        <Card
          isExpandable
          style={isExpanded ? styles.modalContentFullScreen : styles.modalContent}
          onExpand={() => setIsExpanded(!isExpanded)}
        >
          <View style={styles.columnCenter}>
            <View style={{ height: '20%' }}>
              <Text style={styles.metadata}>
                S0
                {seasonNumber}
                {' '}
                - Épisode
                {' '}
                {episode.number}
              </Text>
              <Text style={styles.title}>
                <Text style={{ color: COLORS.primary }}>Quiz:</Text>
                {' '}
                {episode.title}
              </Text>
            </View>
            <View style={{ height: '80%', width: isExpanded ? '50%' : '100%', paddingBottom: 20 }}>
              {renderPage()}
            </View>
          </View>
        </Card>
      </View>
    </Modal>
  );
}

EpisodeModalQuiz.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  modalVisible: PropTypes.bool,
  onPressClose: PropTypes.func,
  episode: PropTypes.object,
  seasonNumber: PropTypes.number,
};

EpisodeModalQuiz.defaultProps = {
  style: {},
  modalVisible: false,
  onPressClose: () => {},
  episode: {},
  seasonNumber: 0,
};

export default EpisodeModalQuiz;
