import React, {
  createContext, useContext, useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useUserContextState } from './UserContext';
import progressApi from '../api/progressApi';

const ProgressContext = createContext();

const useProgressContextState = () => {
  const context = useContext(ProgressContext);

  if (context === undefined) {
    throw new Error('useProgressContextState was used outside of its Provider');
  }

  return context;
};

function ProgressContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState({});
  const [maxLength, setMaxLength] = useState(0);

  const getProgress = useCallback(async () => {
    setIsLoading(true);
    const userProgress = await progressApi.getUserProgress();
    setMaxLength(userProgress.programTitle === 'Pro' ? 4 : 6);
    if (!userProgress.error) {
      setProgress(userProgress);
    } else {
      console.log(userProgress);
    }
    setIsLoading(false);
  }, []);

  async function updateProgress(episodeNumber, seasonNumber, isEpisodeCompleted, isSeasonCompleted, pendingPage) {
    await progressApi.updateUserProgress(episodeNumber, seasonNumber, isEpisodeCompleted, isSeasonCompleted, pendingPage);
    const res = await progressApi.getUserProgress();
    if (!res.error) {
      setProgress(res);
    }
  }

  async function sendMail() {
    const res = await progressApi.sendMail();
    if (!res.error) {
      return res;
    }
    return null;
  }

  async function startFinalQuiz(firstName, lastName) {
    const res = await progressApi.startFinalQuiz(firstName, lastName);
    if (!res.error) {
      return res;
    }
    return null;
  }

  async function endFinalQuiz(mark) {
    const res = await progressApi.endFinalQuiz(mark);
    if (!res.error) {
      return res;
    }
    return null;
  }

  const contextValue = useMemo(
    () => ({
      getProgress,
      updateProgress,
      progress,
      isLoading,
      maxLength,
      sendMail,
      startFinalQuiz,
      endFinalQuiz,
    }),
    [isLoading,
      progress,
      getProgress,
      maxLength,
      updateProgress,
      sendMail,
      startFinalQuiz,
      endFinalQuiz,
    ],
  );

  return (
    <ProgressContext.Provider value={contextValue}>
      {children}
    </ProgressContext.Provider>
  );
}

ProgressContextProvider.propTypes = {
  children: PropTypes.element,
};
ProgressContextProvider.defaultProps = {
  children: null,
};

export { ProgressContextProvider, useProgressContextState };
