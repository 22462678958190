import React, { useEffect, useRef } from 'react';
import * as Linking from 'expo-linking';
import {
  NavigationContainer,
} from '@react-navigation/native';
import { ErrorBoundary } from 'react-error-boundary';
import { AuthScreens, AppScreens } from './navigation/ScreensStacks';
import configRoutes from './navigation/configRoutes';
import { useAuthContextState } from './contexts/AuthContext';
import { useUserContextState } from './contexts/UserContext';
import Error from './pages/Error/Error';
import LoadingScreen from './pages/LoadingScreen/LoadingScreen';
import analytics from './utils/analytics';
import { navigationRef } from './utils/RootNavigation';

function AppContainer() {
  const authContext = useAuthContextState();
  const userContext = useUserContextState();

  const linking = {
    prefixes: [Linking.createURL('/'), 'bemymedia://', 'https://app.be-my-media.com'],
    config: configRoutes,
  };

  useEffect(() => {
    analytics.init();
  }, []);

  useEffect(() => {
    async function fetchUser() {
      await userContext.loadUserData();
    }
    if (authContext.user && !userContext.user) {
      fetchUser();
    }
  }, [authContext.user]);

  if (authContext.isLoading || userContext.isLoading) {
    return (
      <LoadingScreen />
    );
  }
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        fallback={<Error error={{ message: 'Erreur navigation' }} />}
      >
        {authContext.user && userContext.user ? (<AppScreens />) : (<AuthScreens />)}
      </NavigationContainer>
    </ErrorBoundary>
  );
}

export default AppContainer;
