import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import Welcome from '../pages/Welcome/Welcome';
import Home from '../pages/Home/Home';
import Article from '../pages/Article/Article';
import Progress from '../pages/Progress/Progress';
import Search from '../pages/Search/Search';
import Editos from '../pages/Editos/Editos';
import EditoDetail from '../pages/EditoDetail/EditoDetail';
import Account from '../pages/Account/Account';
import Sso from '../pages/Sso/Sso';
import SsoDisconnected from '../pages/Sso/SsoDisconnected';

const Stack = createStackNavigator();

function AuthScreens() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Welcome" component={Welcome} />
      <Stack.Screen name="Sso" component={Sso} />
      <Stack.Screen name="Disconnected" component={SsoDisconnected} />
    </Stack.Navigator>
  );
}

function AppScreens() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Accueil" component={Home} />
      <Stack.Screen name="Article" component={Article} />
      <Stack.Screen name="Progresser" component={Progress} />
      <Stack.Screen name="Recherche" component={Search} />
      <Stack.Screen name="Revues" component={Editos} />
      <Stack.Screen name="Revue" component={EditoDetail} />
      <Stack.Screen name="Account" component={Account} />
    </Stack.Navigator>
  );
}

export { AuthScreens, AppScreens };
