import React, { useState, useEffect } from 'react';
import {
  View, StyleSheet, Text, Modal, ScrollView, Image, Pressable,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native-web';
import PropTypes from 'prop-types';
import { CheckCircle } from 'iconoir-react-native';

import { useProgressContextState } from '../../../contexts/ProgressContext';
import { useUserContextState } from '../../../contexts/UserContext';
import COLORS from '../../../utils/colors';
import BmmModal from '../../Common/Modal/BmmModal';
import Card from '../../Common/Card/Card';
import Button from '../../Common/Button/Button';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  modalContent: {
    width: '30%',
    height: '70%',
    backgroundColor: 'black',
  },
  modalContentFullScreen: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    borderRadius: 0,
  },
  columnCenter: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '90%',
    paddingHorizontal: '5%',
  },
  metadata: {
    fontSize: 13,
    color: COLORS.textSecondary,
    marginTop: 15,
    marginBottom: 5,
    alignSelf: 'center',
  },
  title: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    marginTop: 10,
    textAlign: 'center',
  },
  nextButton: {
    width: '25%',
  },
  contentText: {
    color: 'white',
    fontSize: 15,
    textAlign: 'center',
    marginBottom: '3%',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  label: {
    color: COLORS.textSecondary,
    fontSize: 14,
    marginBottom: '2%',
  },
  input: {
    backgroundColor: COLORS.backgroundSecondary,
    width: '100%',
    borderRadius: 10,
    outlineStyle: 'none',
    paddingHorizontal: '5%',
    height: '20%',
    color: 'white',
    borderColor: COLORS.textSecondary,
    borderWidth: 1,
    marginBottom: '6%',
    fontSize: 14,
  },
  questionContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingVertical: 10,
    paddingHorizontal: 5,
    width: '100%',
    backgroundColor: COLORS.backgroundSecondary,
    borderRadius: 5,
    marginBottom: 10,
  },
  answerContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 5,
    borderWidth: 1,
    width: '100%',
    minHeight: '15%',
    padding: 5,
    borderColor: 'white',
    marginVertical: 5,
    flexDirection: 'row',
  },
  answerNumber: {
    borderRadius: 100,
    borderWidth: 1,
    borderColor: 'white',
    width: 20,
    height: 20,
    padding: 0,
    marginLeft: 10,
    color: 'white',
    textAlign: 'center',
  },
  answerTextContainer: {
    width: '85%',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bigRounded: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 40,
    width: 40,
    height: 40,
    maxHeight: 40,
    minHeight: 40,
    minWidth: 40,
    borderWidth: 1,
    borderRadius: 60 / 2,
    borderColor: 'white',
    flex: 1,
    color: 'white',
    marginBottom: 10,
  },
  quizMark: {
    fontSize: 20,
    textDecorationLine: 'underline',
    textDecorationColor: COLORS.primary,
  },
});

function FinalQuizModal({
  style,
  onClose,
  modalVisible,
  seasonIndex,
}) {
  const { t } = useTranslation();
  const userContext = useUserContextState();
  const progressContext = useProgressContextState();

  const [currentIndex, setCurrentIndex] = useState(-2);
  const [clickedAnswer, setClickedAnswer] = useState(null);
  const [seconds, setSeconds] = useState(60);
  const [chrono, setChrono] = useState(null);
  const [isProcessed, setIsProcessed] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [goodAnswer, setGoodAnswer] = useState(0);
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const answerNumber = ['', 'A', 'B', 'C', 'D', 'E', 'F'];

  function nextPage(nextIndex) {
    if (chrono) {
      clearInterval(chrono);
      setChrono(null);
      setSeconds(60);
    }
    setCurrentIndex(nextIndex);
    let timer = 60;
    if (nextIndex >= 0
            && nextIndex < progressContext.progress.seasons[seasonIndex].episodes[0].pages.length) {
      const tmp = setInterval(() => {
        if (timer <= 0) {
          clearInterval(tmp);
        } else {
          timer -= 1;
          setSeconds(timer);
        }
      }, 1000);
      setChrono(tmp);
    }
  }

  function processAnswer(selected, isRight) {
    analytics.track('user answered quiz question', {
      season: 'final', episode: 'final', question: currentIndex + 1, answer: selected, isRight,
    });
    if (isProcessed) {
      setIsProcessed(false);
      if (chrono) {
        clearInterval(chrono);
        setChrono(null);
      }
      setClickedAnswer(selected);
      if (isRight) setGoodAnswer(goodAnswer + 1);
      setTimeout(() => {
        setIsProcessed(true);
        setClickedAnswer(null);
        setSeconds(60);
        nextPage(currentIndex + 1);
      }, 2000);
    }
  }

  async function startQuiz() {
    analytics.track('user started final quiz');
    await progressContext.startFinalQuiz(firstName, lastName);
  }

  async function endQuiz() {
    analytics.track('user finished quiz', {
      season: 'final', episode: 'final', totalQuestions: progressContext.progress.seasons[seasonIndex].episodes[0].pages.length, goodAnswers: goodAnswer,
    });

    await progressContext.endFinalQuiz(goodAnswer);
  }

  async function dismissModal(isSucceed) {
    if (isSucceed) {
      progressContext.sendMail();
    }
    await userContext.loadUserData();
    onClose();
  }

  useEffect(() => {
    if (seconds <= 0) {
      processAnswer(-1, false);
    }
  }, [seconds]);

  const renderPage = () => (
    <ScrollView style={{ height: '100%', width: '100%' }} contentContainerStyle={{ height: '100%', width: '100%' }}>
      {/* Page de présentation */}
      {currentIndex === -2 && (
        <View style={[styles.contentContainer, { justifyContent: 'space-between' }]}>
          <Text style={styles.contentText}>{t('progress.finalQuiz.intro')}</Text>
          <Button style={styles.nextButton} textStyle={{ fontSize: 13 }} text={t('progress.start')} onPress={() => { setCurrentIndex(-1); }} />
        </View>
      )}
      {/* Formulaire nom prénom */}
      {currentIndex === -1 && (
        <View style={[styles.contentContainer, { justifyContent: 'flex-start' }]}>
          <Text style={[styles.contentText, { marginBottom: '3%' }]}>{t('progress.finalQuiz.form')}</Text>
          <View style={{ marginBottom: '10%', width: '70%', height: '50%' }}>
            <Text style={styles.label}>{t('progress.finalQuiz.firstName')}</Text>
            <TextInput value={firstName} onChangeText={(text) => setfirstName(text)} style={styles.input} accessibilityLabel="Text input field" accessibilityHint="Prénom" />
            <Text style={styles.label}>{t('progress.finalQuiz.lastName')}</Text>
            <TextInput value={lastName} onChangeText={(text) => setlastName(text)} style={styles.input} accessibilityLabel="Text input field" accessibilityHint="Nom" />
          </View>
          <Button style={{ width: '30%', height: '8%' }} textStyle={{ fontSize: 13 }} text={t('progress.start')} onPress={() => { startQuiz(); nextPage(0); }} />
        </View>
      )}
      {/* Questions/réponses */}
      {(currentIndex > -1
                && currentIndex < progressContext.progress.seasons[seasonIndex].episodes[0].pages.length) && (
                <View style={styles.contentContainer}>
                  {progressContext.progress.seasons[seasonIndex].episodes[0].pages[currentIndex].texts.map(
                    (prop, key) => {
                      // Question
                      if (key === 0) {
                        return (
                          <View style={styles.questionContainer}>
                            <Text style={[styles.metadata, { marginTop: 0 }]}>
                              Question
                              {' '}
                              {currentIndex + 1}
                              /
                              {progressContext.progress.seasons[seasonIndex].episodes[0].pages.length}
                            </Text>
                            <Text style={[styles.contentText, { fontWeight: 700, marginBottom: 0 }]}>
                              {prop}
                            </Text>
                          </View>
                        );
                      }
                      //   Réponses;
                      const isRight = progressContext.progress.seasons[seasonIndex].episodes[0].pages[currentIndex].rightAnswer === key;
                      return (
                        <Pressable
                          accessibilityRole="button"
                          style={[styles.answerContainer, isRight && clickedAnswer ? { borderColor: 'green' } : {}, clickedAnswer && clickedAnswer === key && !isRight ? { borderColor: 'red' } : {}]}
                          onPress={() => {
                            processAnswer(key, isRight);
                            if (currentIndex === (progressContext.progress.seasons[seasonIndex].episodes[0].pages.length - 1)) {
                              endQuiz();
                            }
                          }}
                        >
                          <View style={{ width: '15%' }}>
                            <Text style={[styles.answerNumber, isRight && clickedAnswer ? { backgroundColor: 'green', borderColor: 'black', color: 'black' } : {}, clickedAnswer && clickedAnswer === key && !isRight ? { backgroundColor: 'red', borderColor: 'black', color: 'black' } : {}]}>{answerNumber[key]}</Text>
                          </View>
                          <View style={styles.answerTextContainer}>
                            <Text style={[styles.contentText, { fontSize: 13, textAlign: 'left', marginBottom: 0 }]}>{prop}</Text>
                          </View>
                        </Pressable>
                      );
                    },
                  )}
                </View>
      )}
      {/* Fin de quiz / résultats */}
      {currentIndex === progressContext.progress.seasons[seasonIndex].episodes[0].pages.length && (
        <View style={[styles.contentContainer, { justifyContent: 'space-evenly' }]}>
          <Text style={[styles.contentText, styles.quizMark]}>
            {goodAnswer}
            /
            {progressContext.progress.seasons[seasonIndex].episodes[0].pages.length}
          </Text>
          {goodAnswer >= 12 && (
          <Text style={styles.contentText}>{t('progress.finalQuiz.endQuizSuccess')}</Text>
          )}
          {(goodAnswer < 12 && progressContext.progress.finalQuizNbTry + 1 < 2) && (
          <Text style={styles.contentText}>{t('progress.finalQuiz.endQuizRetry')}</Text>
          )}
          {(goodAnswer < 12 && progressContext.progress.finalQuizNbTry + 1 >= 2) && (
          <Text style={styles.contentText}>{t('progress.finalQuiz.endQuizFail')}</Text>
          )}
          <Button style={styles.nextButton} textStyle={{ fontSize: 13 }} text={t('progress.finish')} onPress={() => { dismissModal(goodAnswer >= 12); }} />
        </View>
      )}
    </ScrollView>
  );

  return (
    <BmmModal modalVisible={modalVisible} style={{ ...style }}>
      <Card
        isClosable={(currentIndex < 0)}
        isExpandable
        style={isExpanded ? styles.modalContentFullScreen : styles.modalContent}
        onExpand={() => setIsExpanded(!isExpanded)}
        onClose={onClose}
      >
        <View style={styles.columnCenter}>
          <View style={{ height: '10%' }}>
            <Text style={styles.title}>
              <Text style={{ color: COLORS.primary }}>Quiz:</Text>
              {' '}
              {progressContext.progress.seasons[seasonIndex].title}
            </Text>
          </View>
          {currentIndex >= 0
                        && currentIndex < progressContext.progress.seasons[seasonIndex].episodes[0].pages.length
                        && (
                        <View style={styles.bigRounded}>
                          <Text style={[{ fontSize: 15, color: 'white' }]}>{seconds}</Text>
                        </View>
                        )}
          <View style={{ height: '80%', width: isExpanded ? '50%' : '100%', paddingBottom: 20 }}>
            {renderPage()}
          </View>
        </View>
      </Card>
    </BmmModal>
  );
}

FinalQuizModal.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  modalVisible: PropTypes.bool,
  onClose: PropTypes.func,
  seasonIndex: PropTypes.number,
};

FinalQuizModal.defaultProps = {
  style: {},
  onClose: () => { },
  modalVisible: false,
  seasonIndex: 0,
};

export default FinalQuizModal;
