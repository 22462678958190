import React, { useState, useEffect } from 'react';
import {
  StyleSheet, Text, View, FlatList, Image, Pressable,
} from 'react-native';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import { Plus } from 'iconoir-react-native';
import { useTranslation } from 'react-i18next';

import { articleType, articleDefault } from '../../../validation/article';
import { useContentContextState } from '../../../contexts/ContentContext';
import { useUserContextState } from '../../../contexts/UserContext';
import COLORS from '../../../utils/colors';
import Button from '../../Common/Button/Button.web';
import Card from '../../Common/Card/Card';
import BmmModal from '../../Common/Modal/BmmModal';
import EditoForm from '../../Forms/EditoForm/EditoForm.web';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  modalContent: {
    width: '35%',
    height: '80%',
    backgroundColor: COLORS.background,
  },
  title: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '4%',
  },
  listContainer: {
    paddingHorizontal: '4%',
    paddingVertical: '1%',
    flex: 10,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  createButton: {
    width: '30%',
    alignSelf: 'flex-end',
    marginBottom: '2%',
    justifyContent: 'center',
  },
  button: {
    width: '40%',
    height: '100%',
  },
  editoContainer: {
    flexDirection: 'row',
    width: '100%',
    flex: 2,
    alignItems: 'center',
    minHeight: 75,
  },
  editoImage: {
    width: '30%',
    marginRight: '5%',
    minHeight: '100%',
  },
  separator: {
    width: '100%',
    marginTop: '2%',
    marginBottom: '2%',
    borderColor: COLORS.backgroundSecondary,
    borderBottomWidth: 1,
    alignSelf: 'center',
  },
});

function BookmarkModal({
  style,
  modalVisible,
  article,
  onClose,
}) {
  const [isCreateEdito, setIsCreateEdito] = useState(false);

  const { t } = useTranslation();
  const navigation = useNavigation();
  const contentContext = useContentContextState();
  const userContext = useUserContextState();

  useEffect(() => {
    if (contentContext.myEditos.length === 0) {
      contentContext.getMyEditos();
    }
  }, []);

  const addArticleToEdito = (edito) => {
    analytics.track('user saved article in edito from bookmark menu', { article: article.url, editoTitle: edito.title });
    const image = edito.image ? edito.image : article.image;
    if (article.url.includes('/api/v2/Documents/')) {
      contentContext.addArticleToEdito(edito.id, [article], image);
    } else {
      contentContext.addArticleToEdito(edito.id, [article.url], image);
    }
  };

  const addArticleToDefault = () => {
    analytics.track('user saved article in default edito from bookmark menu', { article: article.url });
    if (article.url.includes('/api/v2/Documents/')) {
      contentContext.addArticleToDefault(article);
    } else {
      contentContext.addArticleToDefault(article.url);
    }
  };

  const createEdito = async (title, description, isPublic) => {
    analytics.track('user created a new edito from bookmark menu', { title });
    await contentContext.createEdito(title, description, isPublic);
    setIsCreateEdito(false);
    userContext.executeAction('edito_create');
  };

  const renderEditos = ({ item, index, separators }) => (
    <View style={{ flexDirection: 'column' }}>
      <Pressable accessibilityRole="button" style={styles.editoContainer} onPress={() => { addArticleToEdito(item, article); onClose(); }}>
        <Image
          accessibilityIgnoresInvertColors
          source={{ uri: item.image ? item.image : 'https://symfony-bmm.s3.eu-west-3.amazonaws.com/articles/Les+grands+titres-min.jpg', width: '100%', height: '80%' }}
          style={styles.editoImage}
        />
        <Text style={{ color: 'white', fontSize: 16 }}>{item.title}</Text>
      </Pressable>
      <View style={styles.separator} />
    </View>
  );

  return (
    <BmmModal modalVisible={modalVisible} style={{ ...style }}>
      <Card isClosable style={styles.modalContent} onClose={() => { analytics.track('user closed bookmark menu'); onClose(); }}>
        {(!isCreateEdito) && (
        <View style={styles.listContainer}>
          <Text style={styles.title}>Ajouter à une revue</Text>
          <Button
            onPress={() => { analytics.track('user clicked on add edito from bookmark menu'); setIsCreateEdito(true); }}
            backgroundColor={COLORS.primary}
            style={styles.createButton}
            accessibilityLabel={t('editos.form.createTitle')}
            accessibilityHint={t('editos.form.createTitle')}
          >
            <View style={{ flexDirection: 'row' }}>
              <Plus style={{ marginTop: 1 }} role="none" color="white" height={15} width={15} />
              <Text style={{ color: 'white', fontSize: 13 }}>Nouvelle revue</Text>
            </View>
          </Button>
          <FlatList
            data={contentContext.myEditos.filter((edito) => edito.title !== 'Marque-pages')}
            showsVerticalScrollIndicator
            style={{ height: '100%', marginTop: '2%' }}
            contentContainerStyle={{ flex: 1 }}
            renderItem={renderEditos}
          />
          <View style={{ justifyContent: 'center', flexDirection: 'row', paddingVertical: '5%' }}>
            <Button
              style={styles.button}
              backgroundColor={COLORS.primary}
              textStyle={{ fontSize: 13 }}
              onPress={() => { addArticleToDefault(); onClose(); }}
              text="Ajouter aux marque-pages"
            />
          </View>
        </View>
        )}
        {isCreateEdito && (
        <EditoForm
          onSubmit={createEdito}
        />
        )}
        <View />
      </Card>
    </BmmModal>
  );
}

BookmarkModal.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  modalVisible: PropTypes.bool,
  onClose: PropTypes.func,
  article: articleType,
};

BookmarkModal.defaultProps = {
  style: {},
  modalVisible: false,
  article: articleDefault,
  onClose: () => {},
};

export default BookmarkModal;
